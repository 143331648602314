export const csvDataString = `Combined Adjusted Gross Income,One Child,Two Children,Three Children,Four Children,Five Children,Six Children
100,,,,,,
150,,,,"Minimum Order Formula Applies for Adjusted Gross Income Below $1,100",,
200,,,,,,
250,,,,,,
300,,,,,,
350,,,,,,
400,,,,,,
450,,,,,,
500,,,,,,
550,,,,,,
600,,,, ,,
650,,,,,,
700,,,,,,
750,,,,,,
800,,,,,,
850,,,,,,
900,,,,,,
950,,,,,,
1000,,,,,,
1050,,,,,,
1100,216,335,410,458,504,547
1150,225,348,427,477,524,570
1200,234,362,443,495,545,592
1250,243,375,460,513,565,614
1300,251,389,476,532,585,636
1350,260,402,492,550,605,658
1400,269,416,509,568,625,680
1450,277,429,525,587,645,701
1500,286,442,541,604,665,723
1550,294,455,556,622,684,743
1600,302,467,572,639,703,764
1650,310,480,587,656,721,784
1700,319,492,602,673,740,805
1750,327,505,618,690,759,825
1800,335,518,634,708,778,846
1850,343,530,649,725,798,867
1900,352,543,665,742,817,888
1950,360,556,680,760,836,908
2000,368,569,696,777,855,929
2050,377,581,711,794,874,950
2100,385,594,727,812,893,971
2150,393,607,742,829,912,991
2200,401,620,758,847,931,1012
2250,410,632,773,864,950,1033
2300,418,645,789,881,969,1054
2350,426,658,804,899,988,1074
2400,435,671,820,916,1007,1095
2450,443,683,835,933,1026,1116
2500,451,696,851,950,1045,1136
2550,459,709,866,968,1064,1157
2600,468,722,882,985,1084,1178
2650,476,734,897,1002,1103,1198
2700,484,747,913,1020,1122,1219
2750,493,760,928,1037,1141,1240
2800,501,772,944,1054,1160,1261
2850,509,785,959,1071,1179,1281
2900,517,797,974,1087,1196,1300
2950,525,809,988,1103,1213,1319
3000,533,821,1002,1119,1231,1338
3050,541,833,1016,1135,1248,1357
3100,548,844,1030,1150,1266,1376
3150,556,856,1044,1166,1283,1394
3200,564,868,1058,1182,1300,1413
3250,572,880,1072,1198,1318,1432
3300,580,892,1086,1214,1335,1451
3350,588,904,1101,1229,1352,1470
3400,596,915,1115,1245,1370,1489
3450,604,928,1129,1261,1388,1508
3500,612,940,1144,1278,1406,1529
3550,620,953,1160,1295,1425,1549
3600,628,965,1175,1312,1444,1569
3650,636,977,1189,1328,1460,1587
3700,643,987,1202,1342,1477,1605
3750,650,998,1215,1357,1493,1622
3800,657,1009,1228,1372,1509,1640
3850,664,1020,1241,1386,1525,1658
3900,671,1031,1254,1401,1541,1675
3950,678,1042,1267,1416,1557,1693
4000,685,1053,1280,1430,1573,1710
4050,692,1063,1294,1445,1589,1728
4100,699,1074,1306,1459,1605,1744
4150,706,1084,1319,1473,1620,1761
4200,713,1095,1331,1487,1635,1778
4250,720,1105,1344,1501,1651,1794
4300,727,1115,1356,1515,1666,1811
4350,734,1126,1368,1529,1681,1828
4400,741,1136,1381,1542,1697,1844
4450,747,1147,1393,1556,1712,1861
4500,754,1157,1406,1570,1727,1878
4550,761,1167,1418,1584,1743,1894
4600,768,1178,1431,1598,1758,1911
4650,775,1188,1443,1612,1773,1928
4700,782,1199,1456,1626,1789,1944
4750,788,1209,1467,1639,1803,1960
4800,795,1218,1478,1651,1817,1975
4850,801,1227,1489,1664,1830,1989
4900,808,1237,1500,1676,1844,2004
4950,814,1246,1511,1688,1857,2019
5000,820,1256,1523,1701,1871,2033
5050,827,1265,1534,1713,1884,2048
5100,833,1274,1545,1725,1898,2063
5150,840,1284,1556,1738,1911,2078
5200,846,1293,1567,1750,1925,2092
5250,852,1303,1578,1762,1938,2107
5300,859,1312,1589,1774,1952,2122
5350,865,1322,1600,1787,1965,2136
5400,871,1330,1610,1798,1978,2150
5450,875,1337,1617,1806,1987,2160
5500,879,1343,1624,1814,1996,2169
5550,883,1349,1631,1822,2005,2179
5600,887,1355,1639,1830,2013,2189
5650,891,1361,1646,1838,2022,2198
5700,896,1367,1653,1846,2031,2208
5750,900,1373,1660,1854,2040,2217
5800,904,1379,1667,1862,2049,2227
5850,908,1385,1674,1870,2057,2236
5900,912,1391,1682,1878,2066,2246
5950,916,1397,1689,1886,2075,2256
6000,920,1404,1696,1894,2084,2265
6050,924,1410,1703,1902,2093,2275
6100,928,1416,1710,1910,2101,2284
6150,932,1422,1717,1918,2110,2294
6200,937,1428,1725,1926,2119,2303
6250,941,1434,1732,1934,2128,2313
6300,945,1440,1739,1942,2136,2322
6350,949,1446,1746,1950,2145,2332
6400,953,1452,1753,1958,2154,2341
6450,957,1458,1760,1966,2162,2351
6500,961,1464,1767,1974,2171,2360
6550,965,1470,1774,1982,2180,2370
6600,969,1476,1782,1990,2189,2379
6650,973,1482,1789,1998,2198,2389
6700,977,1488,1796,2006,2207,2399
6750,981,1494,1803,2014,2216,2408
6800,985,1500,1810,2022,2225,2418
6850,989,1506,1818,2030,2233,2428
6900,993,1512,1825,2038,2242,2437
6950,997,1518,1832,2047,2251,2447
7000,1001,1524,1839,2055,2260,2457
7050,1005,1530,1847,2063,2269,2466
7100,1009,1536,1854,2071,2278,2476
7150,1013,1542,1861,2079,2287,2486
7200,1017,1548,1868,2087,2296,2495
7250,1021,1554,1876,2095,2304,2505
7300,1025,1560,1883,2103,2313,2515
7350,1029,1567,1890,2111,2322,2524
7400,1033,1573,1897,2119,2331,2534
7450,1037,1579,1904,2127,2340,2544
7500,1041,1585,1912,2135,2349,2553
7550,1045,1591,1919,2143,2358,2563
7600,1049,1597,1926,2151,2367,2572
7650,1053,1603,1933,2159,2375,2582
7700,1057,1608,1940,2167,2384,2591
7750,1061,1614,1947,2175,2392,2600
7800,1063,1618,1952,2180,2398,2607
7850,1066,1622,1956,2184,2403,2612
7900,1068,1625,1959,2188,2407,2617
7950,1070,1628,1963,2193,2412,2622
8000,1072,1631,1967,2197,2416,2627
8050,1074,1634,1970,2201,2421,2632
8100,1077,1638,1974,2205,2426,2637
8150,1079,1641,1978,2209,2430,2642
8200,1081,1644,1982,2214,2435,2647
8250,1083,1647,1985,2218,2439,2652
8300,1085,1651,1989,2222,2444,2657
8350,1088,1654,1993,2226,2449,2662
8400,1090,1657,1997,2230,2453,2667
8450,1092,1660,2000,2234,2458,2672
8500,1094,1664,2004,2239,2463,2677
8550,1097,1667,2008,2243,2467,2682
8600,1099,1670,2012,2247,2472,2687
8650,1101,1673,2015,2251,2476,2692
8700,1103,1677,2019,2255,2481,2697
8750,1105,1680,2023,2260,2486,2702
8800,1108,1683,2027,2264,2490,2707
8850,1110,1686,2030,2268,2495,2712
8900,1112,1690,2034,2272,2499,2717
8950,1115,1693,2038,2277,2504,2722
9000,1117,1697,2042,2281,2510,2728
9050,1119,1700,2047,2286,2515,2733
9100,1122,1704,2051,2291,2520,2739
9150,1125,1708,2055,2296,2525,2745
9200,1130,1716,2065,2307,2537,2758
9250,1135,1724,2075,2317,2549,2771
9300,1141,1732,2084,2328,2561,2784
9350,1146,1740,2094,2339,2573,2796
9400,1151,1748,2103,2350,2585,2809
9450,1157,1756,2113,2360,2596,2822
9500,1162,1764,2123,2371,2608,2835
9550,1167,1772,2132,2382,2620,2848
9600,1172,1780,2142,2393,2632,2861
9650,1178,1788,2152,2403,2644,2874
9700,1183,1796,2161,2414,2656,2887
9750,1188,1804,2171,2425,2667,2899
9800,1194,1812,2181,2436,2679,2912
9850,1199,1820,2190,2446,2691,2925
9900,1204,1828,2200,2457,2703,2938
9950,1210,1836,2209,2468,2715,2951
10000,1215,1844,2219,2479,2727,2964
10050,1220,1852,2229,2489,2738,2977
10100,1226,1860,2238,2500,2750,2990
10150,1231,1868,2248,2511,2762,3002
10200,1236,1876,2258,2522,2774,3015
10250,1242,1884,2267,2533,2786,3028
10300,1247,1892,2277,2543,2798,3041
10350,1252,1901,2287,2554,2809,3054
10400,1258,1909,2296,2565,2821,3067
10450,1262,1914,2303,2572,2830,3076
10500,1265,1920,2309,2579,2837,3084
10550,1269,1925,2315,2586,2845,3092
10600,1272,1930,2322,2593,2853,3101
10650,1276,1936,2328,2600,2860,3109
10700,1280,1941,2334,2607,2868,3117
10750,1283,1946,2340,2614,2875,3126
10800,1287,1952,2346,2621,2883,3134
10850,1291,1957,2353,2628,2891,3142
10900,1294,1962,2359,2635,2898,3150
10950,1298,1968,2365,2642,2906,3159
11000,1301,1973,2371,2649,2913,3167
11050,1305,1978,2377,2655,2921,3175
11100,1309,1984,2383,2662,2929,3183
11150,1312,1989,2390,2669,2936,3192
11200,1316,1994,2396,2676,2944,3200
11250,1320,2000,2402,2683,2951,3208
11300,1323,2005,2408,2690,2959,3216
11350,1327,2010,2414,2697,2967,3225
11400,1330,2016,2421,2704,2974,3233
11450,1334,2021,2427,2711,2982,3241
11500,1338,2026,2433,2718,2989,3250
11550,1341,2032,2439,2725,2997,3258
11600,1345,2037,2445,2731,3005,3266
11650,1349,2043,2452,2738,3012,3274
11700,1352,2048,2457,2745,3019,3282
11750,1355,2052,2463,2751,3026,3289
11800,1359,2057,2468,2757,3032,3296
11850,1362,2062,2473,2763,3039,3303
11900,1365,2066,2479,2769,3045,3310
11950,1368,2071,2484,2775,3052,3318
12000,1372,2076,2489,2781,3059,3325
12050,1375,2080,2495,2786,3065,3332
12100,1378,2085,2500,2792,3072,3339
12150,1382,2090,2505,2798,3078,3346
12200,1385,2095,2511,2804,3085,3353
12250,1388,2099,2516,2810,3091,3360
12300,1391,2104,2521,2816,3098,3367
12350,1395,2109,2527,2822,3104,3375
12400,1398,2113,2532,2828,3111,3382
12450,1401,2118,2537,2834,3118,3389
12500,1405,2123,2543,2840,3124,3396
12550,1408,2128,2548,2846,3131,3403
12600,1411,2132,2553,2852,3137,3410
12650,1414,2137,2559,2858,3144,3417
12700,1418,2142,2564,2864,3150,3424
12750,1421,2146,2569,2870,3157,3431
12800,1424,2151,2575,2876,3163,3439
12850,1427,2156,2580,2882,3170,3446
12900,1431,2160,2585,2888,3176,3453
12950,1434,2165,2591,2894,3184,3461
13000,1438,2171,2598,2903,3193,3471
13050,1441,2177,2606,2911,3202,3480
13100,1444,2183,2613,2919,3211,3490
13150,1448,2188,2621,2927,3220,3500
13200,1451,2194,2628,2936,3229,3510
13250,1455,2200,2636,2944,3239,3520
13300,1458,2205,2643,2952,3248,3530
13350,1462,2211,2651,2961,3257,3540
13400,1465,2217,2658,2969,3266,3550
13450,1469,2223,2666,2977,3275,3560
13500,1472,2228,2673,2986,3284,3570
13550,1475,2234,2680,2994,3293,3580
13600,1479,2240,2688,3002,3303,3590
13650,1482,2246,2695,3011,3312,3600
13700,1486,2251,2703,3019,3321,3610
13750,1489,2257,2710,3027,3330,3620
13800,1493,2263,2718,3036,3339,3630
13850,1496,2268,2725,3044,3348,3640
13900,1500,2274,2733,3052,3358,3650
13950,1503,2280,2740,3061,3367,3660
14000,1506,2286,2748,3069,3376,3670
14050,1510,2291,2755,3077,3385,3680
14100,1513,2297,2762,3086,3394,3690
14150,1517,2303,2770,3094,3403,3699
14200,1520,2309,2777,3102,3413,3709
14250,1524,2314,2783,3109,3420,3717
14300,1528,2319,2789,3115,3427,3725
14350,1532,2325,2795,3122,3434,3732
14400,1536,2330,2800,3128,3441,3740
14450,1540,2336,2806,3134,3448,3748
14500,1544,2341,2812,3141,3455,3755
14550,1548,2346,2817,3147,3462,3763
14600,1552,2352,2823,3153,3469,3771
14650,1556,2357,2829,3160,3476,3778
14700,1560,2362,2835,3166,3483,3786
14750,1564,2368,2840,3173,3490,3793
14800,1568,2373,2846,3179,3497,3801
14850,1572,2379,2852,3185,3504,3809
14900,1576,2384,2857,3192,3511,3816
14950,1580,2389,2863,3198,3518,3824
15000,1584,2395,2869,3204,3525,3832
15050,1588,2400,2875,3211,3532,3839
15100,1592,2406,2880,3217,3539,3847
15150,1596,2411,2886,3223,3545,3854
15200,1599,2416,2891,3229,3552,3861
15250,1603,2421,2896,3235,3558,3868
15300,1607,2426,2901,3241,3565,3875
15350,1610,2431,2907,3247,3571,3882
15400,1614,2436,2912,3253,3578,3889
15450,1618,2441,2917,3258,3584,3896
15500,1621,2445,2922,3264,3591,3903
15550,1623,2448,2926,3268,3595,3908
15600,1625,2451,2929,3272,3599,3912
15650,1627,2454,2933,3276,3603,3917
15700,1629,2457,2936,3280,3607,3921
15750,1630,2459,2939,3283,3612,3926
15800,1632,2462,2943,3287,3616,3930
15850,1634,2465,2946,3291,3620,3935
15900,1636,2468,2950,3295,3624,3940
15950,1638,2471,2953,3299,3628,3944
16000,1639,2473,2957,3302,3633,3949
16050,1641,2476,2960,3306,3637,3953
16100,1643,2479,2963,3310,3641,3958
16150,1645,2482,2967,3314,3645,3962
16200,1647,2485,2970,3318,3649,3967
16250,1649,2487,2974,3322,3654,3972
16300,1650,2490,2977,3325,3658,3976
16350,1652,2493,2980,3329,3662,3981
16400,1654,2496,2984,3333,3666,3985
16450,1656,2499,2987,3337,3670,3990
16500,1658,2501,2991,3341,3675,3994
16550,1659,2504,2994,3344,3679,3999
16600,1661,2507,2998,3348,3683,4004
16650,1663,2510,3001,3352,3687,4008
16700,1665,2513,3004,3356,3691,4013
16750,1667,2515,3008,3360,3696,4017
16800,1668,2518,3011,3364,3700,4022
16850,1670,2521,3015,3367,3704,4026
16900,1672,2524,3018,3371,3708,4031
16950,1674,2527,3021,3375,3712,4035
17000,1676,2529,3025,3379,3717,4040
17050,1678,2532,3028,3383,3721,4045
17100,1679,2535,3032,3386,3725,4049
17150,1681,2538,3035,3390,3729,4054
17200,1683,2541,3039,3394,3733,4058
17250,1685,2543,3042,3398,3738,4063
17300,1687,2546,3045,3402,3742,4067
17350,1688,2549,3049,3406,3746,4072
17400,1690,2552,3052,3409,3750,4077
17450,1692,2555,3056,3413,3754,4081
17500,1694,2557,3059,3417,3759,4086
17550,1696,2560,3063,3421,3763,4090
17600,1698,2564,3067,3426,3769,4096
17650,1701,2568,3072,3431,3774,4103
17700,1704,2572,3076,3436,3780,4109
17750,1706,2576,3081,3441,3785,4115
17800,1709,2580,3085,3446,3791,4121
17850,1711,2583,3090,3451,3797,4127
17900,1714,2587,3095,3457,3802,4133
17950,1717,2591,3099,3462,3808,4139
18000,1719,2595,3104,3467,3813,4145
18050,1722,2599,3108,3472,3819,4151
18100,1724,2603,3113,3477,3825,4157
18150,1727,2607,3117,3482,3830,4164
18200,1730,2611,3122,3487,3836,4170
18250,1732,2615,3127,3492,3842,4176
18300,1735,2618,3131,3497,3847,4182
18350,1738,2622,3136,3503,3853,4188
18400,1740,2626,3140,3508,3858,4194
18450,1743,2630,3145,3513,3864,4200
18500,1745,2634,3149,3518,3870,4206
18550,1748,2638,3154,3523,3875,4212
18600,1751,2642,3159,3528,3881,4219
18650,1753,2646,3163,3533,3887,4225
18700,1756,2650,3168,3538,3892,4231
18750,1758,2653,3172,3543,3898,4237
18800,1761,2657,3177,3549,3903,4243
18850,1764,2661,3181,3554,3909,4249
18900,1766,2665,3186,3559,3915,4255
18950,1769,2669,3191,3564,3920,4261
19000,1771,2673,3195,3569,3926,4267
19050,1774,2677,3200,3574,3931,4274
19100,1777,2681,3204,3579,3937,4280
19150,1779,2685,3209,3584,3943,4286
19200,1782,2689,3213,3589,3948,4292
19250,1785,2692,3218,3595,3954,4298
19300,1787,2696,3223,3600,3960,4304
19350,1790,2700,3227,3605,3965,4310
19400,1792,2704,3232,3610,3971,4316
19450,1795,2708,3236,3615,3976,4322
19500,1798,2712,3241,3620,3982,4328
19550,1800,2716,3245,3625,3988,4335
19600,1803,2720,3250,3630,3993,4341
19650,1805,2724,3255,3635,3999,4347
19700,1808,2727,3259,3640,4005,4353
19750,1811,2731,3264,3646,4010,4359
19800,1813,2735,3268,3651,4016,4365
19850,1816,2739,3273,3656,4021,4371
19900,1819,2743,3277,3661,4027,4377
19950,1821,2747,3282,3666,4033,4383
20000,1824,2751,3287,3671,4038,4390
20050,1826,2755,3291,3676,4044,4396
20100,1829,2759,3296,3681,4049,4402
20150,1832,2762,3300,3686,4055,4408
20200,1834,2766,3305,3692,4061,4414
20250,1837,2770,3309,3697,4066,4420
20300,1839,2774,3314,3702,4072,4426
20350,1842,2778,3319,3707,4078,4432
20400,1845,2782,3323,3712,4083,4438
20450,1847,2786,3328,3717,4089,4445
20500,1850,2790,3332,3722,4094,4451
20550,1853,2794,3337,3727,4100,4457
20600,1855,2797,3341,3732,4106,4463
20650,1858,2801,3346,3738,4111,4469
20700,1860,2805,3351,3743,4117,4475
20750,1863,2809,3355,3748,4123,4481
20800,1866,2813,3360,3753,4128,4487
20850,1868,2817,3364,3758,4134,4493
20900,1871,2821,3369,3763,4139,4500
20950,1873,2825,3373,3768,4145,4506
21000,1876,2829,3378,3773,4151,4512
21050,1879,2832,3383,3778,4156,4518
21100,1881,2836,3387,3784,4162,4524
21150,1884,2840,3392,3789,4167,4530
21200,1887,2844,3396,3794,4173,4536
21250,1889,2848,3401,3799,4179,4542
21300,1892,2852,3405,3804,4184,4548
21350,1894,2856,3410,3809,4190,4554
21400,1897,2860,3415,3814,4196,4561
21450,1900,2864,3419,3819,4201,4567
21500,1902,2867,3424,3824,4207,4573
21550,1905,2871,3428,3829,4212,4579
21600,1907,2875,3433,3835,4218,4585
21650,1910,2879,3438,3840,4224,4591
21700,1913,2883,3442,3845,4229,4597
21750,1915,2887,3447,3850,4235,4603
21800,1918,2891,3451,3855,4241,4609
21850,1921,2895,3456,3860,4246,4616
21900,1923,2899,3460,3865,4252,4622
21950,1926,2902,3465,3870,4257,4628
22000,1928,2906,3470,3875,4263,4634
22050,1931,2910,3474,3881,4269,4640
22100,1934,2914,3479,3886,4274,4646
22150,1936,2918,3483,3891,4280,4652
22200,1939,2922,3488,3896,4285,4658
22250,1941,2926,3492,3901,4291,4664
22300,1944,2930,3497,3906,4297,4671
22350,1947,2934,3502,3911,4302,4677
22400,1949,2937,3506,3916,4308,4683
22450,1952,2941,3511,3921,4314,4689
22500,1955,2945,3515,3927,4319,4695
22550,1957,2949,3520,3932,4325,4701
22600,1960,2953,3524,3937,4330,4707
22650,1962,2957,3529,3942,4336,4713
22700,1965,2961,3534,3947,4342,4719
22750,1968,2965,3538,3952,4347,4725
22800,1970,2969,3543,3957,4353,4732
22850,1973,2972,3547,3962,4359,4738
22900,1975,2976,3552,3967,4364,4744
22950,1978,2980,3556,3973,4370,4750
23000,1981,2984,3561,3978,4375,4756
23050,1983,2988,3566,3983,4381,4762
23100,1986,2992,3570,3988,4387,4768
23150,1989,2996,3575,3993,4392,4774
23200,1991,3000,3579,3998,4398,4780
23250,1994,3004,3584,4003,4404,4787
23300,1998,3010,3591,4011,4412,4796
23350,2002,3016,3598,4019,4421,4806
23400,2006,3022,3606,4027,4430,4816
23450,2010,3028,3613,4035,4439,4825
23500,2014,3034,3620,4044,4448,4835
23550,2018,3040,3627,4052,4457,4844
23600,2022,3046,3634,4060,4466,4854
23650,2026,3052,3642,4068,4474,4864
23700,2030,3058,3649,4076,4483,4873
23750,2034,3064,3656,4084,4492,4883
23800,2038,3070,3663,4092,4501,4893
23850,2042,3076,3670,4100,4510,4902
23900,2046,3082,3678,4108,4519,4912
23950,2050,3088,3685,4116,4528,4922
24000,2054,3094,3692,4124,4536,4931
24050,2058,3100,3699,4132,4545,4941
24100,2062,3106,3707,4140,4554,4950
24150,2066,3112,3714,4148,4563,4960
24200,2070,3118,3721,4156,4572,4970
24250,2074,3124,3728,4164,4581,4979
24300,2078,3130,3735,4172,4590,4989
24350,2082,3137,3743,4180,4598,4999
24400,2086,3143,3750,4188,4607,5008
24450,2090,3149,3757,4197,4616,5018
24500,2094,3155,3764,4205,4625,5027
24550,2098,3161,3771,4213,4634,5037
24600,2102,3167,3779,4221,4643,5047
24650,2106,3173,3786,4229,4652,5056
24700,2110,3179,3793,4237,4661,5066
24750,2114,3185,3800,4245,4669,5076
24800,2118,3191,3807,4253,4678,5085
24850,2122,3197,3815,4261,4687,5095
24900,2126,3203,3822,4269,4696,5104
24950,2130,3209,3829,4277,4705,5114
25000,2134,3215,3836,4285,4714,5124
25050,2138,3221,3844,4293,4723,5133
25100,2142,3227,3851,4301,4731,5143
25150,2146,3233,3858,4309,4740,5153
25200,2150,3239,3865,4317,4749,5162
25250,2154,3245,3872,4325,4758,5172
25300,2158,3251,3880,4333,4767,5182
25350,2162,3257,3887,4342,4776,5191
25400,2166,3263,3894,4350,4785,5201
25450,2170,3269,3901,4358,4793,5210
25500,2174,3276,3908,4366,4802,5220
25550,2178,3282,3916,4374,4811,5230
25600,2182,3288,3923,4382,4820,5239
25650,2186,3294,3930,4390,4829,5249
25700,2190,3300,3937,4398,4838,5259
25750,2194,3306,3944,4406,4847,5268
25800,2198,3312,3952,4414,4855,5278
25850,2202,3318,3959,4422,4864,5287
25900,2206,3324,3966,4430,4873,5297
25950,2210,3330,3973,4438,4882,5307
26000,2214,3336,3981,4446,4891,5316
26050,2218,3342,3988,4454,4900,5326
26100,2222,3348,3995,4462,4909,5336
26150,2226,3354,4002,4470,4917,5345
26200,2230,3360,4009,4478,4926,5355
26250,2234,3366,4017,4486,4935,5365
26300,2238,3372,4024,4495,4944,5374
26350,2242,3378,4031,4503,4953,5384
26400,2247,3384,4038,4511,4962,5393
26450,2251,3390,4045,4519,4971,5403
26500,2255,3396,4053,4527,4979,5413
26550,2259,3402,4060,4535,4988,5422
26600,2263,3408,4067,4543,4997,5432
26650,2267,3415,4074,4551,5006,5442
26700,2271,3421,4081,4559,5015,5451
26750,2275,3427,4089,4567,5024,5461
26800,2279,3433,4096,4575,5033,5470
26850,2283,3439,4103,4583,5041,5480
26900,2287,3445,4110,4591,5050,5490
26950,2291,3451,4118,4599,5059,5499
27000,2295,3457,4125,4607,5068,5509
27050,2299,3463,4132,4615,5077,5519
27100,2303,3469,4139,4623,5086,5528
27150,2307,3475,4146,4631,5095,5538
27200,2311,3481,4154,4640,5103,5547
27250,2315,3487,4161,4648,5112,5557
27300,2319,3493,4168,4656,5121,5567
27350,2323,3499,4175,4664,5130,5576
27400,2327,3505,4182,4672,5139,5586
27450,2331,3511,4190,4680,5148,5596
27500,2335,3517,4197,4688,5157,5605
27550,2339,3523,4204,4696,5165,5615
27600,2343,3529,4211,4704,5174,5625
27650,2347,3535,4218,4712,5183,5634
27700,2351,3541,4226,4720,5192,5644
27750,2355,3547,4233,4728,5201,5653
27800,2359,3554,4240,4736,5210,5663
27850,2363,3560,4247,4744,5219,5673
27900,2367,3566,4255,4752,5228,5682
27950,2371,3572,4262,4760,5236,5692
28000,2375,3578,4269,4768,5245,5702
28050,2379,3584,4276,4776,5254,5711
28100,2383,3590,4283,4785,5263,5721
28150,2387,3596,4291,4793,5272,5730
28200,2391,3602,4298,4801,5281,5740
28250,2395,3608,4305,4809,5290,5750
28300,2399,3614,4312,4817,5298,5759
28350,2403,3620,4319,4825,5307,5769
28400,2407,3626,4327,4833,5316,5779
28450,2411,3632,4334,4841,5325,5788
28500,2415,3638,4341,4849,5334,5798
28550,2419,3644,4348,4857,5343,5808
28600,2423,3650,4355,4865,5352,5817
28650,2427,3656,4363,4873,5360,5827
28700,2431,3662,4370,4881,5369,5836
28750,2435,3668,4377,4889,5378,5846
28800,2439,3674,4384,4897,5387,5856
28850,2443,3680,4392,4905,5396,5865
28900,2447,3686,4399,4913,5405,5875
28950,2451,3692,4406,4921,5414,5885
29000,2455,3699,4413,4929,5422,5894
29050,2459,3705,4420,4938,5431,5904
29100,2463,3711,4428,4946,5440,5913
29150,2467,3717,4435,4954,5449,5923
29200,2471,3723,4442,4962,5458,5933
29250,2475,3729,4449,4970,5467,5942
29300,2479,3735,4456,4978,5476,5952
29350,2483,3741,4464,4986,5484,5962
29400,2487,3747,4471,4994,5493,5971
29450,2491,3753,4478,5002,5502,5981
29500,2495,3759,4485,5010,5511,5990
29550,2499,3765,4492,5018,5520,6000
29600,2503,3771,4500,5026,5529,6010
29650,2507,3777,4507,5034,5538,6019
29700,2511,3783,4514,5042,5546,6029
29750,2515,3789,4521,5050,5555,6039
29800,2519,3795,4529,5058,5564,6048
29850,2523,3801,4536,5066,5573,6058
29900,2527,3807,4543,5074,5582,6068
29950,2531,3813,4550,5083,5591,6077
30000,2535,3819,4557,5091,5600,6087`
