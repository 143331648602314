import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import firebase from '../../config/firebase'
import LinkButton from '../../components/buttons/LinkButton'
import Header from '../../components/Header'
import ContentBlock from '../../components/ContentBlock'
import { useSession } from '../../App'
import AppButton from '../../components/buttons/AppButton'
import { getUserInvoiceHistory } from '../APICalls/APICalls'
import './billing-history.css'
import { formatter, dateFormatter } from '../../helpers/converters'
import Grid5Columns from '../../components/layouts/Grid5Columns'
import InvoiceColumns from '../../components/InvoiceColumns/InvoiceColumns'



const SignOutButton = styled(AppButton)`
    position: absolute;
    right: 0;
    top: 0;
`
const DownloadHistory = styled(AppButton)`
    position: absolute;
    left: 0;
    bottom: 0;
`

const BillingHistory = () => {
    const demoMode = true
    const [redirectPath, setRedirectPath] = useState(false)

    const { user } = useSession()
    const [invoices, setInvoice] = useState([])

    useEffect(() => {
      getUserInvoiceHistory(user.uid, setInvoice)
        .then(invoiceHistory => {
          setInvoice(invoiceHistory)
        })
        .catch(error => {
          console.log(' - BillingHistory error - ', error)
        })
    }, []);

    return (
        <section>
            <Header version='loggedIn'/>
            <ContentBlock>
                <h2>Billing History</h2>
                <br />
                <br />
                <Grid5Columns>
                    <InvoiceColumns>Transaction ID</InvoiceColumns>
                    <InvoiceColumns>Date Paid</InvoiceColumns>
                    <InvoiceColumns>Billing Period End</InvoiceColumns>
                    <InvoiceColumns>Amount</InvoiceColumns>
                    <InvoiceColumns>Status</InvoiceColumns>
                </Grid5Columns>
                <br />
                {invoices.map((invoice) => (
                    <Grid5Columns key={invoice.id}>
                        <p>{invoice.id}</p>
                        <p>{dateFormatter(invoice.datePaid)}</p>
                        <p>{dateFormatter(invoice.billingPeriodEnd)}</p>
                        <p>{formatter.format(invoice.amountPaid)}</p>
                        <p>{invoice.status}</p>
                    </Grid5Columns>
                    
                ))}
                <br />
                <br />
                <SignOutButton onClick={() => {
                    if (demoMode) {
                    alert('Demo only')
                    setRedirectPath('/')
                } else {
                    firebase
                    .auth()
                    .signOut()
                    .then(() => setRedirectPath('/'))
                }
                }}>Sign Out
                </SignOutButton>
            </ContentBlock>
        </section>
    )
}

export default BillingHistory;
