export const notAvailable = 'N/A'
export const blank = ''
export const child = {
  firstName: blank,
  lastName: blank,
  dateOfBirth: blank
}
export const adjustments = {
  // prev ux
  maintenancePaid: false,
  maintenancePaidIsTaxDeductible: false,
  maintenanceReceived: false,
  maintenanceReceivedIsTaxable: false,
  maintenanceReceivedAppliesToThisRelationship: false,
  // new ux
  // NOTR means not of this releationship)
  maintenancePaidNOTR: false,
  maintenancePaidNOTRIsTaxDeductible: false,
  maintenanceReceivedNOTR: false,
  maintenanceReceivedNOTRIsTaxDeductible: false,
  supportPaidChildrenNOTR: false,
  // Non-Joint Children
  // TODO: update vars from using OtherRelationship to NOTR for consistency
  // here and in PDF export fucnctions/handlers
  numberOfChildrenOtherRelationshipPrimaryLiving: false,
  documentedPaymentsChildrenOtherRelationship: false,
  numberOfChildrenOtherRelationshipNotPrimaryLiving: false,
  otherRelationshipResponsibility: false
}
export const party = {
  caseProfileParty: blank,
  firstName: blank,
  lastName: blank,
  displayAs: blank,
  monthlyGross: false
}
export const supportObligationAdjustments = {
  workRelatedChildCare: false,
  netWorkRelatedChildCare: false,
  educationRelatedChildCare: false,
  healthInsurancePremiums: false,
  extraordinaryMedical: false,
  extraordinaryExpenses: false,
  extraordinaryAdjustments: false,
  totalAdjustments: false
}

const caseHeader = {
  workSheetType: 'a',
  courtType: notAvailable,
  courtCounty: notAvailable,
  courtAddress: notAvailable,
  caseType: notAvailable,
  party1Type: notAvailable,
  party2Type: notAvailable,
  filingPartyDesignation: notAvailable,
  filingPartyName: notAvailable,
  filingPartyAddress1: notAvailable,
  filingPartyAddress2: notAvailable,
  filingPartyPhone: notAvailable,
  filingPartyFax: notAvailable,
  filingPartyEmail: notAvailable,
  filingPartyAttyReg: notAvailable,
  caseNumber1: notAvailable,
  caseNumber2: notAvailable,
  divisionCourtroom: notAvailable,
}

const children =  [ { name: blank, dob: blank } ]

const comments = blank

const description = {
  adjustedmaintenancereceivedexplained: "Adjusted Maintenance Received Explained",
  adjustedmaintenancerepaidexplained: "Adjusted Maintenance Repaid Explained"
}

const worksheetAParty = {
  label: notAvailable,
  monthlygrossincome: notAvailable,
  adjustedmaintenancereceived: notAvailable,
  adjustedmaintenancepaid: notAvailable,
  supportPaidChildrenNOTR: notAvailable,
  otherRelationshipResponsibility: notAvailable,
  cappedDocumentedPaymentsChildrenOtherRelationship: notAvailable,
  monthlyAdjustedGrossIncomes: notAvailable,
  shareofincome: notAvailable,
  shareofbasicobligation: notAvailable,
  lowincomeadjustment: notAvailable,
  workchildcarecosts: notAvailable,
  educhildcarecosts: notAvailable,
  healthinscosts: notAvailable,
  medexpenses: notAvailable,
  extraexpenses: notAvailable,
  extraadjustments: notAvailable,
  totaladjustments: notAvailable,
  shareadjustments: notAvailable,
  Payingparentsharetotal: notAvailable,
  Payingparentadjustment: notAvailable,
  Payingparentsupporttotal: notAvailable
}

export const worksheetAPdfExport = {
  caseHeader,
  children,
  worksheet: {
    description,
    party1: Object.assign({}, worksheetAParty),
    party2: Object.assign({}, worksheetAParty),
    combined: {
      combinedAdjustedGrossIncome: notAvailable,
      basicsupportobligation: notAvailable,
      Combinedtotaladjustments: notAvailable
    }
  },
  comments
}

const worksheetBParty = {
  label: notAvailable,
  monthlygrossincome: notAvailable,
  adjustedmaintenancereceived: notAvailable,
  adjustedmaintenancepaid: notAvailable,
  supportPaidChildrenNOTR: notAvailable,
  otherRelationshipResponsibility: notAvailable,
  cappedDocumentedPaymentsChildrenOtherRelationship: notAvailable,
  monthlyAdjustedGrossIncomes: notAvailable,
  shareofincome: notAvailable,
  shareofbasicobligation: notAvailable,
  lowincomeadjustment: notAvailable,
  workchildcarecosts: notAvailable,
  educhildcarecosts: notAvailable,
  healthinscosts: notAvailable,
  medexpenses: notAvailable,
  extraexpenses: notAvailable,
  extraadjustments: notAvailable,
  totaladjustments: notAvailable,
  shareadjustments: notAvailable,
  Payingparentsharetotal: notAvailable,
  Payingparentadjustment: notAvailable,
  Payingparentsupporttotal: notAvailable,
  overnightstays: notAvailable,
  percentagetime: notAvailable,
  supportfortime: notAvailable,
  excessadjustmentspaid: notAvailable,
  adjustedobligation: notAvailable,
}

export const worksheetBPdfExport = {
  caseHeader: Object.assign({}, caseHeader, { workSheetType: 'b', }),
  children,
  worksheet: {
    description,
    party1: worksheetBParty,
    party2: worksheetBParty,
    combined: {
      combinedAdjustedGrossIncome: notAvailable,
      basicsupportobligation: notAvailable,
      Combinedtotaladjustments: notAvailable,
      sharedphysicalcaresupportobligation: notAvailable
    }
  },
  comments
}

