import { pdfLayout, PDFHeader, PDFLineBreak, PDFChildrenTable, PDFCalculationsTable } from './index';

export const constructPDFData = (data) => {

  let pdf = pdfLayout();

  // Constructing the header of the worksheet
  pdf.content.push(PDFHeader(data));

  // Adding a line break between the header, and child list.
  pdf.content.push(PDFLineBreak);

  pdf.content.push(PDFChildrenTable(data));

  pdf.content.push(PDFLineBreak);

  pdf.content = pdf.content.concat(PDFCalculationsTable(data));

  return pdf;
};
