import firebase from '../../config/firebase'
import { headersTemplate } from '../../hooks/useAuth'

// todo: move API calls to hooks using fetchWithAuth export from useAuth

export const addCaseDocument = (caseId) => {
  if (firebase.auth().currentUser) {
    return new Promise((resolve, reject) => {
      firebase.auth().currentUser.getIdToken(true)
        .then(token => {
          const options = {
            method: 'POST',
            body: JSON.stringify('caseDocType'),
            headers: headersTemplate(token)
          }
          fetch(`/api/v1/my/cases/${caseId}/child-support-calculation`, options)
          .then(res => {
            resolve(res.json())
          })
          .catch(error => {
            reject(error)
          })
        })
    })
  }
}

export const validatePromoCode = (stripePromoCode, stripePriceId) => {
	if (firebase.auth().currentUser) {
    return new Promise((resolve, reject) => {
      firebase.auth().currentUser.getIdToken(true)
        .then(token => {
          const options = { headers: headersTemplate(token) }
          fetch(`/api/v1/promocode?stripe_promo_code=${stripePromoCode}&stripe_price_id=${stripePriceId}`, options)
            .then(res => {
              resolve(res.json())
            })
            .catch(error => {
              reject(error)
            })
        })
    })
	}
}



export const createSubscription = (customerId, paymentMethodId, priceId, stripePromoCode) => {
	if (firebase.auth().currentUser) {
    return new Promise((resolve, reject) => {
      firebase.auth().currentUser.getIdToken(true)
        .then(token => {
          const options = {
            method: 'POST',
            body: JSON.stringify({
              stripePaymentMethodId: paymentMethodId,
              stripeCustomerId: customerId,
              stripePriceId: priceId,
              stripePromoCode: stripePromoCode || null
            }),
            headers: headersTemplate(token)
          }
          fetch('/api/v1/my/subscriptions', options)
            .then(res => {
                return resolve(res.json())
            })
            .catch(error => {
              console.log(error)
              reject(error)
            })
        })
    })
	}
}

export const getUserInvoiceHistory = ({ customerId }) => {
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true)
      .then(token => {
        const options = { headers: headersTemplate(token) }
        fetch(`/api/v1/my/invoices`, options)
          .then(res => resolve(res.json()) )
          .catch(error => reject(`Error: ${error.message}`))
      })
  })
}

