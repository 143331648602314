import moment from 'moment'
import { CompoundingModels } from '../constants/interest-calculator-settings'
import * as freq from '../constants/FrequencyTypes'
import * as ActionTypes from '../constants/ActionTypes'
import * as InterestCalculatorTypes from '../constants/InterestCalculatorTypes'
import { convertMonthlyAmount, roundToCents } from '../helpers/converters'
import { calculateCompoundInterest, dynamicChildSupportInterest } from '../helpers/interest'
import { createSchedule } from '../helpers/schedule'
import { chronologically } from '../helpers/sorters'
import { maxId } from '../helpers/reducers'
import * as Errors from './errors'
const verbose = false

export const initialState = (currentDateISO) => ({
  currentDateISO,
  supportType: null,
  compoundingModel: CompoundingModels.MONTHLY,
  interestRate: date => 0.12,
  editingCalculatorItemId: null,
  editingCalculatorItemIds: null,
  deletingCalculatorItemIds: null,
  itemsDue: [],
  itemsPaid: [],
  itemsInterest: [],
  calculatorItems: [],
  calculatorItemsNextId: 1,
  uiModalType: false,
  uiModalIn: false,
  uiLastAction: false,
  contentInterestRateInfoOverride: null
})

const itemTypeFromGroupType = (groupType) => {
  if ('PAYMENT_GROUP_DUE' === groupType) return 'PAYMENT_DUE'
  if ('PAYMENT_GROUP_MADE' === groupType) return 'PAYMENT_MADE'
}

export const itemsFromGroup = (group, nextId, currentDateISO) => {
  let items = []
  const { frequency, retroactiveTotalAmount } = group
  const scheduleOptions = { frequency }
  const amount = convertMonthlyAmount(frequency, group.amount)
  if (freq.SINGLE === frequency) {
    Object.assign(scheduleOptions, {
      startDate: moment(group.date)
    })
  } else if (freq.WEEKLY === frequency
    || freq.BIWEEKLY === frequency) {
    Object.assign(scheduleOptions, {
      startDate: moment(group.startDate),
      endDate: moment(group.endDate)
    })
  } else if (freq.SEMIMONTHLY === frequency ) {
    Object.assign(scheduleOptions, {
      startMonth: group.startMonth,
      startYear: group.startYear,
      endMonth: group.endMonth,
      endYear: group.endYear,
      dayOfMonth1: group.semimonthlyDayOfMonth,
      dayOfMonth2: group.semimonthlyDayOfMonth2
    })
  } else if (freq.MONTHLY === frequency ) {
    Object.assign(scheduleOptions, {
      startMonth: group.startMonth,
      startYear: group.startYear,
      endMonth: group.endMonth,
      endYear: group.endYear,
      monthlyDayOfMonth: group.monthlyDayOfMonth,
    })
  }
  const schedule = createSchedule(scheduleOptions)
  schedule.forEach((date, ind) => items.push({
    id: nextId++,
    type: itemTypeFromGroupType(group.type),
    title: group.title,
    date: moment(date),
    amount: amount,
    comments: group.comments,
    editable: true
  }))
  if (retroactiveTotalAmount > 0) {
    generateRetroactiveItems(group, nextId)
      .forEach(item => items.push(item))
  }
  return items
}

export const generateInterestItems = (items, interestRate, currentDateISO, options = {}) => {
  let interestItems = []
  const { compoundingModel = CompoundingModels.MONTHLY  } = options
  const numberOfTimesInterestIsCompoundedPerYear = date => {
    const compoundingModelOnDate = CompoundingModels.DYNAMIC_CHILD_SUPPORT_INTEREST === compoundingModel ?
      dynamicChildSupportInterest(date).compoundingModel : compoundingModel
    return CompoundingModels.ANNUALLY === compoundingModelOnDate ? 1 : 12
  }
  const today = moment(currentDateISO, moment.ISO_8601)
  if (items.length > 0) {
    let itemsCopy = items.slice(0).sort(chronologically)
    let currentBalance = 0
    let currentDate, previousDate
    // add a placeholder transaction so interest is calculated up to today
    itemsCopy.push({
      type: 'PLACEHOLDER',
      title: 'Today',
      amount: 0,
      comments: '',
      date: moment(today),
      editable: false
    })
    const dayFormat = 'DD-MM-YYYY'
    itemsCopy.forEach((item, ind) => {
      previousDate = moment(currentDate)
      currentDate = moment(item.date)
      if (0 < ind
        && currentDate.format(dayFormat) !== previousDate.format(dayFormat)
          && 0 < currentBalance
      ) {
        const startOfInterestPeriod = moment(previousDate)
        const endOfInterestPeriod = moment(currentDate)
        const daysOfInterest = endOfInterestPeriod.diff(startOfInterestPeriod, 'days')
        const interestDueAmount = roundToCents(calculateCompoundInterest(
          currentBalance,
          daysOfInterest,
          interestRate(currentDate),
          numberOfTimesInterestIsCompoundedPerYear(currentDate)
        ))
        if (interestDueAmount > 0) {
          interestItems.push({
            type: 'INTEREST_DUE',
            title: 'Interest due',
            amount: interestDueAmount,
            comments: '',
            date: endOfInterestPeriod,
            editable: false
          })
          currentBalance = currentBalance + interestDueAmount
        }
      }
      currentBalance = 'PAYMENT_MADE' === item.type ? currentBalance - item.amount : currentBalance + item.amount
    })
  }
  return interestItems
}

export const generateRetroactiveItems = (group, nextId) => {
  const items = []
  const { frequency, retroactiveMonthlyAmount, retroactiveTotalAmount } = group
  const totalAmount = retroactiveTotalAmount
  const monthlyAmount = retroactiveMonthlyAmount
  const defaults = {
    type: 'PAYMENT_DUE',
    title: 'Retroactive payment due',
    comments: group.comments,
    editable: true
  }
  if ('LUMP_SUM' === group.retroactiveInterestModel) {
    items.push(Object.assign({}, defaults, {
      id: nextId,
      date: moment(group.supportOrderEnteredDate),
      amount: totalAmount
    }))
  } else if (freq.SINGLE === frequency) {
    items.push(Object.assign({}, defaults, {
      id: nextId,
      date: moment(group.supportOrderEnteredDate),
      amount: totalAmount
    }))
  } else if (freq.WEEKLY === frequency) {
    const weeklyAmount = convertMonthlyAmount(freq.WEEKLY, monthlyAmount)
    const numberOfWeeklyItems = Math.floor(totalAmount / weeklyAmount)
    const endDate = moment(group.startDate).add(numberOfWeeklyItems - 1, 'weeks')
    createSchedule({
      frequency,
      startDate: group.startDate,
      endDate: endDate
    }).forEach((date, ind)  => {
      items.push(Object.assign({}, defaults, {
        id: nextId + ind,
        amount: weeklyAmount,
        date
      }))
    })
    const remainder = roundToCents(totalAmount % weeklyAmount)
    if (remainder > 0) {
      const remainderId = 1 + items.reduce(maxId, nextId)
      items.push(Object.assign({}, defaults, {
        id: remainderId,
        date: moment(endDate).add(1, 'week'),
        amount: remainder
      }))
    }
  } else if (freq.BIWEEKLY === frequency) {
    const biweeklyAmount = convertMonthlyAmount(freq.BIWEEKLY, monthlyAmount)
    const numberOfBiweeklyItems = Math.floor(totalAmount / biweeklyAmount)
    const endDate = moment(group.startDate)
      .add(2 * (numberOfBiweeklyItems - 1), 'weeks')
    createSchedule({
      frequency,
      startDate: group.startDate,
      endDate: endDate
    }).forEach((date, ind)  => {
      items.push(Object.assign({}, defaults, {
        id: nextId + ind,
        amount: biweeklyAmount,
        date
      }))
    })
    const remainder = roundToCents(totalAmount % biweeklyAmount)
    if (remainder > 0) {
      const remainderId = 1 + items.reduce(maxId, nextId)
      items.push(Object.assign({}, defaults, {
        id: remainderId,
        date: moment(endDate).add(2, 'week'),
        amount: remainder
      }))
    }
  } else if (freq.SEMIMONTHLY === frequency) {
    const { startMonth, startYear, endMonth, endYear, dayOfMonth1, dayOfMonth2 }
      = group
    const semimonthlyAmount = convertMonthlyAmount(
      freq.SEMIMONTHLY,
      monthlyAmount
    )
    const numberOfPayments = Math.floor(totalAmount/semimonthlyAmount)
    const remainder = roundToCents(totalAmount % semimonthlyAmount)
    createSchedule({
      frequency,
      startMonth,
      startYear,
      endMonth,
      endYear,
      dayOfMonth1,
      dayOfMonth2
    }).forEach((date, ind) => {
      if (ind < numberOfPayments) {
        items.push(Object.assign({}, defaults, {
          id: nextId + ind,
          date: moment(date),
          amount: semimonthlyAmount
        }))
      } else if (ind === numberOfPayments && remainder > 0) {
        items.push(Object.assign({}, defaults, {
          id: nextId + ind,
          date: moment(date),
          amount: remainder
        }))
      }
    })
  } else if (freq.MONTHLY === frequency) {
    const { startMonth, startYear, endMonth, endYear, monthlyDayOfMonth } = group
    const numberOfPayments = Math.floor(totalAmount/monthlyAmount)
    const remainder = roundToCents(totalAmount % monthlyAmount)
    createSchedule({
      frequency,
      startMonth,
      startYear,
      endMonth,
      endYear,
      monthlyDayOfMonth
    }).forEach((date, ind) => {
      if (ind < numberOfPayments) {
        items.push(Object.assign({}, defaults, {
          id: nextId + ind,
          date: moment(date),
          amount: monthlyAmount
        }))
      } else if (ind === numberOfPayments && remainder > 0) {
        items.push(Object.assign({}, defaults, {
          id: nextId + ind,
          date: moment(date),
          amount: remainder
        }))
      }
    })
  }
  return items
}

function interestCalculculatorApp(state, action) {
  if ('undefined' === typeof state) throw new Errors.InitialStateRequired()
  if ('development' === process.env.NODE_ENV && verbose) console.log(action)
  let itemsDue, itemsPaid, itemsInterest, itemIndex, calculatorItemsNextId
  const { compoundingModel } = state
  switch (action.type) {

    // ui & modal actions

  case ActionTypes.OPEN_ADD_PAYMENTS_DUE_MODAL:
    return Object.assign({}, state, {
      uiModalType: 'ADD_PAYMENTS_DUE',
      uiModalIn: true
    })

  case ActionTypes.OPEN_ADD_PAYMENTS_MADE_MODAL:
    return Object.assign({}, state, {
      uiModalType: 'ADD_PAYMENTS_MADE',
      uiModalIn: true
    })

  case ActionTypes.OPEN_EDIT_CALCULATOR_ITEM_MODAL:
    return Object.assign({}, state, {
      uiModalType: 'EDIT_CALCULATOR_ITEM',
      uiModalIn: true,
      editingCalculatorItemId: action.id
    })

  case ActionTypes.OPEN_EDIT_CALCULATOR_ITEMS_MODAL:
    return Object.assign({}, state, {
      uiModalType: 'EDIT_CALCULATOR_ITEMS',
      uiModalIn: true,
      editingCalculatorItemIds: action.ids
    })

  case ActionTypes.OPEN_CONFIRM_DELETE_ITEM_MODAL:
    return Object.assign({}, state, {
      uiModalType: 'CONFIRM_DELETE_ITEM',
      uiModalIn: true,
      editingCalculatorItemId: action.id
    })

  case ActionTypes.OPEN_CONFIRM_DELETE_SELECTED_MODAL:
    return Object.assign({}, state, {
      uiModalType: 'CONFIRM_DELETE_ITEMS',
      uiModalIn: true,
      deletingCalculatorItemIds: action.ids
    })

  case ActionTypes.CLOSE_MODAL:
    return Object.assign({}, state, {
      editingCalculatorItemId: null,
      deletingCalculatorItemIds: null,
      uiModalType: false,
      uiModalIn: false
    })

  // payment item actions

  case ActionTypes.ADD_PAYMENTS_DUE:
    itemsDue = state.itemsDue.concat(itemsFromGroup(
      action.paymentGroupDue,
      state.calculatorItemsNextId,
      state.currentDateISO
    ))
    itemsInterest = generateInterestItems(
      itemsDue.concat(state.itemsPaid),
      state.interestRate,
      state.currentDateISO,
      { compoundingModel }
    )
    calculatorItemsNextId = itemsDue
      .concat(state.itemsPaid)
      .reduce((accumulator, curItem) => {
      return Math.max(accumulator, curItem.id + 1)
    }, 1)
    return Object.assign({}, state, {
      itemsDue,
      itemsInterest,
      calculatorItems: itemsDue.concat(state.itemsPaid, itemsInterest),
      calculatorItemsNextId,
      uiModalType: 'CONFIRM_ACTION',
      uiModalIn: true,
      uiLastAction: 'ADD_PAYMENTS_DUE'
    })

  case ActionTypes.ADD_PAYMENTS_MADE:
    itemsPaid = state.itemsPaid.concat(itemsFromGroup(
      action.paymentGroupMade,
      state.calculatorItemsNextId,
      state.currentDateISO
    ))
    itemsInterest = generateInterestItems(
      itemsPaid.concat(state.itemsDue),
      state.interestRate,
      state.currentDateISO,
      { compoundingModel }
    )
    calculatorItemsNextId = itemsPaid
      .concat(state.itemsDue)
      .reduce((accumulator, curItem) => {
      return Math.max(accumulator, curItem.id + 1)
    }, 1)
    return Object.assign({}, state, {
      itemsPaid,
      itemsInterest,
      calculatorItems: itemsPaid.concat(state.itemsDue, itemsInterest),
      calculatorItemsNextId,
      uiModalType: 'CONFIRM_ACTION',
      uiModalIn: true,
      uiLastAction: action.type
    })

  case ActionTypes.EDIT_CALCULATOR_ITEM:
    itemsDue = state.itemsDue
    itemsPaid = state.itemsPaid
    switch (action.item.type) {

      case 'PAYMENT_DUE':
        itemIndex = itemsDue.findIndex(curItem => curItem.id === action.item.id)
        itemsDue[itemIndex] = Object.assign({}, itemsDue[itemIndex], action.item)
        break

      case 'PAYMENT_MADE':
      default:
        itemIndex = itemsPaid.findIndex(curItem => curItem.id === action.item.id)
        itemsPaid[itemIndex] = Object.assign({}, itemsPaid[itemIndex], action.item)
        break

    }
    itemsInterest = generateInterestItems(
      itemsPaid.concat(state.itemsDue),
      state.interestRate,
      state.currentDateISO,
      { compoundingModel }
    )
    return Object.assign({}, state, {
      itemsDue: itemsDue,
      itemsPaid: itemsPaid,
      itemsInterest: itemsInterest,
      calculatorItems: itemsPaid.concat(state.itemsDue, itemsInterest),
      uiModalType: 'CONFIRM_ACTION',
      uiModalIn: true,
      uiLastAction: action.type
    })

  case ActionTypes.DELETE_CALCULATOR_ITEM:
    itemsDue = state.itemsDue
    itemsPaid = state.itemsPaid
    switch(action.item.type) {

      case 'PAYMENT_DUE':
        itemIndex = itemsDue.findIndex(curItem => curItem.id === action.item.id)
        itemsDue.splice(itemIndex, 1)
        break

      case 'PAYMENT_MADE':
      default:
        itemIndex = itemsPaid.findIndex(curItem => curItem.id === action.item.id)
        itemsPaid.splice(itemIndex, 1)
        break
    }
    itemsInterest = generateInterestItems(
      itemsPaid.concat(state.itemsDue),
      state.interestRate,
      state.currentDateISO,
      { compoundingModel }
    )
    return Object.assign({}, state, {
      itemsDue: itemsDue,
      itemsPaid: itemsPaid,
      itemsInterest: itemsInterest,
      calculatorItems: itemsPaid.concat(state.itemsDue, itemsInterest),
      uiModalType: 'CONFIRM_ACTION',
      uiModalIn: true,
      uiLastAction: action.type
    })

  case ActionTypes.EDIT_CALCULATOR_ITEMS_AMOUNTS:
    const { editingCalculatorItemIds } = state
    const { amount } = action
    itemsDue = state.itemsDue.map(item => {
      return (-1 === editingCalculatorItemIds.indexOf(item.id))
        ? item : Object.assign({}, item, { amount })
    })
    itemsPaid = state.itemsPaid.map(item => {
      return (-1 === editingCalculatorItemIds.indexOf(item.id))
        ? item : Object.assign({}, item, { amount })
    })
    itemsInterest = generateInterestItems(
      itemsPaid.concat(itemsDue),
      state.interestRate,
      state.currentDateISO,
      { compoundingModel }
    )
    return Object.assign({}, state, {
      itemsDue,
      itemsPaid,
      itemsInterest,
      calculatorItems: itemsPaid.concat(itemsDue, itemsInterest),
      editingCalculatorItemIds: null,
      uiLastAction: ActionTypes.EDIT_CALCULATOR_ITEMS_AMOUNTS,
      uiModalType: 'CONFIRM_ACTION',
      uiModalIn: true
    })

  case ActionTypes.DELETE_CALCULATOR_ITEMS:
    itemsDue = state.itemsDue.filter(item => -1 === state.deletingCalculatorItemIds.indexOf(item.id))
    itemsPaid = state.itemsPaid.filter(item => -1 === state.deletingCalculatorItemIds.indexOf(item.id))
    itemsInterest = generateInterestItems(
      itemsPaid.concat(itemsDue),
      state.interestRate,
      state.currentDateISO,
      { compoundingModel }
    )
    return Object.assign({}, state, {
      itemsDue: itemsDue,
      itemsPaid: itemsPaid,
      itemsInterest: itemsInterest,
      deletingCalculatorItemIds: null,
      calculatorItems: itemsPaid.concat(itemsDue, itemsInterest),
      uiModalType: 'CONFIRM_ACTION',
      uiModalIn: true,
      uiLastAction: action.type
    })

  case ActionTypes.UPDATE_CURRENT_DATE_ISO:
    return Object.assign({}, state, {
      currentDateISO: action.currentDateISO
    })

  case ActionTypes.CONFIGURE_INTEREST_CALCULATOR:
    switch (action.settings.type) {
      case InterestCalculatorTypes.SPOUSAL_SUPPORT:
        return Object.assign({}, state, {
          supportType: action.settings.type,
          interestRate: date => 0.08,
          compoundingModel: CompoundingModels.ANNUALLY
        })
        default: // CHILD_SUPPORT
        return Object.assign({}, state, {
          // .12 compounded monthly prior to July 1, 2021 & .1 compounded annualy as of July 1, 2021
          supportType: action.settings.type,
          interestRate: date => dynamicChildSupportInterest(date).interestRate,
          compoundingModel: CompoundingModels.DYNAMIC_CHILD_SUPPORT_INTEREST,
          contentInterestRateInfoOverride: action.settings.contentInterestRateInfoOverride
        })
    }

  default:
    return state

  }
}

export default interestCalculculatorApp
