import { useReducer, useEffect } from 'react'
import { simpleReducerVerbose, simpleReducer } from '../helpers/simpleReducer'
import { fetchWithAuth } from './useAuth'

const debuggingReducer = false
const reducer = debuggingReducer ? simpleReducerVerbose : simpleReducer
const initialState = {
  loading: false,
  status: 'initialize',
  caseProfile: {},
  caseDocData: {},
  modalHeader: null,
  modalMessage: null,
  error: null
}

const useCaseDoc = (caseId, caseDocId) => {
  const docUrl = `/api/v1/my/cases/${caseId}/child-support-calculation/${caseDocId}`
  const [state, dispatch] = useReducer(reducer, initialState)
  const { loading, status, caseDocData, error } = state
  const dispatchFetchError = error => dispatch({ payload: {
    loading: false,
    status: 'error',
    modalHeader: 'Oh no!',
    modalMessage: 'There was an error and our systems were unable to complete your action.',
    error: error
  }})
  useEffect(() => {
    if ('initialize' === status) {
      dispatch({ type: 'loading', payload: { loading: true, status: 'initializing' }})
      fetchWithAuth(docUrl)
        .then(res => {
          const {
            caseProfile: newCaseProfile = {},
            caseDoc: newCaseDocData = {}
          } = res
          dispatch({ payload: {
            loading: false,
            status: 'loaded',
            caseProfile: newCaseProfile,
            caseDocData: newCaseDocData
          }})
        })
        .catch(dispatchFetchError)
    }
  }, [status])
  const updateCaseDoc = updatedCaseDoc => {
    dispatch({ type: 'loading', payload: { loading: true, status: 'updating' }} )
    const options = {
      method: 'PATCH',
      body: JSON.stringify(updatedCaseDoc)
    }
    fetchWithAuth(docUrl, options)
      .then(() => {
        dispatch({
          type: 'updated',
          payload: {
            loading: false,
            status: 'loaded',
            modalHeader: 'Success!',
            modalMessage: 'Your updates have been saved successfully.'
          }
        })
      })
      .catch(dispatchFetchError)
  }
  const dismiss = () => dispatch({ payload: {
    status: 'loaded',
    modalHeader: null,
    modalMessage: null,
    error: null
  }})
  const actions = { updateCaseDoc, dismiss }
  return Object.assign({}, state, { actions })
}

export default useCaseDoc
