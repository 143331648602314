import * as moment from 'moment'
/**
 * presentation helpers used to format data in component render functions
 */
export function presentMoment(m, format = 'MMM D, YYYY') {
  return m.format(format)
}

export const dateInputFormat = 'MM/DD/YYYY'

export function presentMomentAsDateInputValue(m) {
  return moment.isMoment(m) ? m.format('YYYY-MM-DD') : ''
}

export function presentDollarAmount(amount, significantDigits = 2) {
  return Number(amount).toLocaleString(
    navigator.language,
    {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: significantDigits,
      maximumFractionDigits: significantDigits
    }
  )
}

export function presentDecimalAsPercentage(decimal, significantDigits = 0) {
  return (false === decimal) ? false : `${(decimal * 100).toFixed(significantDigits)}%`
}

const withSpacing = input => input.replace( /([A-Z])/g, " $1" )
const capitalized = input => (input.split(' ').map(word => word.charAt(0).toUpperCase() + (word.length > 1 ? word.slice(1) : '')).join(' '))
export const presentCamelCaseAsStartCase = input => capitalized(withSpacing(input))

export const presentCaseType = key => {
  switch (key?.toUpperCase()) {
    case 'IN-RE-THE-MARRIAGE-OF':
      return 'In re the Marriage of'
    case 'IN-RE-THE-PARENTAL-RESPONSIBILITIES-CONCERNING':
      return 'In re the Parental Responsibilities Concerning'
    case 'IN-THE-CIVIL-UNION-OF':
      return 'In the Civil Union of'
    default:
      return key
  }
}
