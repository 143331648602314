import React from 'react'
import styled from 'styled-components'

const FormGrid2 = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 25px;
`
FormGrid2.displayName = 'FromGrid'

export default FormGrid2