import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  cursor: pointer;
  color: ${props => props.theme.colors.gunmetal};
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 15px;
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(to right, ${props => props.theme.colors.sun}, ${props => props.theme.colors.darkPink}, ${props => props.theme.colors.hanPurple});
  border-image-slice: 1;
  background-color: #fff;
  &:disabled {
    cursor: default;
    color: #838F98;
    background-color: #F6F7F8;
    border-color: #D6D9DD;
    border-image: none;
  }
`

const AppButton = props => <Button {...props}>{props.children}</Button>
export const DarkAppButton = styled(AppButton)`
  color: white;
  border: none;
  background-image: linear-gradient(to right, #EE7A30, #EA368A, #7A2CF5);
`
export const PromoCodeAppButton = styled(AppButton)`
  margin-bottom: 10px;
  margin-left: 15px;
  font-size: 80%;
  height: 65px;
  width: 20%;
`

export const CloseButton = styled(AppButton)`
  position: absolute;
  display: inline-block;
  font-size: 20px;
  top: 50px;
  right: 25px;
  padding: 10px 15px;
  background-color: white;
`
export default AppButton
