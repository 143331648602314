import React from 'react'
import './Grid3Columns.css'

function Grid3Columns (props) {
  return (
    <div className="Grid3Columns">{props.children}</div>
  )
}

export default Grid3Columns
