// brand color palette
export const orange = '#EE7A30'
export const pink = '#EA368A'
export const purple = '#7A2CF5'
// numbers after grey reprecent percentages
export const grey5 = '#F6F7F8'
export const grey10 = '#EEF0F1'
export const grey25 = '#D6D9DD'
export const grey50 = '#ADB4B9'
export const grey75 = '#838F98'
export const grey100 = '#5A6A76'
export const darkGrey = '#30393D'

// additional colors
export const red = '#D87B7B'
export const darkGreyHighContrast = '#484E59'

