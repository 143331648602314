// updates state with action payload key/value pairs
export const simpleReducer = (state, action) => {
  const { payload } = action
  return Object.assign({}, state, payload)
}

export const simpleReducerVerbose = (state, action) => {
  console.log(' - reducer - prev state -', state)
  console.log(' - reducer - action -', action)
  const { payload } = action
  const newState = Object.assign({}, state, payload)
  console.log(' - reducer - new state -', newState)
  return newState
}


export default simpleReducer
