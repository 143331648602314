import React from 'react'
import { DarkAppButton } from '../../components/buttons/AppButton'

const ConfirmationButton = (stripe) => {
    return (
      <div>
        <DarkAppButton disabled={!stripe} type="submit" className="ConfirmationButton">Confirm Details</DarkAppButton>
        <br />
      </div>
    )
  }

  export default ConfirmationButton