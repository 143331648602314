import React from 'react'
import styled from 'styled-components'

const Stripe = props => (
  <StyledText {...props} type="text" />
)
const StyledText = styled.div`
  ${props => props.theme.snippets.stripeInputs}
`

Stripe.displayName = 'Stripe'

export default Stripe