import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderOpen, faAngleRight, faUser } from '@fortawesome/free-solid-svg-icons'
import { grey100, darkGrey, purple, orange, pink } from '../../constants/colors'
import * as Timings from '../../constants/timings'
import { casePath } from '../../helpers/paths'
import logoMarkWhite from '../../assets/logo-mark-white.png'

const logoMarkAspectRatio = 243 / 475
const logoHeight = 50
const logoWidth = logoMarkAspectRatio * logoHeight
const Wrapper = styled.div`
  position: relative;
  height: 90px;
`
const FixedWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100vw - 80px);
  background-color: ${darkGrey};
  background-image: linear-gradient(to right, ${orange}, ${pink}, ${purple});
  padding: 20px 40px;
  z-index: 100;
`
const LogoLink = styled(Link)`
  display: inline-block;
  text-indent: -9999px;
  vertical-align: top;
  width: ${logoWidth}px;
  height: ${logoHeight}px;
  background: url(${logoMarkWhite});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 25px;
`
const HeaderLink = styled(Link)`
  display: inline-block;
  color: white;
  text-decoration: none;
  transition: ${Timings.med}ms background-color;
  &:hover,
  &:active,
  &:focus {
    color: white;
    text-decoration: underline;
    background-color: ${grey100};
  }
  p,
  h2 {
    display: inline-block;
    line-height: ${logoHeight}px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 100;
    font-size: 25px;
    margin: 0 12px 0;
  }
`
const CaseTitle = styled.h2`
  display: inline-block;
  font-size: 25px;
  line-height: ${logoHeight}px;
  color: white;
  margin-left: 12px;
`
const IconWithMargin = styled(FontAwesomeIcon)`
  margin-right: 10px;
`
const AccountLink = styled(HeaderLink)`
  position: absolute;
  right: 40px;
`
const CaseHeader = props => (
  <Wrapper>
    <FixedWrapper>
      <LogoLink to='/'>
        <h1>Legal Thunder</h1>
      </LogoLink>
      {props.case && (
        <HeaderLink to={casePath(props.case.id)}>
          <h2><IconWithMargin icon={faFolderOpen} /> {props.case.title}</h2>
        </HeaderLink>
      )}
      {props.doc && (
        <CaseTitle>
          <IconWithMargin icon={faAngleRight} /> {props.doc.title}
        </CaseTitle>
      )}
      <AccountLink to='/account'>
        <p><IconWithMargin icon={faUser} /> Account</p>
      </AccountLink>
    </FixedWrapper>
  </Wrapper>
)

export default CaseHeader
