import React from 'react'
import styled from 'styled-components'

const Password = props => (
  <StyledPassword {...props} type="password" />
)
const StyledPassword = styled.input`
  ${props => props.theme.snippets.inputs}
`

Password.displayName = 'Password'

export default Password