import theme from './index'
export const CARD_NUMBER_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: theme.colors.tuna,
        fontFamily: "'Montserrat', sans-serif",
        fontSize: "12px",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: theme.colors.tuna,
        },
        
      },
      invalid: {
        color: theme.colors.tuna,
        iconColor: theme.colors.tuna,
      },
    },
    placeholder: "Enter Credit Card Number..."
  }
  
  export const CARD_EXPIRY_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: theme.colors.tuna,
        fontFamily: "'Montserrat', sans-serif",
        fontSize: "12px",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: theme.colors.tuna,
        },
        
      },
      invalid: {
        color: theme.colors.tuna,
        iconColor: theme.colors.tuna,
      },
    },
    placeholder: "Enter Credit Card Expiration Date MM/YY..."
  }
  
  export const CARD_CVC_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: theme.colors.tuna,
        fontFamily: "'Montserrat', sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "12px",
        "::placeholder": {
          color: theme.colors.tuna,
        },
        
      },
      invalid: {
        color: theme.colors.tuna,
        iconColor: theme.colors.tuna,
      },
    },
    placeholder: "Enter Credit Card Security Code..."
  }
