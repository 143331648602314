import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Actions from '../../store/actions'
import { default as Content } from '../../store/pages/free-child-support-interest-calculator'
import Header from '../../components/Header'
import ContentWithSidebar from '../../components/layouts/ContentWithSidebar'
import ContentBlock from '../../components/ContentBlock'
import ArticlePreview from '../../components/ArticlePreview'
import InterestCalculatorComponent from './InterestCalculatorComponent'
import { CHILD_SUPPORT } from '../../constants/InterestCalculatorTypes'

export const mapDispatchToProps = dispatch => {
  const { configureInterestCalculator } = Actions
  let dispatchProps = {
    actions: bindActionCreators(
      { configureInterestCalculator },
      dispatch
    ),
    dispatch: {'cta-buttons': []},
    introDispatch: {'cta-buttons': []}
  }
  Content['content-block']['cta-buttons'].forEach( (ctaButton, ind) => {
    if (ctaButton['actionName']  && Actions[ctaButton['actionName']]) {
      dispatchProps.dispatch['cta-buttons'][ind] = {
        'callback': () => dispatch(Actions[ctaButton['actionName']](ctaButton['payload']))
      }
    }
  })
  Content['intro']['content-block']['cta-buttons'].forEach( (ctaButton, ind) => {
    if (ctaButton['actionName']  && Actions[ctaButton['actionName']]) {
      dispatchProps.introDispatch['cta-buttons'][ind] = {
        'callback': () => dispatch(Actions[ctaButton['actionName']](ctaButton['payload']))
      }
    }
  })
  return dispatchProps
}

class PageChildSupport extends Component {
  constructor (props) {
    super(props)
    const { actions } = props
    const { configureInterestCalculator } = actions
    const settings = {
      type: CHILD_SUPPORT,
      contentInterestRateInfoOverride: <p><em>Interest due</em> items are calculated at a 10% interest rate compounded annually for all child support arrears due and owing from July 1, 2021, to the present, and at a 12% interest rate compounded monthly for all child support arrears due and owing prior to July 1, 2021.</p>

    }
    configureInterestCalculator(settings)
  }

  render () {
    const { dispatch, introDispatch } = this.props
    const intro = Content['intro']['content-block']
    intro['dispatch'] = introDispatch
    return (
      <div>
        <Header />
        <ContentWithSidebar has-background={true}>
          <ContentBlock 
            title={Content['content-block']['title']}
            body={Content['content-block']['body']}
            cta-buttons={Content['content-block']['cta-buttons']}
            dispatch={dispatch}
          />
          <ArticlePreview
            title={Content['article-preview']['title']}
            category={Content['article-preview']['category']}
            excerpt={Content['article-preview']['excerpt']}
            url={Content['article-preview']['url']}
            image={Content['article-preview']['image']} />
        </ContentWithSidebar>
        <InterestCalculatorComponent
          intro={intro}
          dispatch={introDispatch}
        />
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(PageChildSupport)
