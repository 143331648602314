import styled from 'styled-components'

export const Input = styled.div`
  marginBottom: 20px;
  label {
    display: inline-block;
    margin-bottom: 10px;
  }
  input[type="text"],
  input[type="email"],
  input[type="date"],
  input[type="password"],
  input[type="number"],
  select,
  textarea {
    display: block;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    border: 1px solid #EEF0F1;
    margin: 0 0 18px;
    padding: 15px;
  }
  textarea {
    border: 1px solid #EEF0F1;
  }
`

export const RadioLabel = styled.label`
  position: relative;
  user-select: none;
  cursor: pointer;
  padding: 5px 10px 5px 35px;
  margin: 0;
  && {
    display: block;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  &:hover .app-checkmark {
    background-color: #ADB4B9;
  }
  input:checked ~ .app-checkmark {
    background-color: #484E59;
    &:after {
      content: " ";
      display: block;
      position: absolute;
      left: 8.5px;
      top: 8.5px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: white;
    }
  }
`
export const RadioCheckmark = styled.span`
  content: " ";
  position: absolute;
  top: 5px;
  left: 0px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #EEF0F1;
  transition: background-color 300ms;
`
