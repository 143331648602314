import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import logo from '../../assets/logo-placeholder.png'
//import MainMenu from '../MainMenu'
import AccountMenu from '../../components/AccountMenu'
import { useSession } from '../../App'

const Header = props => {
  const { status } = useSession()
  const loggedIn = 'loggedIn' === status
  return (
    <Wrapper version={props.version}>
      <Inner>
        {'center' === props.version && (
          <div>
            <Title>Legal </Title>
            <Logo src={logo} alt="Legal Thunder Logo" />
            <Title> Thunder</Title>
          </div>
        )}
        {'center' !== props.version && (
          <div>
            <BrandLink to="/">
              <Logo src={logo} alt="Legal Thunder Logo" version={props.version} />
              <Title>Legal Thunder</Title>
            </BrandLink>
              {/*'minimal' !== props.version
              && loggedIn
                && <MainMenu />*/}
            {loggedIn && <AccountMenu />}
          </div>
        )}
      </Inner>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  background-color: ${props => props.theme.colors.white};
  text-align: ${props => 'center' === props.version ? 'center' : 'left'};
  padding: 35px 40px;
`
const Inner = styled.div`
  position: relative;
`
const BrandLink = styled(Link)`
  &,
  &:hover,
  &:active,
  &:visited {
    color: ${props => props.theme.colors.blueBayoux};
  }
`
const Logo = styled.img`
  display: inline-block;
  width: 50px;
  height: 67px;
  vertical-align: top;
  margin-right: 20px;
  margin-left: ${props => 'center' === props.version ? '20px' : '0'};
`
const Title = styled.h1`
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-size: 40px;
  line-height: 67px;
  letter-spacing: 6px;
  text-transform: uppercase;
  margin: 0;
`

export default Header
