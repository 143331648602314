import React, { Component } from 'react'
import { connect } from 'react-redux'
import { editCalculatorItemsAmounts } from '../../store/actions'
import ModalWrapper from '../../components/modals/ModalWrapper'
import Grid2Columns from '../../components/layouts/Grid2Columns'
import { DarkAppButton } from '../../components/buttons/AppButton'
import { errorMessagesArrayToObject } from '../../helpers/reducers'

const mapDispatchToProps = dispatch => {
  return {
    editCalculatorItemsAmounts: amount => {
      dispatch(editCalculatorItemsAmounts(amount))
    }
  }
}

let validate = state => {
  const errors = []
  if ('undefined' === typeof state.amount || '' === state.amount) {
    errors.push(['amount', 'Required'])
  } else {
    if (0 >= state.amount) errors.push(['amount', 'Must be greater than $0'])
  }
  return errors
}

class CalculatorItemsFormComponent extends Component {

  constructor (props ) {
    super(props)
    this.state = this.initialState()
  }

  initialState () {
    return {
      amount: 0,
      errors: [],
      showErrors: false,
      form: {
        title: 'Edit Items'
      }
    }
  }

  validateForm () {
    const errors = validate(this.state)
    this.setState({ errors })
    return errors
  }

  handleInputChange = e => {
    const target = e.target
    const rawValue = target.value
    const { type, name } = target
    const newState = {}
    let value
    if ('number' === type) {
      value = '' === rawValue ? rawValue : Number(rawValue)
      newState[name] = value
    }
    if (this.state.showErrors) newState['errors'] = validate(newState)
    this.setState(newState)
  }

  handleFormSubmit = e => {
    e.preventDefault()
    const errors = this.validateForm()
    const { amount } = this.state
    const { editCalculatorItemsAmounts } = this.props
    if (errors.length) this.setState({showErrors: true})
    else editCalculatorItemsAmounts(amount)
  }

  render () {
    const showErrors = this.state.showErrors
    const errorMessages = errorMessagesArrayToObject(this.state.errors)
    return (
      <ModalWrapper {...this.props}>
        <form className="CalculatorItemForm" onSubmit={this.handleFormSubmit}>
          <h3 className="AppFormTitle">{this.state.form.title}</h3>
          <Grid2Columns>
            <div>
              <div className={`AppInput${ showErrors && errorMessages['amount'] ? ' has-error' : ''}`}>
                <label htmlFor="amount">Amount</label>
                <input id="amount" type="number" value={this.state.amount} step=".01" min="0.01" placeholder="Enter amount..." name="amount" onChange={this.handleInputChange} />
                {showErrors && errorMessages['amount'] &&
                  <p className="AppInputError">{errorMessages['amount']}</p>
                }
              </div>
              <div className="AppSubmit">
                <DarkAppButton type="submit">Submit</DarkAppButton>
              </div>
            </div>
          </Grid2Columns>
        </form>
      </ModalWrapper>
    )
  }
}

let CalculatorItemsForm = connect(
  null,
  dispatch => mapDispatchToProps(dispatch)
)(CalculatorItemsFormComponent)
export default CalculatorItemsForm
