import * as CaseDocTypes from '../constants/CaseDocTypes'

const caseDocTypeMap = {
  'child-support': CaseDocTypes.CHILD_SUPPORT_CALCULATION,
  'child-support-interest': CaseDocTypes.CHILD_SUPPORT_INTEREST_CALCULATION,
  'spousal-support': CaseDocTypes.SPOUSAL_MAINTENANCE_CALCULATION,
  'spousal-support-interest': CaseDocTypes.CHILD_SUPPORT_INTEREST_CALCULATION
}
export const caseDocPath = (caseId, caseDocType, caseDocId) => {
  return `/cases/${caseId}/child-support/${caseDocId}`
}

export const casePath = id => `/cases/${id}`

export const caseDocTypeFromPath = caseDocPath => caseDocTypeMap[caseDocPath]
