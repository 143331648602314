import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  display: inline-block;
  color: #484E59;
  text-transform: none;
  letter-spacing: inherit;
  font-size: inherit;
  font-weight: 300;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;

`

const TextButton = props => <Button {...props}>{props.children}</Button>
export default TextButton
