import React from 'react'
import { Route, Switch, Link, useHistory, useRouteMatch } from 'react-router-dom'
import Loader from '../Loader'
import Header from '../Header'
import ContentBlock from '../ContentBlock'
import useAttorneyProfiles from '../../hooks/useAttorneyProfiles'
import NewButton  from '../buttons/NewButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import New from './new'
import Show from './show'
import Edit from './edit'
import Error from '../pages/Error'

const pathHelperFromPath = basePath => id => ({
  index: basePath,
  show: `${basePath}/${id}`,
  edit: `${basePath}/${id}/edit`,
})
const Index = ({
  loading,
  attorneyProfiles,
  history,
  pathHelper
}) => {
  const { path } = useRouteMatch()
  if (loading) return <Loader />
  const count = attorneyProfiles.length
  const newAttorneyProfilePath = `${path}/new`
  return (
    <main>
      <Header />
      <ContentBlock>
        <h2>Attorney Profiles</h2>
        <p>The attorneys listed here will be available as representation options in case profiles. The details provided for an attorney will populate the representation section in the header of PDF exports for a case.</p>
        <NewButton
          title="Click to add a new attorney profile"
          onClick={() => history.push(newAttorneyProfilePath)}>
          <FontAwesomeIcon icon={faPlus} /> New
        </NewButton>
        {0 === count && <p>No attorney profiles have been created. <Link to={newAttorneyProfilePath}>Click here to add a new attorney profile.</Link></p>}
        {0 < count && attorneyProfiles.map((profile, ind) => (
          <p key={ind}><Link to={pathHelper(profile.id).show}>{profile.name}</Link></p>
        ))}
      </ContentBlock>
    </main>
  )
}

const Routes = () => {
  const history = useHistory()
  const { path } = useRouteMatch()
  const pathHelper = pathHelperFromPath(path)
  const { loading, attorneyProfiles, actions, profileFields } = useAttorneyProfiles({history, pathHelper})
  const { create, update } = actions
  return (
    <Switch>
      <Route path={`${path}/new`}>
        <New {...{ loading, create, history }} />
      </Route>
      <Route exact path={`${path}/:id`}>
        <Show {...{ loading, attorneyProfiles, profileFields, pathHelper }} />
      </Route>
      <Route exact path={`${path}/:id/edit`}>
        <Edit {...{ loading, attorneyProfiles, pathHelper, update, history }} />
      </Route>
      <Route exact path={path} component={Index}>
        <Index {...{loading, attorneyProfiles, pathHelper, history }} />
      </Route>
        <Route component={Error} />
    </Switch>
  )
}

export default Routes
