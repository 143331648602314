import React from 'react'
import styled from 'styled-components'

const Label = styled.label`
  display: inline-block;
  margin-bottom: 10px;
`
Label.displayName = 'Label'

export default Label
