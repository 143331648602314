import React, { Component } from 'react'
import { connect } from 'react-redux'
import { deleteCalculatorItem } from '../../store/actions'
import ModalWrapper from '../../components/modals/ModalWrapper'
import Grid2Columns from '../../components/layouts/Grid2Columns'
import { DarkAppButton } from '../../components/buttons/AppButton'
import Inputs from '../../components/inputs'


class ConfirmDeleteItemFormComponent extends Component {
  handleFormSubmit = e => {
    e.preventDefault()
    this.props.dispatch(deleteCalculatorItem(this.props.item))
  }
  render () {
    return (
      <ModalWrapper {...this.props}>
        <form className="ConfirmDeleteItemForm" onSubmit={this.handleFormSubmit}>
          <h3 className="AppFormTitle">Confirm Delete Item</h3>
          <Inputs.Wrapper>
            <p>Are you sure you would like to delete <em>{this.props.item.title}</em> item?</p>
          </Inputs.Wrapper>
          <Grid2Columns>
            <div className="AppSubmit">
              <DarkAppButton type="submit">Delete</DarkAppButton>
            </div>
          </Grid2Columns>
        </form>
      </ModalWrapper>
    )
  }
}

let ConfirmDeleteItemForm = connect()(ConfirmDeleteItemFormComponent)

export default ConfirmDeleteItemForm
