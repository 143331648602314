export const PDFCalculationsTableLayout = (data) => {
  return {
    layout: 'myTableLayout',
    table: {
      headerRows: 1,
      dontBreakRows: true,
      widths: ['*', '*', '*', '*', '*', '*'],
      body: [
        [{
          text: ' ',
          colSpan: 3
        }, {}, {}, {
          text: data.worksheet.party1.label,
          style: {
            bold: true
          }
        }, {
          text: data.worksheet.party2.label,
          style: {
            bold: true
          }
        }, {
          text: 'Combined',
          style: {
            bold: true
          }
        }]
      ]
    }
  };
};