import React from 'react'
import ConfirmationMessages from '../../store/ui/confirmation-messages'
import { DarkAppButton } from '../buttons/AppButton'
import './ConfirmAction.css'
import ModalWrapper from './ModalWrapper'

const ConfirmAction = props => {
  return (
    <ModalWrapper {...props}>
      <div className="ConfirmAction-content">
        <p>{ConfirmationMessages[props.lastAction]}</p>
      </div>
      <div className="AppSubmit">
        <DarkAppButton onClick={props.close}>OK</DarkAppButton>
      </div>
    </ModalWrapper>
  )
}

export default ConfirmAction
