import React, { Component } from 'react'
import './ContentBlock.css'
import CTAButtonGroup from '../layouts/CTAButtonGroup'

class ContentBlock extends Component {
  render () {
    // combine state and dispatch props
    if (this.props.dispatch && this.props.dispatch['cta-buttons']) this.props['cta-buttons'].forEach((button, ind) => {
      if (this.props.dispatch['cta-buttons'][ind] && this.props.dispatch['cta-buttons'][ind]['callback']) {
        this.props['cta-buttons'][ind]['callback'] = this.props.dispatch['cta-buttons'][ind]['callback']
      }
    })
    return (
      <article className={"ContentBlock" + (this.props["version"] ? " ver-" + this.props["version"] : "")}>
        {this.props["title"] &&
          <h2 className="ContentBlock-title">{this.props.title}</h2>
        }
        {this.props["body"] &&
          <div>
            {this.props.body.map((p, ind) => <p key={ind}>{p}</p>)}
          </div>}
        {this.props["cta-buttons"] &&
          <CTAButtonGroup cta-buttons={this.props["cta-buttons"]} />
        }
        {this.props.children}
      </article>
    )
  }
}

export default ContentBlock
