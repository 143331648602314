import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { useSession } from '../../App'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faFile, faTrash } from '@fortawesome/free-solid-svg-icons'
import LinkButton from '../../components/buttons/LinkButton'
import ContentBlock from '../../components/ContentBlock'
import Loader from '../../components/Loader'
import CaseSummary from '../../components/case-summary'
//import CTAButton from '../../components/buttons/CTAButton'
import TextButton from '../../components/buttons/TextButton'
import /*AppButton, */{ DarkAppButton } from '../../components/buttons/AppButton'
import * as Layout from '../../components/layouts/ContentWithSidebarLeft'
import Grid2Columns from '../../components/layouts/Grid2Columns'
import Grid4Columns from '../../components/layouts/Grid4Columns'
import useCase from '../../hooks/useCase'
import useBuffer from '../../hooks/useBuffer'
import Header from '../../components/Header'
import CaseHeader from '../../components/CaseHeader'
import ModalWrapper from '../../components/modals/ModalWrapper'
import Fade from '../../components/transitions/Fade'
import * as CaseDocTypes from '../../constants/CaseDocTypes'
import { purple, grey5, grey10, /*grey25,*/ grey50, darkGreyHighContrast, red } from '../../constants/colors'
import * as Timings from '../../constants/timings'
import { caseDocPath } from '../../helpers/paths'
import { addCaseDocument } from '../../containers/APICalls/APICalls'

export const GridItem = styled.div`
  transition: background-color ${Timings.fast}ms ease-out;
  background-color: ${grey5};
  transition: ${Timings.fast}ms background-color;
  &:hover {
    background-color: ${grey10};
  }
`
export const GridItemInner = styled.div`
  margin: 10px;
  padding: 10px;
`

export const FlexGridItemInner = styled(GridItemInner)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const CasesTitle = styled.h2`
  display: inline-block;
  margin-right: 25px;
  margin-bottom: 30px;
`
export const NewButton = styled(LinkButton)`
  font-size: 16px;
  color: ${darkGreyHighContrast};
  padding: 10px;
  text-decoration: none;
  text-align: left;
  transition: ${Timings.fast}ms background-color,
   ${Timings.fast}ms color;
  &:hover,
  &:active,
  &:focus {
    color: ${purple};
    background-color: ${grey5};
  }
`
export const CaseTitleButton = styled(TextButton)`
  font-size: 18px;
  text-align: left;
  transition: ${Timings.fast}ms color;
  margin-bottom: 10px;
  &:hover,
  &:active,
  &:focus {
    color: ${purple};
  }
`
export const CasePreviewAction = styled(CaseTitleButton)`
  display: block;
  font-size: 14px;
  color: ${grey50};
  &:hover,
  &:active,
  &:focus {
    color: ${red};
  }
`

const Case = props => {
  const { session } = useSession()
  const [redirectPath, setRedirectPath] = useState(false)
  const [deleteCaseDocId, setDeleteCaseDocId] = useState(false)
  const [showAddCaseDoc, setShowAddCaseDoc] = useState(false)
  const [isCaseDeleted, setIsCaseDeleted] = useState(false)
  const { match } = props
  const { params } = match
  const { id } = params

  const {
    loading,
    profile = {},
    addCaseDocument,
    addCaseDoc,
    deleteCaseDoc,
    documents = [],
    error
  } = useCase(id)

  const { admin = {} } = profile
  const { caseName: title = '' } = admin
  const buffering = useBuffer()
  if (error) return (
    <main>
      <Header version='loggedIn' />
      <ContentBlock>
        <h2><strong>Error:</strong> {error.message}</h2>
      </ContentBlock>
    </main>
  )
  const caseHeaderProps = {
    case: { title, id }
  }
  const addNewChildSupportCalculation = () => {
    const type = CaseDocTypes.CHILD_SUPPORT_CALCULATION
    addCaseDocument(
      // title: 'Child Support Calculation',
      // type
      caseHeaderProps.case.id

    ).then(result => {
      const { caseDoc = {}, caseProfile = {} } = result
      const { id: caseDocId } = caseDoc
      setRedirectPath( caseDocPath(id, type, caseDocId))
    })
  }
  const addNewChildSupportInterestCalculation = () => {
    const type = CaseDocTypes.CHILD_SUPPORT_INTEREST_CALCULATION
    addCaseDoc({
      title: 'Child Support Interest Calculation',
      type
    }).then(docRef => setRedirectPath( caseDocPath(id, type, docRef.id)))
  }
  const addNewSpousalMaintenanceCalculation = () => {
    const type = CaseDocTypes.SPOUSAL_MAINTENANCE_CALCULATION
    addCaseDoc({
      title: 'Spousal Maintenance Calculation',
      type
    }).then(docRef => setRedirectPath( caseDocPath(id, type, docRef.id)))
  }
  const addNewSpousalMaintenanceInterestCalculation = () => {
    const type = CaseDocTypes.SPOUSAL_MAINTENANCE_INTEREST_CALCULATION
    addCaseDoc({
      title: 'Spousal Maintenance Interest Calculation',
      type
    }).then(docRef => setRedirectPath( caseDocPath(id, type, docRef.id)))
  }
  return (
    <main>
      {redirectPath && <Redirect push={true} to={{ pathname: redirectPath }} />}
      <CaseHeader {...caseHeaderProps}/>
      {(loading || buffering) && <Loader />}
      <Layout.ColsMain>
        <Layout.MainCol1>
          <Layout.AsideSticky>

            <CaseSummary {...{profile}} />

            <Layout.AsideSection>
              <Layout.AsideSummary>
                <Link to={`/cases/${id}/case-profile`}>Edit Case Profile</Link>
              </Layout.AsideSummary>
            </Layout.AsideSection>

          </Layout.AsideSticky>
        </Layout.MainCol1>
        <Layout.MainCol2>
      <ContentBlock>
        <CasesTitle>Case Documents</CasesTitle>
        <NewButton
          title="Click to add a new case document"
          onClick={() => setShowAddCaseDoc(true)}>
          <FontAwesomeIcon icon={faPlus} /> New
        </NewButton>
        <br />
        {0 === documents.length && (
          <div>
            <p>No case documents found.</p>
          </div>
        )}
        {0 < documents.length && (
          <Grid4Columns>
            {documents.map((caseDoc, ind) => {
              const { title: caseDocTitle = 'Child Support Calculation' } = caseDoc
              return (
              <GridItem key={ind}>
                <GridItemInner>
                  <CaseTitleButton
                    title="Click to view document details"
                    onClick={() => {
                      console.log(' onClick caseDoc' ,caseDoc)
                      setRedirectPath( caseDocPath(id, '',  caseDoc.id))
                    }
                    }>
                    <FontAwesomeIcon icon={faFile} /> {caseDocTitle}
                  </CaseTitleButton>
                  <br />
                  <CasePreviewAction
                    title="Click to delete document"
                    onClick={() => console.log('todo: implement deleting document only')}
                  ><FontAwesomeIcon icon={faTrash} /> Delete</CasePreviewAction>
                </GridItemInner>
              </GridItem>
            )})}
          </Grid4Columns>
        )}
        <Fade in={showAddCaseDoc}>
          <ModalWrapper close={() => setShowAddCaseDoc(false)}>
            <h3 className="AppFormTitle">Create New Case Document</h3>
              <p>
                <NewButton onClick={addNewChildSupportCalculation}>
                  <FontAwesomeIcon icon={faPlus} /> Child Support Calculation
                </NewButton>
              </p>
              <p>
                <NewButton onClick={addNewChildSupportInterestCalculation}>
                  <FontAwesomeIcon icon={faPlus} /> Child Support Interest Calculation
                </NewButton>
              </p>
              <p>
                <NewButton onClick={addNewSpousalMaintenanceCalculation}>
                  <FontAwesomeIcon icon={faPlus} /> Spousal Maintenance Calculation
                </NewButton>
              </p>
              <p>
                <NewButton onClick={addNewSpousalMaintenanceInterestCalculation}>
                  <FontAwesomeIcon icon={faPlus} /> Spousal Maintenance Interest Calculation
                </NewButton>
              </p>
          </ModalWrapper>
        </Fade>
        <Fade in={deleteCaseDocId}>
          <ModalWrapper close={() => setDeleteCaseDocId(false)}>
            <Grid2Columns>
              <form onSubmit={e => {
                e.preventDefault()
                deleteCaseDoc(deleteCaseDocId)
                  .then(() => setDeleteCaseDocId(false))
              }}>
                <h3 className="AppFormTitle">Confirm Delete Case Document?</h3>
                <div className="AppSubmit">
                  <DarkAppButton type="submit">Delete Now</DarkAppButton>
                </div>
              </form>
            </Grid2Columns>
          </ModalWrapper>
        </Fade>
      </ContentBlock>
        </Layout.MainCol2>
      </Layout.ColsMain>
    </main>
  )
}
export default Case
