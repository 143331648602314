import React from 'react'
import styled from 'styled-components'

const Email = props => (
  <StyledEmail {...props} type="text" />
)
const StyledEmail = styled.input`
  ${props => props.theme.snippets.inputs}
`

Email.displayName = 'Email'

export default Email
