import React from 'react'
import LinkButton from './LinkButton'
import styled from 'styled-components'
import * as Timings from '../../constants/timings'

export const NewButton = styled(LinkButton)`
  font-size: 16px;
  color: ${props => props.theme.colors.tuna};
  padding: 10px;
  text-decoration: none;
  text-align: left;
  transition: ${Timings.fast}ms background-color,
   ${Timings.fast}ms color;
  &:hover,
  &:active,
  &:focus {
    color: ${props => props.theme.colors.hanPurple};
    background-color: ${props => props.theme.colors.solitude};
  }
 `
export default NewButton
