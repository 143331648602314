import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import './index.css'
import App from './App'
import { unregister } from './registerServiceWorker'
import { updateCurrentDateISO } from './store/actions'
import interestCalculculatorApp, { initialState } from './store/reducers'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

const stripePromise = loadStripe("pk_test_1AxYrQzPdU6VSrS37haoN0Yn00bAHuv5H1")
const currentDateISO = () => (new Date()).toISOString()
const store = createStore(
  interestCalculculatorApp,
  initialState(currentDateISO()),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
const verbose = false
setInterval(() => store.dispatch(updateCurrentDateISO(currentDateISO())), 60000)
if ('development' === process.env.NODE_ENV && verbose) {
  store.subscribe(() => console.log(store.getState()))
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Provider store={store}>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </Provider>,
  document.getElementById('root')
)

unregister()
