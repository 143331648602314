import React from 'react'
import styled from 'styled-components'

function InvoiceColumns (props) {
  return (
    <InvoiceColumn>
      <p>{props.children}</p>
    </InvoiceColumn>
  )
}

export default InvoiceColumns


const InvoiceColumn = styled.div`
  color: #30393D;
  text-transform: uppercase;
  -webkit-letter-spacing: 2px;
  -moz-letter-spacing: 2px;
  -ms-letter-spacing: 2px;
  letter-spacing: 2px;
  padding: 15px;
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(to right,#EE7A30,#EA368A,#7A2CF5);
  border-image-slice: 1;
  background-color: #fff;
  p {
    margin-bottom: 0;
  }
`
