import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as moment from 'moment'
import { editCalculatorItem } from '../../store/actions'
import ModalWrapper from '../../components/modals/ModalWrapper'
import { errorMessagesArrayToObject } from '../../helpers/reducers'
import { presentMomentAsDateInputValue, dateInputFormat } from '../../helpers/presenters'
import { supportsDateInput } from '../../helpers/browser-support'
import { DarkAppButton } from '../../components/buttons/AppButton'

let validate = item => {
  const errors = []
  if ('undefined' === typeof item.amount || '' === item.amount) {
    errors.push(['amount', 'Required'])
  } else {
    if (0 >= item.amount) errors.push(['amount', 'Must be greater than $0'])
  }
  if (!item.date || !moment.isMoment(item.date)) errors.push(['date', 'Must be a valid date'])
  return errors
}

class CalculatorItemFormComponent extends Component {
  constructor (props) {
    super(props)
    this.state = this.initialState()
  }

  initialState () {
    const { item } = this.props
    return {
      id: item.id,
      type: item.type,
      title: item.title,
      amount: item.amount,
      date: item.date,
      dateTextInput: item.date.format(dateInputFormat),
      comments: item.comments,
      errors: [],
      showErrors: false,
      // dynamic text in form
      form: {
        title: 'PAYMENT_DUE' === item.type ? 'Edit Payment Due' : 'Edit Payment Made',
        amountType: 'PAYMENT_DUE' === item.type ? 'due' : 'paid'
      }
    }
  }

  dateInputValue = () => presentMomentAsDateInputValue(this.state.date)

  handleInputChange = e => {
    const target = e.target
    const name = target.name
    const type = target.type
    const rawValue = target.value
    const newState = {}
    const item = this.itemFromState()
    let value
    if ('number' === type) {
      value = '' === rawValue ? rawValue : Number(rawValue)
      newState[name] = item[name] = value
    } else if ('date' === type) {
      value = rawValue ?  moment(rawValue) : null
      newState[name] = item[name] = value
    } else if ('dateTextInput' === name) {
      const parsedRawValue = moment(rawValue, dateInputFormat, true)
      value = parsedRawValue.isValid() ? parsedRawValue : null
      newState[name] = rawValue
      newState['date'] = item['date'] = value
    } else {
      value = rawValue
      newState[name] = item[name] = value
    }
    if (this.state.showErrors) newState['errors'] = validate(item)
    this.setState(newState)
  }

  itemFromState () {
    return {
      id: this.state.id,
      type: this.state.type,
      title: this.state.title,
      amount: this.state.amount,
      date: this.state.date,
      comments: this.state.comments
    }
  }

  validateForm () {
    const errors = validate(this.itemFromState())
    this.setState({ errors })
    return errors
  }

  handleFormSubmit = e => {
    e.preventDefault()
    const errors = this.validateForm()
    if (errors.length) this.setState({showErrors: true})
    else this.props.dispatch(editCalculatorItem(this.itemFromState()))
  }

  render () {
    const showErrors = this.state.showErrors
    const errorMessages = errorMessagesArrayToObject(this.state.errors)
    return (
      <ModalWrapper {...this.props}>
        <form className="CalculatorItemForm" onSubmit={this.handleFormSubmit}>

          <h3 className="AppFormTitle">{this.state.form.title}</h3>

          <div className={`AppInput${ showErrors && errorMessages['amount'] ? ' has-error' : ''}`}>
            <label htmlFor="amount">Amount {this.state.form.amountType}</label>
            <input id="amount" type="number" value={this.state.amount} step=".01" min="0.01" placeholder="Enter amount..." name="amount" onChange={this.handleInputChange} />
            {showErrors && errorMessages['amount'] &&
              <p className="AppInputError">{errorMessages['amount']}</p>
            }
          </div>

          <div className={`AppInput${ showErrors && errorMessages['date'] ? ' has-error' : ''}`}>
            <label htmlFor="date">Date</label>
            {supportsDateInput &&
              <input id="date" type="date" name="date" value={this.dateInputValue()} onChange={this.handleInputChange} />
            }
            {!supportsDateInput &&
              <input id="date" type="text" name="dateTextInput" value={this.state.dateTextInput} onChange={this.handleInputChange} maxLength="10" />
            }
            {showErrors && errorMessages['date'] &&
              <p className="AppInputError">{errorMessages['date']}</p>
            }
          </div>

          <div className={`AppInput${ showErrors && errorMessages['comments'] ? ' has-error' : ''}`}>
            <label htmlFor="comments">Add comments</label>
            <textarea id="comments" name="comments" value={this.state.comments} placeholder="Enter comments..." rows="6" maxLength="500" onChange={this.handleInputChange} />
            {showErrors && errorMessages['comments'] &&
              <p className="AppInputError">{errorMessages['comments']}</p>
            }
          </div>

          <div className="AppSubmit">
            <DarkAppButton type="submit">Submit</DarkAppButton>
          </div>
        </form>
      </ModalWrapper>
    )
  }
}

let CalculatorItemForm = connect()(CalculatorItemFormComponent)

export default CalculatorItemForm
