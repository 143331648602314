import React, { useState, useReducer } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleDown, faTimes } from '@fortawesome/free-solid-svg-icons'
// todo: rename file since calculations are used in both A & B
import {
  scheduleOfBasicSupportObligations,
  legalResponsibilityForChildrenOtherRelationship,
  applyDocumentedPaymentsCap,
  calculateNetWorkRelated
} from './calculations/worksheet-calculations'
import AppButton from '../buttons/AppButton'
import useCaseDoc from '../../hooks/useCaseDoc'
import useBuffer from '../../hooks/useBuffer'
import useAttorneyProfiles from '../../hooks/useAttorneyProfiles'
import Loader from '../Loader'
import CaseHeader from '../CaseHeader'
import OvernightsCalculator from './overnights-calculator'
import Grid4Columns from '../layouts/Grid4Columns'
import Grid2Columns from '../layouts/Grid2Columns'
import { presentDollarAmount, presentDecimalAsPercentage, presentCaseType } from '../../helpers/presenters'
import * as AppUI from '../app'
import * as templates from './templates'
import * as Layout from '../layouts/ContentWithSidebarLeft'
import * as UI from './styled-components'
import { childSupportWorksheetBExample } from '../../lib/pdf/stubs'
import { downloadChildSupportWorksheet, previewChildSupportWorksheet } from '../../lib/pdf/exporters'
import ModalWrapper from '../modals'
import {
  calculateMonthlyAdjustedGrossIncome as calculateMonthlyAdjustedGrossIncomeForSpousalSupport,
  calculateSpousalSupport,
  spousalSupportTemplate
} from '../spousal-support/calculations'
import Label from '../inputs/label'
import SummaryPanel from './summary-panel'
import Parties from './sections/parties'

import { DISPLAY_PARTY_AS_OPTIONS, PARTY_1, PARTY_2 } from '../../constants/case-parties'
import { WORKSHEET_A, WORKSHEET_B } from '../../constants/child-support'

const demoAlert = () => alert('Demo only')

const partyKeys = [PARTY_1, PARTY_2]
const determineWorksheetType = overnights => {
  if ('' === overnights || false === overnights) return false
  return (overnights <= 92) || ((365 - overnights) <= 92) ? WORKSHEET_A : WORKSHEET_B
}
const calculateMonthlyAdjustedGrossIncome = (worksheetUsed, monthlyGross,
  adjustments) => {
  const { maintenancePaid, maintenanceReceived,
    maintenanceReceivedIsTaxable,
    maintenancePaidIsTaxDeductible,
    supportPaidChildrenNOTR,
    numberOfChildrenOtherRelationshipPrimaryLiving,
    documentedPaymentsChildrenOtherRelationship,
    numberOfChildrenOtherRelationshipNotPrimaryLiving
  } = adjustments
  if (false === monthlyGross || !worksheetUsed) {
    return {
      monthlyGross,
      otherRelationshipResponsibility: false,
      adjustedMaintenanceReceived: false,
      adjustedMaintenancePaid: false,
      cappedDocumentedPaymentsChildrenOtherRelationship: false
    }
  } else {
    const adjustedMaintenanceReceived = (
        maintenanceReceived && maintenanceReceivedIsTaxable
      ) ? maintenanceReceived : 1.25 * maintenanceReceived
    const adjustedMaintenancePaid = (
        maintenancePaid && maintenancePaidIsTaxDeductible
      ) ? maintenancePaid : 1.25 * maintenancePaid
    const otherRelationshipResponsibility = (numberOfChildrenOtherRelationshipPrimaryLiving ?
        legalResponsibilityForChildrenOtherRelationship(
          monthlyGross,
          adjustedMaintenanceReceived,
          adjustedMaintenancePaid,
          numberOfChildrenOtherRelationshipPrimaryLiving
        ) : 0)
    const cappedDocumentedPaymentsChildrenOtherRelationship = (
      documentedPaymentsChildrenOtherRelationship ?
        applyDocumentedPaymentsCap(
          monthlyGross,
          numberOfChildrenOtherRelationshipNotPrimaryLiving,
          documentedPaymentsChildrenOtherRelationship
        ) : 0)
    return {
      monthlyGross: monthlyGross // 1
        + (adjustedMaintenanceReceived ? adjustedMaintenanceReceived : 0) // 1a
        - (adjustedMaintenancePaid ? adjustedMaintenancePaid : 0) // 1b
        - (false !== supportPaidChildrenNOTR ?
          supportPaidChildrenNOTR : 0) // 1c
        - (false !== otherRelationshipResponsibility ?
          otherRelationshipResponsibility : 0) // 1d
        - cappedDocumentedPaymentsChildrenOtherRelationship, // 1e
      otherRelationshipResponsibility,
      adjustedMaintenanceReceived,
      adjustedMaintenancePaid,
      cappedDocumentedPaymentsChildrenOtherRelationship
    }
  }
}
const stateTemplate = {
  title: 'Child Support Calculation',
  type: 'CHILD_SUPPORT_CALCULATION',
  parties: {
    PARTY_1: templates.party,
    PARTY_2: templates.party,
  },
  children: [templates.child],
  numberOfChildrenInput: "1",
  numberOfChildren: 1,
  overnightWidget: 'DIRECT',
  overnightsParty: PARTY_1,
  overnightsInput: '',
  overnights: false,
  overnightsPercentage: false,
  otherPartyOvernights: false,
  otherPartyOvernightsPercentage: false,
  payor: false,
  worksheetUsed: false,
  spousalMaintenanceEntryMethod: '',
  monthlyAdjustedGrossIncomesForSpousalSupport: {
    PARTY_1: false,
    PARTY_2: false
  },
  calculatedSpousalSupport: spousalSupportTemplate,
  directSpousalSupport: {
    payor: false,
    maintenance: false,
    _40PercentOfCombinedAdjustedGrossMonthlyIncome: false,
  },
  spousalSupportOfThisRelationshipIsTaxable: false,
  spousalMaintenanceNOTREntry: false,
  childSupportNOTREntry: false,
  adjustments: {
    PARTY_1: templates.adjustments,
    PARTY_2: templates.adjustments
  },
  monthlyAdjustedGrossIncomes: {
    PARTY_1: false,
    PARTY_2: false
  },
  adjustedMaintenanceReceived: {
    PARTY_1: false,
    PARTY_2: false
  },
  adjustedMaintenancePaid: {
    PARTY_1: false,
    PARTY_2: false
  },
  cappedDocumentedPaymentsChildrenOtherRelationship: {
    PARTY_1: false,
    PARTY_2: false
  },
  combinedAdjustedGrossIncome: false,
  decimalSharesOfIncome: {
    PARTY_1: false,
    PARTY_2: false
  },
  basicSupportObligation: false,
  shareOfBasicSupportObligation: {
    PARTY_1: false,
    PARTY_2: false
  },
  lowIncomeAdjustment: {
    PARTY_1: false,
    PARTY_2: false
  },
  lowIncomeAdjustmentApplies: {
    PARTY_1: false,
    PARTY_2: false
  },
  sharedPhysicalSupportObligation: false,
  supportObligationAdjustments: {
    PARTY_1: templates.supportObligationAdjustments,
    PARTY_2: templates.supportObligationAdjustments
  },
  eachParentsPortionOfSharedPhysicalCareSupportObligation: {
    PARTY_1: false,
    PARTY_2: false
  },
  supportForTime: {
    PARTY_1: false,
    PARTY_2: false
  },
  combinedTotalAdjustments: false,
  fairShareOfAdjustments: {
    PARTY_1: false,
    PARTY_2: false
  },
  excessAdjustmentsPaid: {
    PARTY_1: false,
    PARTY_2: false
  },
  adjustedObligation: {
    PARTY_1: false,
    PARTY_2: false
  },
  shareOfTotalChildSupport: {
    PARTY_1: false,
    PARTY_2: false
  },
  payingParentAdjustment: {
    PARTY_1: false,
    PARTY_2: false
  },
  recommendedChildSupportOrder: {
    PARTY_1: false,
    PARTY_2: false
  },
  comments: '',
  calculationModel: 'CURRENT'
}

const reducer = (state, action) => {
  //console.log(state, action)
  const { party, update, type } = action
  const newState = Object.assign({}, state)
  switch (type) {
    case 'SET_PARTY':
      const { parties: newParties } = state
      newParties[party] = Object.assign(
        {},
        state.parties[party],
        update
      )
      newState.parties = newParties
      break
    case 'SET_NUMBER_OF_CHILDREN':
      newState.numberOfChildrenInput = action.value
      if ('' === action.value) {
        newState.numberOfChildren = 1
      } else {
        newState.numberOfChildren = Math.min(Math.max(1, action.value), 6)
      }
      break
    case 'SET_CHILDREN':
      newState.children = action.children
      break
    case 'SET_OVERNIGHTS_WIDGET':
      newState.overnightWidget = action.widget
      break
    case 'SET_OVERNIGHTS_PARTY':
      newState.overnightsParty = action.party
      break
    case 'SET_OVERNIGHTS_INPUT':
      newState.overnightsInput = action.value
      if ('' === action.value) {
        newState.overnights = false
        newState.overnightsPercentage = false
        newState.otherPartyOvernights = false
        newState.otherPartyOvernightsPercentage = false
        newState.worksheetUsed = false
      } else {
        newState.overnights = Math.min(
          365,
          Math.max(Number(action.value), 0)
        )
        newState.overnightsPercentage = newState.overnights / 365
        newState.otherPartyOvernights = 365 - newState.overnights
        newState.otherPartyOvernightsPercentage = newState.otherPartyOvernights / 365
        newState.worksheetUsed = determineWorksheetType(
          newState.overnights
        )
      }
      break
    case 'SET_SPOUSAL_MAINTENANCE_ENTRY_METHOD':
      newState.spousalMaintenanceEntryMethod = action.entryMethod
      break
    case 'SET_DIRECT_SPOUSAL_SUPPORT':
      newState.directSpousalSupport = Object.assign(
        {},
        state.directSpousalSupport,
        action.update
      )
      break
    case 'SET_SPOUSAL_MAINTENANCE_NOTR_ENTRY':
      newState.spousalMaintenanceNOTREntry = "true" === action.entry
      break
    case 'SET_CHILD_SUPPORT_NOTR_ENTRY':
      newState.childSupportNOTREntry = "true" === action.entry
      break
    case 'SET_ADJUSTMENTS':
      const { adjustments: newAdjustments } = state
      newAdjustments[action.party] = Object.assign(
        {},
        templates.adjustments,
        state.adjustments[action.party],
        action.update
      )
      break
    case 'SET_OBLIGATION_ADJUSTMENTS':
      const { supportObligationAdjustments: newSupportObligationAdjustments }
        = state
      newSupportObligationAdjustments[action.party] = Object.assign(
        {},
        state.supportObligationAdjustments[action.party],
        action.update
      )
      break
    case 'SET_COMMENTS':
      newState.comments = action.comments
      break
    case 'SET_TITLE':
      newState.title = action.title
      break
    case 'SET_CALCULATION_MODEL':
      newState.calculationModel = action.calculationModel
      break
    default:
      throw new Error('Undefined action type dispatched.')
  }

  // do not set payor if overnights are not set or Worksheet B is used
  if (false === newState.overnights || WORKSHEET_B === newState['worksheetUsed']) {
    newState.payor = false
  // set child support payor based on overnights when Worksheet A is used
  } else if (92 >= newState.overnights) {
    newState.payor = newState.overnightsParty
  } else if ((365 - 92) <= newState.overnights) {
    newState.payor = PARTY_1 === newState.overnightsParty ? PARTY_2 : PARTY_1
  }
  // calculate spousal support
  const {
    parties,
    worksheetUsed,
    adjustments,
    spousalMaintenanceEntryMethod,
    monthlyAdjustedGrossIncomesForSpousalSupport
  } = newState
  if (spousalMaintenanceEntryMethod === 'CALCULATOR') {
    // calculate Monthly Adjusted Gross Incomes for SPOUSAL SUPPORT
    partyKeys.forEach(partyKey => {
      const { adjustedMonthlyGross } =
        calculateMonthlyAdjustedGrossIncomeForSpousalSupport(
          parties[partyKey].monthlyGross,
          adjustments[partyKey]
        )
      monthlyAdjustedGrossIncomesForSpousalSupport[partyKey] = adjustedMonthlyGross
    })

    newState.calculatedSpousalSupport = calculateSpousalSupport({
      supportIsTaxDeductible: null, // TODO: Add input and set dynamically here
      adjustedGrossMonthlyIncome1: monthlyAdjustedGrossIncomesForSpousalSupport[PARTY_1],
      adjustedGrossMonthlyIncome2: monthlyAdjustedGrossIncomesForSpousalSupport[PARTY_2],
    })
  } else {
    newState.calculatedSpousalSupport = spousalSupportTemplate
  }
  
  // calculate 2. Monthly Adjusted Gross Incomes
  const { monthlyGross: monthlyAdjusted1,
    otherRelationshipResponsibility: otherRelationshipResponsibility1,
    adjustedMaintenanceReceived: adjustedMaintenanceReceived1,
    adjustedMaintenancePaid: adjustedMaintenancePaid1,
    cappedDocumentedPaymentsChildrenOtherRelationship: cappedDocumentedPaymentsChildrenOtherRelationship1
  } = calculateMonthlyAdjustedGrossIncome(
      worksheetUsed,
      parties[PARTY_1]['monthlyGross'],
      adjustments[PARTY_1]
    )
  newState['monthlyAdjustedGrossIncomes'][PARTY_1] = monthlyAdjusted1
  newState['adjustedMaintenanceReceived'][PARTY_1] = adjustedMaintenanceReceived1
  newState['adjustedMaintenancePaid'][PARTY_1] = adjustedMaintenancePaid1
  newState['cappedDocumentedPaymentsChildrenOtherRelationship'][PARTY_1] = cappedDocumentedPaymentsChildrenOtherRelationship1
  adjustments[PARTY_1]['otherRelationshipResponsibility'] = otherRelationshipResponsibility1
  const { monthlyGross: monthlyAdjusted2,
    otherRelationshipResponsibility: otherRelationshipResponsibility2,
    adjustedMaintenanceReceived: adjustedMaintenanceReceived2,
    adjustedMaintenancePaid: adjustedMaintenancePaid2,
    cappedDocumentedPaymentsChildrenOtherRelationship: cappedDocumentedPaymentsChildrenOtherRelationship2
  } = calculateMonthlyAdjustedGrossIncome(
      worksheetUsed,
      parties[PARTY_2]['monthlyGross'],
      adjustments[PARTY_2]
    )
  newState['monthlyAdjustedGrossIncomes'][PARTY_2] = monthlyAdjusted2
  newState['adjustedMaintenanceReceived'][PARTY_2] = adjustedMaintenanceReceived2
  newState['adjustedMaintenancePaid'][PARTY_2] = adjustedMaintenancePaid2
  newState['cappedDocumentedPaymentsChildrenOtherRelationship'][PARTY_2] = cappedDocumentedPaymentsChildrenOtherRelationship2
  adjustments[PARTY_2]['otherRelationshipResponsibility'] = otherRelationshipResponsibility2
  newState['combinedAdjustedGrossIncome'] = (
    false === newState['monthlyAdjustedGrossIncomes'][PARTY_1]
    || false === newState['monthlyAdjustedGrossIncomes'][PARTY_2] )
  ? false : ( newState['monthlyAdjustedGrossIncomes'][PARTY_1]
    + newState['monthlyAdjustedGrossIncomes'][PARTY_2] )

  // calculate 3. Percentage Share of Income
  partyKeys.forEach(partyKey => {
    newState['decimalSharesOfIncome'][partyKey] = newState['combinedAdjustedGrossIncome'] ?
      newState['monthlyAdjustedGrossIncomes'][partyKey] / newState['combinedAdjustedGrossIncome'] : false
  })

  // calculate 4. Basic Combined Obligation
  newState['basicSupportObligation'] = (false === newState['combinedAdjustedGrossIncome']
    || isNaN(newState['combinedAdjustedGrossIncome']) ) ?
    false : scheduleOfBasicSupportObligations(
      newState['combinedAdjustedGrossIncome'],
      newState['numberOfChildren']
    )
  partyKeys.forEach(partyKey => {
    newState['shareOfBasicSupportObligation'][partyKey] = (
      false !== newState['basicSupportObligation']
      && false !== newState['decimalSharesOfIncome'][partyKey]) ?
        newState['basicSupportObligation']
        * newState['decimalSharesOfIncome'][partyKey]
      : false
  })

  // calculate 5. Low-Income Adjustment (Worksheet A)
  partyKeys.forEach(partyKey => {
    const minimumSupportObligation = 1 === newState['numberOfChildren'] ? 50
        : 2 === newState['numberOfChildren'] ? 70
        : 3 === newState['numberOfChildren'] ? 90
        : 4 === newState['numberOfChildren'] ? 110
        : 5 === newState['numberOfChildren'] ? 130
        : 150
    if (false === newState['combinedAdjustedGrossIncome']
        || partyKey !== newState['payor']) {
      // required data not available to calculate low income adjustment
      newState['lowIncomeAdjustment'][partyKey] = false
      newState['lowIncomeAdjustmentApplies'][partyKey] = false
    } else if (1100 > newState['combinedAdjustedGrossIncome']
      || 1100 > newState['monthlyAdjustedGrossIncomes'][partyKey]) {
      // 1st & 3rd scenarios listed in statue are treated equal and used in
      // (9) Each Parent’s Share of Total Child Support Obligation
      // & (11) Recommended Child Support Order
      newState['lowIncomeAdjustment'][partyKey] = minimumSupportObligation
      newState['lowIncomeAdjustmentApplies'][partyKey] = true
    } else if (1900 > newState['monthlyAdjustedGrossIncomes'][partyKey]) {
      const adjustedMinimumSupportCheck =
        newState['monthlyAdjustedGrossIncomes'][partyKey] - 1100
      const adjustedMinimumSupport = adjustedMinimumSupportCheck > 0
        ? (adjustedMinimumSupportCheck + minimumSupportObligation)
        :  minimumSupportObligation
      newState['lowIncomeAdjustment'][partyKey] = adjustedMinimumSupport
      newState['lowIncomeAdjustmentApplies'][partyKey] =
        false !== newState['lowIncomeAdjustment'][partyKey]
        && newState['monthlyAdjustedGrossIncomes'][partyKey] < newState['lowIncomeAdjustment'][partyKey]
    } else {
      newState['lowIncomeAdjustment'][partyKey] = false
      newState['lowIncomeAdjustmentApplies'][partyKey] = false
    }
  })

  // calculate 5. Shared Physical Support Obligation (Worksheet B)
  newState['sharedPhysicalSupportObligation'] = false !== newState['basicSupportObligation'] ?
    (1.5 * newState['basicSupportObligation']) : false

  // calculate 6. Adjustments (Worksheet A) (& 10. Worksheet B & part of 11. Worksheet B)
  partyKeys.forEach(partyKey => {
    newState['supportObligationAdjustments'][partyKey]['netWorkRelatedChildCare']
      = calculateNetWorkRelated(
        parties[partyKey]['monthlyGross'],
        newState['numberOfChildren'],
        newState['supportObligationAdjustments'][partyKey]['workRelatedChildCare']
    )
    newState['supportObligationAdjustments'][partyKey]['totalAdjustments'] =
      (newState['supportObligationAdjustments'][partyKey]['netWorkRelatedChildCare'] ?
        newState['supportObligationAdjustments'][partyKey]['netWorkRelatedChildCare'] : 0)
    + (newState['supportObligationAdjustments'][partyKey]['educationRelatedChildCare'] ?
        newState['supportObligationAdjustments'][partyKey]['educationRelatedChildCare'] : 0)
    + (newState['supportObligationAdjustments'][partyKey]['healthInsurancePremiums'] ?
        newState['supportObligationAdjustments'][partyKey]['healthInsurancePremiums'] : 0)
    + (newState['supportObligationAdjustments'][partyKey]['extraordinaryMedical'] ?
        newState['supportObligationAdjustments'][partyKey]['extraordinaryMedical'] : 0)
    + (newState['supportObligationAdjustments'][partyKey]['extraordinaryExpenses'] ?
        newState['supportObligationAdjustments'][partyKey]['extraordinaryExpenses'] : 0)
    - (newState['supportObligationAdjustments'][partyKey]['extraordinaryAdjustments'] ?
        newState['supportObligationAdjustments'][partyKey]['extraordinaryAdjustments'] : 0)
  })

  // calculate 6. Shared Physical Care Support Obligation (Worksheet B)
  partyKeys.forEach(partyKey => {
    // Line (3) x Line (5) for each party.
    newState['eachParentsPortionOfSharedPhysicalCareSupportObligation'][partyKey] =
      (false !== newState['decimalSharesOfIncome'][partyKey]
        && false !== newState['sharedPhysicalSupportObligation']) ?
        newState['decimalSharesOfIncome'][partyKey] * newState['sharedPhysicalSupportObligation'] : false
  })

  // calculate 7. Total Adjustments (11. Worksheet B)
  newState['combinedTotalAdjustments'] =
    (false !== newState['supportObligationAdjustments'][PARTY_1]['totalAdjustments']
      && false !== newState['supportObligationAdjustments'][PARTY_2]['totalAdjustments']) ?
    newState['supportObligationAdjustments'][PARTY_1]['totalAdjustments']
      + newState['supportObligationAdjustments'][PARTY_2]['totalAdjustments']
    : false

  // calculate 8. Each Parent's Fair Share of Adjustments (12. Worksheet B)
  partyKeys.forEach(partyKey => {
    newState['fairShareOfAdjustments'][partyKey] = (
      (false === newState['combinedTotalAdjustments'])
      || (false === newState['decimalSharesOfIncome'][PARTY_1])
      || (false === newState['decimalSharesOfIncome'][PARTY_2])) ? false :
      newState['combinedTotalAdjustments'] * newState['decimalSharesOfIncome'][partyKey]
  })

  // calculate 13. Adjustments Paid in Excess of Fair Share (Worksheet B)
  partyKeys.forEach(partyKey => {
    newState['excessAdjustmentsPaid'][partyKey] = (
      false !== newState['supportObligationAdjustments'][partyKey]['totalAdjustments']
       && false !== newState['fairShareOfAdjustments'][partyKey]) ?
      Math.max(newState['supportObligationAdjustments'][partyKey]['totalAdjustments']
        - newState['fairShareOfAdjustments'][partyKey], 0) : false
  })

  // calculate 14. Each Parent's Adjusted Support Obligation (Worksheet B)
  partyKeys.forEach(partyKey => {
    const partyPercentageTime = (partyKey === newState['overnightsParty']) ? newState['overnightsPercentage'] : newState['otherPartyOvernightsPercentage']
    const otherPartyPercentageTime = (partyKey === newState['overnightsParty']) ? newState['otherPartyOvernightsPercentage'] : newState['overnightsPercentage']
    newState['supportForTime'][partyKey] = (false !== newState['eachParentsPortionOfSharedPhysicalCareSupportObligation'][partyKey]
      && false !== partyPercentageTime) ? (
        newState['eachParentsPortionOfSharedPhysicalCareSupportObligation'][partyKey] * otherPartyPercentageTime)
      : false
    newState['adjustedObligation'][partyKey] = (false !== newState['supportForTime'][partyKey]
      && false !== newState['excessAdjustmentsPaid'][partyKey]) ?
      newState['supportForTime'][partyKey] - newState['excessAdjustmentsPaid'][partyKey] : false
  })

  // calculate 9. Each Parent’s Share of Total Child Support Obligation
  partyKeys.forEach(partyKey => {
    newState['shareOfTotalChildSupport'][partyKey] =
      (false !== newState['shareOfBasicSupportObligation'][partyKey]
      && false !== newState['fairShareOfAdjustments'][partyKey])
      ? (newState['lowIncomeAdjustmentApplies'][partyKey]
        ? newState['lowIncomeAdjustment'][partyKey]
        : newState['shareOfBasicSupportObligation'][partyKey])
        + newState['fairShareOfAdjustments'][partyKey]
      : false
  })

  // calculate 10. Paying Parent's Adjustment
  partyKeys.forEach(partyKey => {
    newState['payingParentAdjustment'][partyKey] =
      (partyKey === newState['payor'])
      ? newState['supportObligationAdjustments'][partyKey]['totalAdjustments']
      : false
  })


  // calculate 11. Recommended Child Support Order (Worksheet B 15.)
  partyKeys.forEach(partyKey => {
    switch (newState['worksheetUsed']) {
      case WORKSHEET_A:
        newState['recommendedChildSupportOrder'][partyKey] =
          (partyKey === newState['payor']
            && false !== newState['shareOfTotalChildSupport'][partyKey]['totalAdjustments'])
          ? (
            newState['lowIncomeAdjustmentApplies'][partyKey]
              ? newState['lowIncomeAdjustment'][partyKey]
              : (
                newState['shareOfTotalChildSupport'][partyKey]
                  - (false !== newState['supportObligationAdjustments'][partyKey]['totalAdjustments']
                  ?  newState['supportObligationAdjustments'][partyKey]['totalAdjustments'] : 0)
                )
            )
          : false
        break
      case WORKSHEET_B:
        const otherPartyKey = partyKey === PARTY_1 ? PARTY_2 : PARTY_1
        newState['recommendedChildSupportOrder'][partyKey] =
          (false !== newState['adjustedObligation'][partyKey]
            && false !== newState['adjustedObligation'][otherPartyKey]
            && newState['adjustedObligation'][partyKey] > newState['adjustedObligation'][otherPartyKey]) ?
            newState['adjustedObligation'][partyKey] - newState['adjustedObligation'][otherPartyKey] : false
        if (false !== newState['adjustedObligation'][partyKey]) {
          newState['payor'] = partyKey
        }
        break
    }

  })

  return newState
}

const pdfExportData = (state, party1Label, party2Label, caseProfile, attorneyProfiles) => {
  const {
    parties,
    overnightsParty,
    overnights,
    overnightsPercentage,
    otherPartyOvernights,
    otherPartyOvernightsPercentage,
    worksheetUsed,
    adjustedMaintenanceReceived,
    adjustedMaintenancePaid,
    cappedDocumentedPaymentsChildrenOtherRelationship,
    adjustments,
    monthlyAdjustedGrossIncomes,
    combinedAdjustedGrossIncome,
    decimalSharesOfIncome,
    basicSupportObligation,
    shareOfBasicSupportObligation,
    sharedPhysicalSupportObligation,
    eachParentsPortionOfSharedPhysicalCareSupportObligation,
    lowIncomeAdjustment,
    combinedTotalAdjustments,
    fairShareOfAdjustments,
    excessAdjustmentsPaid,
    adjustedObligation,
    shareOfTotalChildSupport,
    payingParentAdjustment,
    recommendedChildSupportOrder,
    supportObligationAdjustments,
    supportForTime,
    comments
  } = state
  // swap in values from case profile
  const {
    courtCaseInfo = {},
    filingPartyInfo = {},
    proSeAttorneyProfile = {},
    parties: caseProfileParties = {}
  } = caseProfile
  const {
    petitioner = {},
    coPetitionerRespondent = {}
  } = caseProfileParties
  const {
    caseNumber = '',
    additionalCaseNumber = null,
    divisionCourtroom = 'N/A',
    courtName = '',
    caseType = ''
  } = courtCaseInfo
  const {
    partyRole = '',
    representation = ''
  } = filingPartyInfo
  const {
    name: proSeName  = '',
    address1: proSeAddress1 = '',
    address2: proSeAddress2 = '',
    city: proSeCity = '',
    state: proSeState = '',
    zip: proSeZip = '',
    phone: proSePhone = '',
    fax: proSeFax = '',
    email: proSeEmail = '',
  } = proSeAttorneyProfile
  const proSeRepresentation = 'Pro Se' === representation
  const attorneyProfile = !proSeRepresentation && attorneyProfiles.find(ap => representation === ap.id)
  const { worksheetAPdfExport, worksheetBPdfExport } = templates
  const { caseHeader: caseHeaderTemplate } = worksheetAPdfExport
  // reference pdf template at lib/pdf/helpers/pdf-header
  const party1Name = [petitioner.firstName]
  if (petitioner.middleName) party1Name.push(petitioner.middleName)
  party1Name.push(petitioner.lastName)
  const party2Name = [coPetitionerRespondent.firstName]
  if (coPetitionerRespondent.middleName) party2Name.push(coPetitionerRespondent.middleName)
  party2Name.push(petitioner.lastName)
  const caseHeader = Object.assign({}, caseHeaderTemplate, {
    filingPartyDesignation: partyRole,
    caseNumber1: caseNumber,
    caseNumber2: additionalCaseNumber,
    divisionCourtroom: divisionCourtroom,
    courtName: courtName,
    caseType: presentCaseType(caseType),
    party1Name: party1Name.join(' '),
    party2Name: party2Name.join(' '),
    party2Type: coPetitionerRespondent.type
  })
  if (proSeRepresentation) {
    // use pro se details from case profile
    caseHeader.filingPartyName = proSeName
    caseHeader.filingPartyAddress1 = proSeAddress1
    caseHeader.filingPartyAddress2 = proSeAddress2
    caseHeader.cityStateZip = `${proSeCity}${proSeCity && ', '}${proSeState} ${proSeZip}`
    caseHeader.filingPartyPhone = proSePhone
    caseHeader.filingPartyFax = proSeFax
    caseHeader.filingPartyEmail = proSeEmail
  } else if (attorneyProfile) {
    //console.log(' - attorneyProfile -', attorneyProfile)
    // use details from attorney profile selected in case profile
    caseHeader.filingPartyName = attorneyProfile.name
    caseHeader.filingPartyFirmName = attorneyProfile.firmName
    caseHeader.filingPartyAddress1 = attorneyProfile.address1
    caseHeader.filingPartyAddress2 = attorneyProfile.address2
    caseHeader.cityStateZip = `${attorneyProfile.city}${attorneyProfile.city && ', '}${attorneyProfile.state} ${attorneyProfile.zip}`
    caseHeader.filingPartyPhone = attorneyProfile.phone
    caseHeader.filingPartyFax = attorneyProfile.fax
    caseHeader.filingPartyEmail = attorneyProfile.email
    caseHeader.filingPartyAttyReg = attorneyProfile.attyRegNo
  }
  const children = state.children.map(child => ({
    name: `${child.firstName} ${child.lastName}`,
    dob: child.dateOfBirth
  }))
  let worksheet = {
    description: worksheetAPdfExport.worksheet
  }
  if (WORKSHEET_A === worksheetUsed) {
    partyKeys.forEach(partyKey => {
      const worksheetKey = PARTY_1 === partyKey ? 'party1' : 'party2'
      const { supportPaidChildrenNOTR,
        otherRelationshipResponsibility }
        = adjustments[partyKey]
      worksheet[worksheetKey] = Object.assign({}, worksheet.party1, {
        label: PARTY_1 === partyKey ? party1Label : party2Label,
        monthlygrossincome: presentDollarAmount(
          parties[partyKey].monthlyGross, 0
        ),
        adjustedmaintenancereceived: presentDollarAmount(
          adjustedMaintenanceReceived[partyKey], 0
        ),
        adjustedmaintenancepaid: presentDollarAmount(
          adjustedMaintenancePaid[partyKey], 0
        ),
        supportPaidChildrenNOTR: supportPaidChildrenNOTR
          ? presentDollarAmount(supportPaidChildrenNOTR, 0) : '',
        otherRelationshipResponsibility: otherRelationshipResponsibility
          ? presentDollarAmount(otherRelationshipResponsibility, 0) : '',
        cappedDocumentedPaymentsChildrenOtherRelationship: cappedDocumentedPaymentsChildrenOtherRelationship[partyKey]
        ? presentDollarAmount(
          cappedDocumentedPaymentsChildrenOtherRelationship[partyKey], 0
        ): '',
        monthlyAdjustedGrossIncomes: false !== monthlyAdjustedGrossIncomes[partyKey] ?
          presentDollarAmount(monthlyAdjustedGrossIncomes[partyKey], 0) : '',
        shareofincome: false !== decimalSharesOfIncome[partyKey] ?
          presentDecimalAsPercentage(decimalSharesOfIncome[partyKey]) : '',
        shareofbasicobligation: false !== shareOfBasicSupportObligation[partyKey] ?
          presentDollarAmount(shareOfBasicSupportObligation[partyKey], 0) : '',
        lowincomeadjustment: false !== lowIncomeAdjustment[partyKey] ?
          presentDollarAmount(lowIncomeAdjustment[partyKey], 0) : '',
        Payingparentsupporttotal: false !== recommendedChildSupportOrder[partyKey] ?
          presentDollarAmount(recommendedChildSupportOrder[partyKey]) : '',
        workchildcarecosts: false !== supportObligationAdjustments[partyKey]['netWorkRelatedChildCare'] ?
          presentDollarAmount(supportObligationAdjustments[partyKey]['netWorkRelatedChildCare'], 0) : '',
        educhildcarecosts: false !== supportObligationAdjustments[partyKey]['educationRelatedChildCare'] ?
          presentDollarAmount(supportObligationAdjustments[partyKey]['educationRelatedChildCare'], 0) : '',
        healthinscosts: false !== supportObligationAdjustments[partyKey]['healthInsurancePremiums'] ?
          presentDollarAmount(supportObligationAdjustments[partyKey]['healthInsurancePremiums'], 0) : '',
        medexpenses: false !== supportObligationAdjustments[partyKey]['extraordinaryMedical'] ?
          presentDollarAmount(supportObligationAdjustments[partyKey]['extraordinaryMedical'], 0) : '',
        extraexpenses: false !== supportObligationAdjustments[partyKey]['extraordinaryExpenses'] ?
          presentDollarAmount(supportObligationAdjustments[partyKey]['extraordinaryExpenses'], 0) : '',
        extraadjustments: false !== supportObligationAdjustments[partyKey]['extraordinaryAdjustments'] ?
          presentDollarAmount(supportObligationAdjustments[partyKey]['extraordinaryAdjustments'], 0) : '',
        totaladjustments: false !== supportObligationAdjustments[partyKey]['totalAdjustments'] ?
          presentDollarAmount(supportObligationAdjustments[partyKey]['totalAdjustments'], 0) : '',
        shareadjustments: false !== fairShareOfAdjustments[partyKey] ?
          presentDollarAmount(fairShareOfAdjustments[partyKey], 0) : '',
        Payingparentsharetotal: false !== shareOfTotalChildSupport[partyKey] ?
          presentDollarAmount(shareOfTotalChildSupport[partyKey], 0) : '',
        Payingparentadjustment: false !== payingParentAdjustment[partyKey] ?
          presentDollarAmount(payingParentAdjustment[partyKey], 0) : ''
      })
    })
    worksheet.combined = Object.assign(
      {},
      worksheetAPdfExport.worksheet.combined,
      {
        combinedAdjustedGrossIncome: false !== combinedAdjustedGrossIncome ?
          presentDollarAmount(combinedAdjustedGrossIncome, 0) : '',
        basicsupportobligation: false !== basicSupportObligation ?
          presentDollarAmount(basicSupportObligation, 0) : '',
        Combinedtotaladjustments: false !== combinedTotalAdjustments ?
          presentDollarAmount(combinedTotalAdjustments, 0) : '',
      }
    )
    return {
      caseHeader,
      children,
      worksheet,
      comments
    }
  } else if (WORKSHEET_B === worksheetUsed) {
    caseHeader['workSheetType'] = 'b'
    Object.assign(worksheet, worksheetBPdfExport.worksheet)
    partyKeys.forEach(partyKey => {
      const worksheetKey = PARTY_1 === partyKey ? 'party1' : 'party2'
      const { supportPaidChildrenNOTR,
        otherRelationshipResponsibility }
        = adjustments[partyKey]
      const partyOvernights = (partyKey === overnightsParty) ? overnights : otherPartyOvernights
      const partyPercentageTime = (partyKey === overnightsParty) ? overnightsPercentage : otherPartyOvernightsPercentage
      worksheet[worksheetKey] = Object.assign({}, {
        label: PARTY_1 === partyKey ? party1Label : party2Label,
        monthlygrossincome: presentDollarAmount(
          parties[partyKey].monthlyGross, 0
        ),
        adjustedmaintenancereceived: presentDollarAmount(
          adjustedMaintenanceReceived[partyKey], 0
        ),
        adjustedmaintenancepaid: presentDollarAmount(
          adjustedMaintenancePaid[partyKey], 0
        ),
        supportPaidChildrenNOTR: supportPaidChildrenNOTR
          ? presentDollarAmount(supportPaidChildrenNOTR, 0) : templates.notAvailable,
        otherRelationshipResponsibility: otherRelationshipResponsibility
          ? presentDollarAmount(otherRelationshipResponsibility, 0) : templates.notAvailable,
        cappedDocumentedPaymentsChildrenOtherRelationship: cappedDocumentedPaymentsChildrenOtherRelationship[partyKey]
        ? presentDollarAmount(
          cappedDocumentedPaymentsChildrenOtherRelationship[partyKey], 0
        ) : templates.notAvailable,
        monthlyAdjustedGrossIncomes: monthlyAdjustedGrossIncomes[partyKey] ?
          presentDollarAmount(monthlyAdjustedGrossIncomes[partyKey], 0) : templates.notAvailable,
        shareofincome: decimalSharesOfIncome[partyKey] ?
          presentDecimalAsPercentage(decimalSharesOfIncome[partyKey]) : templates.notAvailable,
        shareofbasicobligation: eachParentsPortionOfSharedPhysicalCareSupportObligation[partyKey] ?
          presentDollarAmount(eachParentsPortionOfSharedPhysicalCareSupportObligation[partyKey], 0) : templates.notAvailable,
        overnightstays: false !== partyOvernights ? partyOvernights : templates.notAvailable,
        percentagetime: false !== partyPercentageTime ?
          presentDecimalAsPercentage(partyPercentageTime) : templates.notAvailable,
        supportfortime: false !== supportForTime[partyKey] ?
        presentDollarAmount(supportForTime[partyKey], 0) : templates.notAvailable,
        workchildcarecosts: false !== supportObligationAdjustments[partyKey]['netWorkRelatedChildCare'] ?
          presentDollarAmount(supportObligationAdjustments[partyKey]['netWorkRelatedChildCare'], 0) : templates.notAvailable,
        educhildcarecosts: false !== supportObligationAdjustments[partyKey]['educationRelatedChildCare'] ?
          presentDollarAmount(supportObligationAdjustments[partyKey]['educationRelatedChildCare'], 0) : templates.notAvailable,
        healthinscosts: false !== supportObligationAdjustments[partyKey]['healthInsurancePremiums'] ?
          presentDollarAmount(supportObligationAdjustments[partyKey]['healthInsurancePremiums'], 0) : templates.notAvailable,
        medexpenses: false !== supportObligationAdjustments[partyKey]['extraordinaryMedical'] ?
          presentDollarAmount(supportObligationAdjustments[partyKey]['extraordinaryMedical'], 0) : templates.notAvailable,
        extraexpenses: false !== supportObligationAdjustments[partyKey]['extraordinaryExpenses'] ?
          presentDollarAmount(supportObligationAdjustments[partyKey]['extraordinaryExpenses'], 0) : templates.notAvailable,
        extraadjustments: false !== supportObligationAdjustments[partyKey]['extraordinaryAdjustments'] ?
          presentDollarAmount(supportObligationAdjustments[partyKey]['extraordinaryAdjustments'], 0) : templates.notAvailable,
        totaladjustments: false !== supportObligationAdjustments[partyKey]['totalAdjustments'] ?
          presentDollarAmount(supportObligationAdjustments[partyKey]['totalAdjustments'], 0) : templates.notAvailable,
        shareadjustments: false !== fairShareOfAdjustments[partyKey] ?
          presentDollarAmount(fairShareOfAdjustments[partyKey], 0) : templates.notAvailable,
        excessadjustmentspaid: false !== excessAdjustmentsPaid[partyKey] ?
          presentDollarAmount(excessAdjustmentsPaid[partyKey], 0) : templates.notAvailable,
        adjustedobligation: (false !== adjustedObligation[partyKey]) ?
          presentDollarAmount(adjustedObligation[partyKey], 0) : templates.notAvailable,
      })
    })
    if (false !== worksheet['party1']['adjustedObligation']
      && false !== worksheet['party2']['adjustedObligation']) {
      partyKeys.forEach(partyKey => {
        if (PARTY_1 === partyKey
          && adjustedObligation[PARTY_1] > adjustedObligation[PARTY_2]) {
          worksheet['party1']['Payingparentsupporttotal'] =
            presentDollarAmount(
              adjustedObligation[PARTY_1] - adjustedObligation[PARTY_2],
              0
            )
        } else if (PARTY_2 === partyKey
          && adjustedObligation[PARTY_2] > adjustedObligation[PARTY_1]) {
          worksheet['party2']['Payingparentsupporttotal'] =
            presentDollarAmount(
              adjustedObligation[PARTY_2] - adjustedObligation[PARTY_1],
              0
            )
        }
      })
    }
    worksheet.combined = Object.assign(
      {},
      worksheetBPdfExport.worksheet.combined,
      {
        combinedAdjustedGrossIncome: false !== combinedAdjustedGrossIncome ?
          presentDollarAmount(combinedAdjustedGrossIncome, 0) : '',
        basicsupportobligation: false !== basicSupportObligation ?
          presentDollarAmount(basicSupportObligation, 0) : '',
        sharedphysicalcaresupportobligation: false !== sharedPhysicalSupportObligation ?
          presentDollarAmount(sharedPhysicalSupportObligation, 0) : templates.notAvailable,
        Combinedtotaladjustments: false !== combinedTotalAdjustments ?
          presentDollarAmount(combinedTotalAdjustments, 0) : '',
      }
    )
    return {
      caseHeader,
      children,
      worksheet,
      comments
    }
  } else {
    throw 'Unable to determine worksheet type A or B. Number of overnights is required.'
  }
}

const ChildSupportLoader = props => {
  const { id: caseId, caseDocId } = props
  const buffering = useBuffer()
  const caseDoc = useCaseDoc(caseId, caseDocId)
  const {
    loading: attorneyProfilesLoading,
    attorneyProfiles
  } = useAttorneyProfiles({})
  const { status } = caseDoc
  const initializing = -1 !== ['initializing', 'initialize'].indexOf(status)
  if (initializing || attorneyProfilesLoading || buffering) return <Loader />
  return <ChildSupport {...caseDoc} {...{attorneyProfiles}} />
}
const ChildSupport = props => {
  const {
    loading,
    caseProfile,
    caseDocData,
    modalHeader,
    modalMessage,
    error,
    actions,
    attorneyProfiles = []
  } = props
  const {
    id: caseId,
    parties: caseProfileParties = {}
  } = caseProfile
  const {
    coPetitionerRespondent = {},
    petitioner = {}
  } = caseProfileParties
  const { updateCaseDoc, dismiss } = actions
  const {
    admin = {}
  } = caseProfile
  const {
    caseName = ''
  } = admin

  // State/Reducer
  // Apply adjustment template to populate template values in legacy data
  const initialAdjustments = Object.assign(
    {},
    {
      adjustments: {
        PARTY_1: Object.assign(
          {},
          templates.adjustments,
          caseDocData?.adjustments?.[PARTY_1]
        ),
        PARTY_2: Object.assign(
          {},
          templates.adjustments,
          caseDocData?.adjustments?.[PARTY_1]
        ),
      }
    }
  )
  const initialState = Object.assign(
    {},
    stateTemplate,
    caseDocData,
    initialAdjustments
    )
  const [state, dispatch] = useReducer(reducer, initialState)
  const {
    parties,
    children,
    numberOfChildren,
    numberOfChildrenInput,
    overnightWidget,
    overnightsParty,
    overnights,
    overnightsInput,
    adjustments,
    spousalMaintenanceEntryMethod,
    childSupportNOTREntry,
    monthlyAdjustedGrossIncomesForSpousalSupport,
    calculatedSpousalSupport,
    directSpousalSupport,
    monthlyAdjustedGrossIncomes,
    decimalSharesOfIncome,
    payor,
    spousalMaintenanceNOTREntry,
    supportObligationAdjustments,
    recommendedChildSupportOrder,
    comments,
    title,
    calculationModel
  } = state
  const { PARTY_1: party1, PARTY_2: party2 } = parties
  // Adjustments to Gross Income
  const { PARTY_1: adjustments1, PARTY_2: adjustments2} = adjustments
  // Monthly Adjusted Gross Income
  const { PARTY_1: party1MonthlyAdjustedGross,
    PARTY_2: party2MonthlyAdjustedGross } = monthlyAdjustedGrossIncomes
  // Adjustments to Support Obligation
  const { PARTY_1: party1SupportObligationAdjustments,
    PARTY_2: party2SupportObligationAdjustments } = supportObligationAdjustments
  // Percentage Shares of Income
  const { PARTY_1: party1ShareOfIncome,
    PARTY_2: party2ShareOfIncome } = decimalSharesOfIncome
  // Tabs
  const [ activeTab, setActiveTab ] = useState(false)
  const toggleActiveTab = tabName => {
    setActiveTab( tabName === activeTab ? false : tabName)
  }
  const overnightsSummary = {
    overnightsParty1:  overnights !== false ? (
      PARTY_1 === overnightsParty ? overnights : 365 - overnights) : false,
    overnightsParty2: overnights !== false ? (
      PARTY_2 === overnightsParty ? overnights : 365 - overnights) : false,
  }
  const caseHeaderProps = {
    case: { title: caseName, id: caseId },
    doc: { title }
  }
  const party1Label = 'FIRST_NAME' === party1.displayAs ?
    ( party1.firstName ? party1.firstName : 'Label N/A' )
    : party1.displayAs ? DISPLAY_PARTY_AS_OPTIONS.find(opt => opt.value === party1.displayAs)['label']
    : 'Petitioner'
  const party2Label = 'FIRST_NAME' === party2.displayAs ?
    ( party2.firstName ? party2.firstName : 'Label N/A' )
    : party2.displayAs ? DISPLAY_PARTY_AS_OPTIONS.find(opt => opt.value === party2.displayAs)['label']
    : 'Respondent'
  const { overnightsParty1,
    overnightsParty2
  } = overnightsSummary
  const caseCaptionPartyOptions = []
  if (coPetitionerRespondent.firstName || coPetitionerRespondent.lastName) {
    caseCaptionPartyOptions.push({
      value: 'coPetitionerRespondent',
      label: [coPetitionerRespondent.firstName, coPetitionerRespondent.lastName].join(' ')
    })
  }
  if (petitioner.firstName || petitioner.lastName) {
    caseCaptionPartyOptions.push({
      value: 'petitioner',
      label: [petitioner.firstName, petitioner.lastName].join(' ')
    })
  }
  const handleSelectedParty = party => e => {
    const newCaseProfileParty = e.target.value
    const update = {
      caseProfileParty: newCaseProfileParty,
      firstName: caseProfileParties[newCaseProfileParty]?.firstName || '',
      lastName: caseProfileParties[newCaseProfileParty]?.lastName || ''
    }
    dispatch({ type: 'SET_PARTY', party, update })
  }

  const setCalculationModel = newCalculationModel => dispatch({
    type: 'SET_CALCULATION_MODEL',
    calculationModel: newCalculationModel
  })
  if (loading) return <Loader />
  const summaryPanelProps = {
    numberOfChildren,
    party1,
    party2,
    party1Label,
    overnightsParty1,
    party2Label,
    overnightsParty2,
    spousalMaintenanceEntryMethod,
    calculatedSpousalSupport,
    directSpousalSupport,
    party1MonthlyAdjustedGross,
    party2MonthlyAdjustedGross,
    party1ShareOfIncome,
    party2ShareOfIncome,
    payor,
    recommendedChildSupportOrder,
    previewChildSupportWorksheet,
    pdfExportData,
    state,
    caseProfile,
    attorneyProfiles,
    updateCaseDoc
  }
  const partiesSectionProps = {
    activeTab,
    toggleActiveTab,
    party1,
    party2,
    handleSelectedParty,
    caseCaptionPartyOptions,
    children,
    dispatch
  }
  return (
    <div>

      <CaseHeader {...caseHeaderProps} />

      <Layout.ColsMain>

        <SummaryPanel {...summaryPanelProps}/>

        <Layout.MainCol2>

          <UI.ToggleSectionButton
            isActive={'CHILDREN' === activeTab}
            onClick={() => toggleActiveTab('CHILDREN')}>
            <UI.ToggleSectionIcon
              icon={'CHILDREN' === activeTab ? faAngleDown : faAngleRight} /> Children
          </UI.ToggleSectionButton>
          {'CHILDREN' === activeTab && (
            <UI.BodySectionInner>
              <AppUI.Input>
                <label>Enter the number of children:</label>
                <Grid4Columns>
                  <input
                    type="number"
                    min="1"
                    max="6"
                    value={numberOfChildrenInput}
                    onChange={e => {
                      dispatch({
                        type: 'SET_NUMBER_OF_CHILDREN',
                        value: e.target.value
                      })
                    }}
                  />
                </Grid4Columns>
              </AppUI.Input>
              {(6 <= numberOfChildren) &&
                  <UI.BodyInstructions><em>Because Colorado’s Child Support Guidelines do not account for greater than six children, this calculation will calculate child support for up to six children only. C.R.S. § 14-10-115(7)(b).</em></UI.BodyInstructions>
              }
            </UI.BodySectionInner>
          )}
          <UI.ToggleSectionButton
            isActive={'OVERNIGHTS' === activeTab}
            onClick={() => toggleActiveTab('OVERNIGHTS')}>
            <UI.ToggleSectionIcon
              icon={'OVERNIGHTS' === activeTab ? faAngleDown : faAngleRight} /> Overnights
          </UI.ToggleSectionButton>
          {'OVERNIGHTS' === activeTab && (
            <UI.BodySectionInner>
              <AppUI.Input>
                <label>Choose a party to enter overnights schedules</label>
                <AppUI.RadioLabel>Enter overnights child or children stay with <em>{party1Label}</em>
                  <input
                    name="overnightsParty"
                    value="PARTY_1"
                    type="radio"
                    checked={PARTY_1 === overnightsParty}
                    onChange={e => {
                      dispatch({
                        type: 'SET_OVERNIGHTS_PARTY',
                        party: e.target.value
                      })
                    }}/>
                  <AppUI.RadioCheckmark className="app-checkmark" />
                </AppUI.RadioLabel>
                <AppUI.RadioLabel>Enter overnights child or children stay with <em>{party2Label}</em>
                  <input
                    name="overnightsParty"
                    value="PARTY_2"
                    type="radio"
                    checked={PARTY_2 === overnightsParty}
                    onChange={e => {
                      dispatch({
                        type: 'SET_OVERNIGHTS_PARTY',
                        party: e.target.value
                      })
                    }}/>
                  <AppUI.RadioCheckmark className="app-checkmark" />
                </AppUI.RadioLabel>
              </AppUI.Input>
              <AppUI.Input>
                <label htmlFor="overnightWidget">Select how you would like to enter the number of overnights</label>
                <AppUI.RadioLabel>Enter overnights directly
                  <input
                    name="overnightWidget"
                    value="DIRECT"
                    type="radio"
                    checked={'DIRECT' === overnightWidget}
                    onChange={e => {
                      dispatch({
                        type: 'SET_OVERNIGHTS_WIDGET',
                        widget: e.target.value
                      })
                    }}/>
                  <AppUI.RadioCheckmark className="app-checkmark" />
                </AppUI.RadioLabel>
                <AppUI.RadioLabel>Use calculator to enter number of overnights
                  <input
                    name="overnightWidget"
                    value="CALCULATOR"
                    type="radio"
                    checked={'CALCULATOR' === overnightWidget}
                    onChange={e => {
                      dispatch({
                        type: 'SET_OVERNIGHTS_WIDGET',
                        widget: e.target.value
                      })
                    }}/>
                  <AppUI.RadioCheckmark className="app-checkmark" />
                </AppUI.RadioLabel>
              </AppUI.Input>
              {'DIRECT' === overnightWidget && (
                <Grid4Columns>
                  <AppUI.Input>
                    <label>Number of Overnights</label>
                    <input
                      type="number"
                      min="0"
                      max="365"
                      value={overnightsInput}
                      onChange={e => {
                        dispatch({
                          type: 'SET_OVERNIGHTS_INPUT',
                          value: e.target.value
                        })
                      }}
                    />
                  </AppUI.Input>
                </Grid4Columns>
              )}
            </UI.BodySectionInner>
          )}
          {'OVERNIGHTS' === activeTab &&
            'CALCULATOR' === overnightWidget && (
              <UI.BodySectionInner>
                <OvernightsCalculator />
              </UI.BodySectionInner>
          )}
          <UI.ToggleSectionButton
            isActive={'MONTHLY_GROSS_INCOME' === activeTab}
            onClick={() => toggleActiveTab('MONTHLY_GROSS_INCOME')}>
            <UI.ToggleSectionIcon
              icon={'MONTHLY_GROSS_INCOME' === activeTab ? faAngleDown : faAngleRight} /> Monthly Gross Income
          </UI.ToggleSectionButton>
          {'MONTHLY_GROSS_INCOME' === activeTab && (
            <UI.BodySectionInner>
              <UI.BodyInstructions>Enter the monthly gross income of each parent.</UI.BodyInstructions>
              <Grid2Columns>
                <AppUI.Input>
                  <label>{party1Label}</label>
                  <input
                    type="number"
                    step="0.01"
                    min="0"
                    value={party1.monthlyGross}
                    onChange={e => {
                      const newMonthlyGross = '' === e.target.value ? false : Number(e.target.value)
                      dispatch({ type: 'SET_PARTY', party: PARTY_1,
                        update: { monthlyGross: newMonthlyGross }
                      })
                    }}
                  />
                </AppUI.Input>
                <AppUI.Input>
                  <label>{party2Label}</label>
                  <input
                    type="number"
                    step="0.01"
                    min="0"
                    value={party2.monthlyGross}
                    onChange={e => {
                      const newMonthlyGross = '' === e.target.value ? false : Number(e.target.value)
                      dispatch({ type: 'SET_PARTY', party: PARTY_2,
                        update: { monthlyGross: newMonthlyGross }
                      })
                    }}
                  />
                </AppUI.Input>
              </Grid2Columns>
            </UI.BodySectionInner>
          )}
          <UI.ToggleSectionButton
            isActive={'ADJUSTMENTS_TO_GROSS_INCOME' === activeTab}
            onClick={() => toggleActiveTab('ADJUSTMENTS_TO_GROSS_INCOME')}>
            <UI.ToggleSectionIcon
              icon={'ADJUSTMENTS_TO_GROSS_INCOME' === activeTab ? faAngleDown : faAngleRight} /> Income Adjustments
          </UI.ToggleSectionButton>
          {'ADJUSTMENTS_TO_GROSS_INCOME' === activeTab && (
            <UI.BodySectionInner>
              <UI.BodySubSectionTitle>Spousal Maintenance For This Relationship</UI.BodySubSectionTitle>
              <AppUI.Input>
                <AppUI.RadioLabel>Do not enter spousal maintenance for this relationship
                  <input
                    name="spousalMaintenanceEntrymethod"
                    value=""
                    type="radio"
                    checked={'' === spousalMaintenanceEntryMethod}
                    onChange={e => {
                      dispatch({
                        type: 'SET_SPOUSAL_MAINTENANCE_ENTRY_METHOD',
                        entryMethod: e.target.value
                      })
                    }}/>
                  <AppUI.RadioCheckmark className="app-checkmark" />
                </AppUI.RadioLabel>
                <AppUI.RadioLabel>Use calculated spousal maintenance value
                  <input
                    name="spousalMaintenanceEntrymethod"
                    value="CALCULATOR"
                    type="radio"
                    checked={'CALCULATOR' === spousalMaintenanceEntryMethod}
                    onChange={e => {
                      dispatch({
                        type: 'SET_SPOUSAL_MAINTENANCE_ENTRY_METHOD',
                        entryMethod: e.target.value
                      })
                    }}/>
                  <AppUI.RadioCheckmark className="app-checkmark" />
                </AppUI.RadioLabel>
                <AppUI.RadioLabel>Enter spousal maintenance directly
                  <input
                    name="spousalMaintenanceEntrymethod"
                    value="DIRECT"
                    type="radio"
                    checked={'DIRECT' === spousalMaintenanceEntryMethod}
                    onChange={e => {
                      dispatch({
                        type: 'SET_SPOUSAL_MAINTENANCE_ENTRY_METHOD',
                        entryMethod: e.target.value
                      })
                    }}/>
                  <AppUI.RadioCheckmark className="app-checkmark" />
                </AppUI.RadioLabel>
              </AppUI.Input>
              <br />
              {'CALCULATOR' === spousalMaintenanceEntryMethod && <div>
                {calculatedSpousalSupport.payor === PARTY_1 && (
                  <UI.BodyInstructions><em>{party1Label}</em> pays <em>{party2Label}</em> {presentDollarAmount(calculatedSpousalSupport.maintenance)}</UI.BodyInstructions>
                )}
                {calculatedSpousalSupport.payor === PARTY_2 && (
                  <UI.BodyInstructions><em>{party2Label}</em> pays <em>{party1Label}</em> {presentDollarAmount(calculatedSpousalSupport.maintenance)}</UI.BodyInstructions>
                )}
                {!calculatedSpousalSupport.payor && <UI.BodyInstructions>N/A</UI.BodyInstructions>}
              </div>}
              {'DIRECT' === spousalMaintenanceEntryMethod && (
                <div>                
                  <AppUI.Input>
                    <label>Select spousal support <strong>payor:</strong></label>
                    <AppUI.RadioLabel>{party1Label}
                      <input
                      name="directSpousalSupport[payor]"
                      value="PARTY_1"
                      type="radio"
                      checked={PARTY_1 === directSpousalSupport.payor}
                      onChange={e => {
                        dispatch({
                          type: 'SET_DIRECT_SPOUSAL_SUPPORT',
                          update: { payor: PARTY_1}
                        })
                      }}
                      />
                      <AppUI.RadioCheckmark className="app-checkmark" />
                    </AppUI.RadioLabel>
                    <AppUI.RadioLabel>{party2Label}
                      <input
                      name="directSpousalSupport[payor]"
                      value="PARTY_2"
                      type="radio"
                      checked={PARTY_2 === directSpousalSupport.payor}
                      onChange={e => {
                        dispatch({
                          type: 'SET_DIRECT_SPOUSAL_SUPPORT',
                          update: { payor: PARTY_2}
                        })
                      }}
                      />
                      <AppUI.RadioCheckmark className="app-checkmark" />
                    </AppUI.RadioLabel>
                  </AppUI.Input>
                  <br />
                  <Grid2Columns>
                    <AppUI.Input>
                      <label>Enter the spousal maintenance monthly amount</label>
                      <input
                        type="number"
                        value={directSpousalSupport.maintenance}
                        onChange={e => {
                          dispatch({
                            type: 'SET_DIRECT_SPOUSAL_SUPPORT',
                            update: { maintenance: e.target.value }
                          })
                        }}
                      />
                    </AppUI.Input>
                  </Grid2Columns>
                </div>
              )}
              <br />
              <UI.BodySubSectionTitle>Spousal Maintenance Not Of This Relationship</UI.BodySubSectionTitle>
              <AppUI.Input>
                <AppUI.RadioLabel>Not applicable
                  <input
                    name="spousalMaintenanceNOTREntry"
                    value="false"
                    type="radio"
                    checked={!spousalMaintenanceNOTREntry}
                    onChange={e => {
                      dispatch({
                        type: 'SET_SPOUSAL_MAINTENANCE_NOTR_ENTRY',
                        entry: e.target.value
                      })
                    }}/>
                  <AppUI.RadioCheckmark className="app-checkmark" />
                </AppUI.RadioLabel>
                <AppUI.RadioLabel>Enter spousal maintenance <strong>not</strong> of this relationship
                  <input
                    name="spousalMaintenanceNOTREntry"
                    value="true"
                    type="radio"
                    checked={spousalMaintenanceNOTREntry}
                    onChange={e => {
                      dispatch({
                        type: 'SET_SPOUSAL_MAINTENANCE_NOTR_ENTRY',
                        entry: e.target.value
                      })
                    }}/>
                  <AppUI.RadioCheckmark className="app-checkmark" />
                </AppUI.RadioLabel>
              </AppUI.Input>
              {spousalMaintenanceNOTREntry && (
                <div>
                  <UI.BodyInstructions>Enter maintenance paid by each parent.</UI.BodyInstructions>
                  <Grid2Columns>
                    <div>
                      <AppUI.Input>
                        <label>{party1Label}</label>
                        <input
                          type="number"
                          value={adjustments1.maintenancePaidNOTR}
                          onChange={e => {
                            const newMaintenancePaidNOTR = '' === e.target.value ? false : Number(e.target.value)
                            dispatch({
                              type: 'SET_ADJUSTMENTS',
                              party: PARTY_1,
                              update: { maintenancePaidNOTR: newMaintenancePaidNOTR}
                            })
                          }}
                          />
                      </AppUI.Input>
                      <AppUI.Input>
                        <input
                          id="maintenancePaidNOTRIsTaxDeductible1"
                          type="checkbox"
                          checked={adjustments1.maintenancePaidNOTRIsTaxDeductible}
                          onChange={e => {
                            dispatch({
                              type: 'SET_ADJUSTMENTS',
                              party: PARTY_1,
                              update: { maintenancePaidNOTRIsTaxDeductible: !adjustments1.maintenancePaidNOTRIsTaxDeductible}
                            })
                          }}
                          />
                          <Label htmlFor="maintenancePaidNOTRIsTaxDeductible1">Maintenance is taxable</Label>
                      </AppUI.Input>
                    </div>
                    <div>
                      <AppUI.Input>
                        <label>{party2Label}</label>
                        <input
                          type="number"
                          value={adjustments2.maintenancePaidNOTR}
                          onChange={e => {
                            const newMaintenancePaidNOTR = '' === e.target.value ? false : Number(e.target.value)
                            dispatch({
                              type: "SET_ADJUSTMENTS",
                              party: PARTY_2,
                              update: { maintenancePaidNOTR: newMaintenancePaidNOTR}
                            })
                          }}
                          />
                      </AppUI.Input>
                      <AppUI.Input>
                        <input
                          id="maintenancePaidNOTRIsTaxDeductible2"
                          type="checkbox"
                          checked={adjustments2.maintenancePaidNOTRIsTaxDeductible}
                          onChange={e => {
                            dispatch({
                              type: 'SET_ADJUSTMENTS',
                              party: PARTY_2,
                              update: { maintenancePaidNOTRIsTaxDeductible: !adjustments2.maintenancePaidNOTRIsTaxDeductible}
                            })
                          }}
                          />
                          <label htmlFor="maintenancePaidNOTRIsTaxDeductible2">Maintenance is taxable</label>
                      </AppUI.Input>
                    </div>
                  </Grid2Columns>

                </div>
              )}
              <UI.BodySubSectionTitle>Child Support for Non-Joint Children</UI.BodySubSectionTitle>
              <AppUI.Input>
                <AppUI.RadioLabel>Not applicable
                  <input
                    name="childSupportNOTREntry"
                    value="false"
                    type="radio"
                    checked={!childSupportNOTREntry}
                    onChange={e => {
                      dispatch({
                        type: 'SET_CHILD_SUPPORT_NOTR_ENTRY',
                        entry: e.target.value
                      })
                    }}/>
                  <AppUI.RadioCheckmark className="app-checkmark" />
                </AppUI.RadioLabel>
                <AppUI.RadioLabel>Enter child support for children <strong>not</strong> of this relationship
                  <input
                    name="childSupportNOTREntry"
                    value="true"
                    type="radio"
                    checked={childSupportNOTREntry}
                    onChange={e => {
                      dispatch({
                        type: 'SET_CHILD_SUPPORT_NOTR_ENTRY',
                        entry: e.target.value
                      })
                    }}/>
                  <AppUI.RadioCheckmark className="app-checkmark" />
                </AppUI.RadioLabel>
              </AppUI.Input>
              {childSupportNOTREntry && (
                <div>
                  <UI.BodyInstructions>Enter the <strong>court-ordered</strong> child support payments made by each parent for children not of this relationship pursuant to §14-10-115(6)(a)(I), C.R.S, if any.</UI.BodyInstructions>
                  <Grid2Columns>
                    <AppUI.Input>
                      <label>{party1Label}</label>
                      <input
                        type="number"
                        value={adjustments1.supportPaidChildrenNOTR}
                        onChange={e => {
                          const newSupportPaid = '' === e.target.value ? false : Number(e.target.value)
                          dispatch({
                            type: 'SET_ADJUSTMENTS',
                            party: PARTY_1,
                            update: { supportPaidChildrenNOTR: newSupportPaid }
                          })
                        }}
                      />
                    </AppUI.Input>
                    <AppUI.Input>
                      <label>{party2Label}</label>
                      <input
                        type="number"
                        value={adjustments2.supportPaidChildrenNOTR}
                        onChange={e => {
                          const newSupportPaid = '' === e.target.value ? false : Number(e.target.value)
                          dispatch({
                            type: 'SET_ADJUSTMENTS',
                            party: PARTY_2,
                            update: { supportPaidChildrenNOTR: newSupportPaid }
                          })
                        }}
                      />
                    </AppUI.Input>
                  </Grid2Columns>
                  <UI.BodyInstructions>Enter the number of minor children not of this relationship <strong>living primarily</strong> with each parent, if any.</UI.BodyInstructions>
                  <Grid2Columns>
                    <AppUI.Input>
                      <label>{party1Label}</label>
                      <input
                        type="number"
                        value={adjustments1.numberOfChildrenOtherRelationshipPrimaryLiving}
                        min="0"
                        step="1"
                        onChange={e => {
                          const newNumberOfChildren = '' === e.target.value ? false : Number(e.target.value)
                          dispatch({
                            type: 'SET_ADJUSTMENTS',
                            party: PARTY_1,
                            update: { numberOfChildrenOtherRelationshipPrimaryLiving: newNumberOfChildren }
                          })
                        }}
                      />
                    </AppUI.Input>
                    <AppUI.Input>
                      <label>{party2Label}</label>
                      <input
                        type="number"
                        value={adjustments2.numberOfChildrenOtherRelationshipPrimaryLiving}
                        min="0"
                        step="1"
                        onChange={e => {
                          const newNumberOfChildren = '' === e.target.value ? false : Number(e.target.value)
                          dispatch({
                            type: 'SET_ADJUSTMENTS',
                            party: PARTY_2,
                            update: { numberOfChildrenOtherRelationshipPrimaryLiving: newNumberOfChildren }
                          })
                        }}
                      />
                    </AppUI.Input>
                  </Grid2Columns>
                  {(adjustments1.numberOfChildrenOtherRelationshipPrimaryLiving ||
                    adjustments2.numberOfChildrenOtherRelationshipPrimaryLiving) && (
                    <div>
                      <UI.BodyInstructions>Based on the monthly gross incomes and number of minor children not of this relationship living primarily with each parent, the credits pursuant to §14-10-115(6)(b), C.R.S are:</UI.BodyInstructions>
                      <Grid2Columns>
                        <AppUI.Input>
                          <label>{party1Label}</label>
                          <UI.BodyValue>{adjustments1.otherRelationshipResponsibility ?
                              presentDollarAmount(adjustments1.otherRelationshipResponsibility)
                              : 'N/A'}</UI.BodyValue>
                        </AppUI.Input>
                        <AppUI.Input>
                          <label>{party2Label}</label>
                          <UI.BodyValue>{adjustments2.otherRelationshipResponsibility ?
                              presentDollarAmount(adjustments2.otherRelationshipResponsibility)
                            : 'N/A'}</UI.BodyValue>
                        </AppUI.Input>
                      </Grid2Columns>
                    </div>
                  )}
                  <UI.BodyInstructions>Enter the amount of <strong>documented money payments</strong> actually paid for the support of children not of this relationship who do not live primarily with each parent pursuant to §14-10-115(6)(a)(III), C.R.S.</UI.BodyInstructions>
                  <Grid2Columns>
                    <AppUI.Input>
                      <label>{party1Label}</label>
                      <input
                        type="number"
                        value={adjustments1.documentedPaymentsChildrenOtherRelationship}
                        onChange={e => {
                          const newPayments = '' === e.target.value ? false : Number(e.target.value)
                          dispatch({
                            type: 'SET_ADJUSTMENTS',
                            party: PARTY_1,
                            update: { documentedPaymentsChildrenOtherRelationship: newPayments }
                          })
                        }}
                      />
                    </AppUI.Input>
                    <AppUI.Input>
                      <label>{party2Label}</label>
                      <input
                        type="number"
                        value={adjustments2.documentedPaymentsChildrenOtherRelationship}
                        onChange={e => {
                          const newPayments = '' === e.target.value ? false : Number(e.target.value)
                          dispatch({
                            type: 'SET_ADJUSTMENTS',
                            party: PARTY_2,
                            update: { documentedPaymentsChildrenOtherRelationship: newPayments }
                          })
                        }}
                      />
                    </AppUI.Input>
                  </Grid2Columns>
                  {/*<UI.BodyInstructions>Enter the number of minor children not of this relationship not living primarily with each parent, if any.</UI.BodyInstructions>
                  <Grid2Columns>
                    <AppUI.Input>
                      <label>{party1Label}</label>
                      <input
                        type="number"
                        value={adjustments1.numberOfChildrenOtherRelationshipNotPrimaryLiving}
                        min="0"
                        step="1"
                        onChange={e => {
                          const newNumberOfChildren = '' === e.target.value ? false : Number(e.target.value)
                          dispatch({
                            type: 'SET_ADJUSTMENTS',
                            party: PARTY_1,
                            update: { numberOfChildrenOtherRelationshipNotPrimaryLiving: newNumberOfChildren }
                          })
                        }}
                      />
                    </AppUI.Input>
                    <AppUI.Input>
                      <label>{party2Label}</label>
                      <input
                        type="number"
                        value={adjustments2.numberOfChildrenOtherRelationshipNotPrimaryLiving}
                        min="0"
                        step="1"
                        onChange={e => {
                          const newNumberOfChildren = '' === e.target.value ? false : Number(e.target.value)
                          dispatch({
                            type: 'SET_ADJUSTMENTS',
                            party: PARTY_2,
                            update: { numberOfChildrenOtherRelationshipNotPrimaryLiving: newNumberOfChildren }
                          })
                        }}
                      />
                    </AppUI.Input>
                      </Grid2Columns>*/}
                </div>
              )}
            </UI.BodySectionInner>
          )}
          <UI.ToggleSectionButton
            isActive={'ADJUSTMENTS_TO_SUPPORT_OBLIGATION' === activeTab}
            onClick={() => toggleActiveTab('ADJUSTMENTS_TO_SUPPORT_OBLIGATION')}>
            <UI.ToggleSectionIcon
              icon={'ADJUSTMENTS_TO_SUPPORT_OBLIGATION' === activeTab ? faAngleDown : faAngleRight} /> Support Obligation Adjustments
          </UI.ToggleSectionButton>
          {'ADJUSTMENTS_TO_SUPPORT_OBLIGATION' === activeTab && (
            <UI.BodySectionInner>
              <UI.BodyInstructions>Enter each parent’s actual work-related child care costs, if any.</UI.BodyInstructions>
              <Grid2Columns>
                <AppUI.Input>
                  <label>{party1Label}</label>
                  <input
                    type="number"
                    value={party1SupportObligationAdjustments.workRelatedChildCare}
                    onChange={e => {
                      const newChildCare = '' === e.target.value ? false : Number(e.target.value)
                      dispatch({ type: 'SET_OBLIGATION_ADJUSTMENTS', party: PARTY_1, update: { workRelatedChildCare: newChildCare } })
                    }}
                  />
                </AppUI.Input>
                <AppUI.Input>
                  <label>{party2Label}</label>
                  <input
                    type="number"
                    value={party2SupportObligationAdjustments.workRelatedChildCare}
                    onChange={e => {
                      const newChildCare = '' === e.target.value ? false : Number(e.target.value)
                      dispatch({ type: 'SET_OBLIGATION_ADJUSTMENTS', party: PARTY_2, update: { workRelatedChildCare: newChildCare } })
                    }}
                  />
                </AppUI.Input>
              </Grid2Columns>
              <UI.BodyInstructions>Each parent’s actual work-related child care costs minus federal tax credit pursuant to §14-10-115(9), C.R.S are as follows:</UI.BodyInstructions>
              <Grid2Columns>
                <AppUI.Input>
                  <label>{party1Label}</label>
                  <UI.BodyValueNA>
                    {party1SupportObligationAdjustments.netWorkRelatedChildCare
                        ? presentDollarAmount(
                          party1SupportObligationAdjustments.netWorkRelatedChildCare
                        ) : 'Not applicable.'}
                  </UI.BodyValueNA>
                </AppUI.Input>
                <AppUI.Input>
                  <label>{party2Label}</label>
                  <UI.BodyValueNA>
                    {party2SupportObligationAdjustments.netWorkRelatedChildCare
                        ? presentDollarAmount(
                          party2SupportObligationAdjustments.netWorkRelatedChildCare
                        ) : 'Not applicable.'}
                  </UI.BodyValueNA>
                </AppUI.Input>
              </Grid2Columns>
              <UI.BodyInstructions>Enter each parent’s education-related child care costs pursuant to §14-10-115(9), C.R.S., if any:</UI.BodyInstructions>
              <Grid2Columns>
                <AppUI.Input>
                  <label>{party1Label}</label>
                  <input
                    type="number"
                    value={party1SupportObligationAdjustments.educationRelatedChildCare}
                    onChange={e => {
                      const newChildCare = '' === e.target.value ? false : Number(e.target.value)
                      dispatch({ type: 'SET_OBLIGATION_ADJUSTMENTS', party: PARTY_1, update: { educationRelatedChildCare: newChildCare } })
                    }}
                  />
                </AppUI.Input>
                <AppUI.Input>
                  <label>{party2Label}</label>
                  <input
                    type="number"
                    value={party2SupportObligationAdjustments.educationRelatedChildCare}
                    onChange={e => {
                      const newChildCare = '' === e.target.value ? false : Number(e.target.value)
                      dispatch({ type: 'SET_OBLIGATION_ADJUSTMENTS', party: PARTY_2, update: { educationRelatedChildCare: newChildCare } })
                    }}
                  />
                </AppUI.Input>
              </Grid2Columns>
              <UI.BodyInstructions>Enter each parent’s health insurance premium costs</UI.BodyInstructions>
              <Grid2Columns>
                <AppUI.Input>
                  <label>{party1Label}</label>
                  <input
                    type="number"
                    value={party1SupportObligationAdjustments.healthInsurancePremiums}
                    onChange={e => {
                      const newPremiums = '' === e.target.value ? false : Number(e.target.value)
                      dispatch({ type: 'SET_OBLIGATION_ADJUSTMENTS', party: PARTY_1, update: { healthInsurancePremiums: newPremiums } })
                    }}
                  />
                </AppUI.Input>
                <AppUI.Input>
                  <label>{party2Label}</label>
                  <input
                    type="number"
                    value={party2SupportObligationAdjustments.healthInsurancePremiums}
                    onChange={e => {
                      const newPremiums = '' === e.target.value ? false : Number(e.target.value)
                      dispatch({ type: 'SET_OBLIGATION_ADJUSTMENTS', party: PARTY_2, update: { healthInsurancePremiums: newPremiums } })
                    }}
                  />
                </AppUI.Input>
              </Grid2Columns>
              <p><AppButton onClick={demoAlert}>Health Insurance Premium Calculator</AppButton></p>
              <br />
              <UI.BodyInstructions>Enter each parent’s extraordinary medical expenses</UI.BodyInstructions>
              <Grid2Columns>
                <AppUI.Input>
                  <label>{party1Label}</label>
                  <input
                    type="number"
                    value={party1SupportObligationAdjustments.extraordinaryMedical}
                    onChange={e => {
                      const newMedical = '' === e.target.value ? false : Number(e.target.value)
                      dispatch({ type: 'SET_OBLIGATION_ADJUSTMENTS', party: PARTY_1, update: { extraordinaryMedical: newMedical } })
                    }}
                  />
                </AppUI.Input>
                <AppUI.Input>
                  <label>{party2Label}</label>
                  <input
                    type="number"
                    value={party2SupportObligationAdjustments.extraordinaryMedical}
                    onChange={e => {
                      const newMedical = '' === e.target.value ? false : Number(e.target.value)
                      dispatch({ type: 'SET_OBLIGATION_ADJUSTMENTS', party: PARTY_2, update: { extraordinaryMedical: newMedical } })
                    }}
                  />
                </AppUI.Input>
              </Grid2Columns>
              <UI.BodyInstructions>Enter each parent’s extraordinary expenses</UI.BodyInstructions>
              <Grid2Columns>
                <AppUI.Input>
                  <label>{party1Label}</label>
                  <input
                    type="number"
                    value={party1SupportObligationAdjustments.extraordinaryExpenses}
                    onChange={e => {
                      const newExpenses = '' === e.target.value ? false : Number(e.target.value)
                      dispatch({ type: 'SET_OBLIGATION_ADJUSTMENTS', party: PARTY_1, update: { extraordinaryExpenses: newExpenses } })
                    }}
                  />
                </AppUI.Input>
                <AppUI.Input>
                  <label>{party2Label}</label>
                  <input
                    type="number"
                    value={party2SupportObligationAdjustments.extraordinaryExpenses}
                    onChange={e => {
                      const newExpenses = '' === e.target.value ? false : Number(e.target.value)
                      dispatch({ type: 'SET_OBLIGATION_ADJUSTMENTS', party: PARTY_2, update: { extraordinaryExpenses: newExpenses } })
                    }}
                  />
                </AppUI.Input>
              </Grid2Columns>
              <UI.BodyInstructions>Enter each parent’s extraordinary adjustments</UI.BodyInstructions>
              <Grid2Columns>
                <AppUI.Input>
                  <label>{party1Label}</label>
                  <input
                    type="number"
                    value={party1SupportObligationAdjustments.extraordinaryAdjustments}
                    onChange={e => {
                      const newAdjustments = '' === e.target.value ? false : Number(e.target.value)
                      dispatch({ type: 'SET_OBLIGATION_ADJUSTMENTS', party: PARTY_1, update: { extraordinaryAdjustments: newAdjustments } })
                    }}
                  />
                </AppUI.Input>
                <AppUI.Input>
                  <label>{party2Label}</label>
                  <input
                    type="number"
                    value={party2SupportObligationAdjustments.extraordinaryAdjustments}
                    onChange={e => {
                      const newAdjustments = '' === e.target.value ? false : Number(e.target.value)
                      dispatch({ type: 'SET_OBLIGATION_ADJUSTMENTS', party: PARTY_2, update: { extraordinaryAdjustments: newAdjustments } })
                    }}
                  />
                </AppUI.Input>
              </Grid2Columns>
            </UI.BodySectionInner>
          )}
          <UI.ToggleSectionButton
            isActive={'COMMENTS' === activeTab}
            onClick={() => toggleActiveTab('COMMENTS')}>
            <UI.ToggleSectionIcon
              icon={'COMMENTS' === activeTab ? faAngleDown : faAngleRight} /> Comments
          </UI.ToggleSectionButton>
          {'COMMENTS' === activeTab && (
            <UI.BodySectionInner>
              <AppUI.Input>
                <label>Enter comments to appear in worksheet export.</label>
                <textarea
                  placholder="Enter comments..."
                  rows="6"
                  value={comments}
                  onChange={e => {
                    dispatch({
                      type: 'SET_COMMENTS',
                      comments: e.target.value
                    })
                  }}
                />
              </AppUI.Input>
            </UI.BodySectionInner>
          )}
          <Parties {...partiesSectionProps}/>
          <UI.ToggleSectionButton
            isActive={'ADMIN_SETTINGS' === activeTab}
            onClick={() => toggleActiveTab('ADMIN_SETTINGS')}>
            <UI.ToggleSectionIcon
              icon={'ADMIN_SETTINGS' === activeTab ? faAngleDown : faAngleRight} /> Admin Settings
          </UI.ToggleSectionButton>
          {'ADMIN_SETTINGS' === activeTab && (
            <UI.BodySectionInner>
              <AppUI.Input>
                <label>Document title</label>
                <input
                  type="text"
                  placholder="Enter document title..."
                  value={title}
                  onChange={e => {
                    dispatch({
                      type: 'SET_TITLE',
                      title: e.target.value
                    })
                  }}
                />
              </AppUI.Input>
              <AppUI.Input>
                <label>Choose a calculation model to apply.</label>
                <AppUI.RadioLabel>Run calculation based on current law
                  <input
                    name="calculationModel"
                    value="CURRENT"
                    type="radio"
                    checked={'CURRENT' === calculationModel}
                    onChange={e => setCalculationModel(e.target.value)}
                  />
                  <AppUI.RadioCheckmark className="app-checkmark" />
                </AppUI.RadioLabel>
                <AppUI.RadioLabel>Run calculation based on law prior to July 2020
                  <input
                    name="calculationModel"
                    value="PRE_JULY_2020"
                    type="radio"
                    checked={'PRE_JULY_2020' === calculationModel}
                    onChange={e => setCalculationModel(e.target.value)}
                  />
                  <AppUI.RadioCheckmark className="app-checkmark" />
                </AppUI.RadioLabel>
              </AppUI.Input>            
              <UI.BodyInstructions>To archive this calculation click the <strong>ARCHIVE</strong> button below.</UI.BodyInstructions>
              <AppButton onClick={demoAlert}>Archive</AppButton>
            </UI.BodySectionInner>
          )}
        </Layout.MainCol2>

      </Layout.ColsMain>
      {(error || modalHeader || modalMessage) && (
        <ModalWrapper close={() => dismiss()}>
          {modalHeader && <h2>{modalHeader}</h2>}
          {modalMessage && <p>{modalMessage}</p>}
          {error && error.message && <p><em>(Details: {error.message})</em></p>}
        </ModalWrapper>
      )}

    </div>
  )
}

export default ChildSupportLoader
