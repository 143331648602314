import React, { useReducer } from 'react'
import * as AppUI from '../app'
import Inputs from '../inputs'
import { DarkAppButton } from '../buttons/AppButton'
import simpleReducer from '../../helpers/simpleReducer'

const initialState = {
  name: '',
  firmName: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  phone: '',
  fax: '',
  email: '',
  attyRegNo: ''
}

const Form = ({id, create, update, attorneyProfile}) => {
  const creating = !id
  const [reducerState, dispatch] = useReducer(
    simpleReducer,
    Object.assign({}, initialState, attorneyProfile)
  )
  const {
    name,
    firmName,
    address1,
    address2,
    city,
    state,
    zip,
    phone,
    fax,
    email,
    attyRegNo
  } = reducerState
  const handleChange = e => {
    e.preventDefault()
    const { target } = e
    const { name, value } = target
    dispatch({ payload: { [name]: value }})
  }
  const submit = e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const newProfileData = {}
    for (const [key, val] of formData) {
      newProfileData[key] = val
    }
    if (creating) {
      create(newProfileData)
    } else {
      update(id, newProfileData)
    }
  }
  return (
    <form onSubmit={submit}>
      <AppUI.Input>
        <label>Name</label>
        <input
          required={true}
          type="text"
          placeholder="Enter name..."
          name="name"
          value={name}
          onChange={handleChange}
        />
      </AppUI.Input>
      <br />
      <AppUI.Input>
        <label>Firm Name</label>
        <input
          type="text"
          placeholder="Enter firm name..."
          name="firmName"
          value={firmName}
          onChange={handleChange}
        />
      </AppUI.Input>
      <br />
      <AppUI.Input>
        <label>Address Line 1</label>
        <input
          type="text"
          placeholder="Enter address line 1..."
          name="address1"
          required={true}
          value={address1}
          onChange={handleChange}
        />
      </AppUI.Input>
      <br />
      <AppUI.Input>
        <label>Address Line 2</label>
        <input
          type="text"
          placeholder="Enter address line 2..."
          name="address2"
          value={address2}
          onChange={handleChange}
        />
      </AppUI.Input>
      <br />
      <AppUI.Input>
        <label>City</label>
        <input
          type="text"
          placeholder="Enter city..."
          name="city"
          required={true}
          value={city}
          onChange={handleChange}
        />
      </AppUI.Input>
      <br />
      <AppUI.Input>
        <label>State</label>
        <input
          type="text"
          placeholder="Enter state..."
          name="state"
          required={true}
          value={state}
          onChange={handleChange}
        />
      </AppUI.Input>
      <br />
      <AppUI.Input>
        <label>Zip</label>
        <input
          type="text"
          placeholder="Enter zip..."
          name="zip"
          required={true}
          value={zip}
          onChange={handleChange}
        />
      </AppUI.Input>
      <br />
      <AppUI.Input>
        <label>Phone</label>
        <input
          type="text"
          placeholder="Enter phone..."
          name="phone"
          required={true}
          value={phone}
          onChange={handleChange}
        />
      </AppUI.Input>
      <br />
      <AppUI.Input>
        <label>Fax</label>
        <input
          type="text"
          placeholder="Enter fax..."
          name="fax"
          value={fax}
          onChange={handleChange}
        />
      </AppUI.Input>
      <br />
      <AppUI.Input>
        <label>Email</label>
        <input
          type="email"
          placeholder="Enter email..."
          name="email"
          value={email}
          onChange={handleChange}
        />
      </AppUI.Input>
      <br />
      <AppUI.Input>
        <label>Atty. Reg. #</label>
        <input
          type="text"
          placeholder="Enter atty reg no..."
          name="attyRegNo"
          required={true}
          value={attyRegNo}
          onChange={handleChange}
        />
      </AppUI.Input>
      <br />
      <DarkAppButton onSubmit={submit}>Save</DarkAppButton>
    </form>
  )
}
export default Form
