import React from 'react'
import { useSession } from '../../App'
import * as Layout from '../layouts/ContentWithSidebarLeft'
import useAttorneyProfiles from '../../hooks/useAttorneyProfiles'

const CaseSummary = ({profile}) => {
  const { user } = useSession()
  const { helpers } = useAttorneyProfiles({})
  const {
    admin = {},
    parties = {},
    courtCaseInfo = {},
    filingPartyInfo = {}
  } = profile
  const {
    caseName
  } = admin
  const {
    petitioner = {},
    coPetitionerRespondent = {},
    intervenor = ''
  } = parties
  const {
    firstName: petitionerFirstName = '',
    middleName: petitionerMiddleName = '',
    lastName: petitionerLastName = '',
  } = petitioner
  const {
    type: coPetitionerRespondentType = 'Co-Petitioner',
    firstName: coPetitionerRespondentFirstName = '',
    middleName: coPetitionerRespondentMiddleName = '',
    lastName: coPetitionerRespondentLastName = '',
  } = coPetitionerRespondent
  const {
    courtName = '',
    caseType = '',
    caseNumber = '',
    additionalCaseNumber = '',
    divisionCourtroom = ''
  } = courtCaseInfo
  const {
    partyRole = '',
    representation = ''
  } = filingPartyInfo
  const { representationOptions } = helpers
  const selectedRepresentation = representationOptions.find(option => representation === option.value)
  const { label: selectedRepresentationLabel } = selectedRepresentation || {}
  return (
    <div>
      <Layout.AsideSection>
        <Layout.AsideSummary>
          <Layout.SummaryLabel><strong>Admin</strong></Layout.SummaryLabel>
        </Layout.AsideSummary>
      </Layout.AsideSection>

      <Layout.AsideSection>
        <Layout.AsideSummary>
          <Layout.SummaryLabel>Case Profile: {caseName}</Layout.SummaryLabel>
        </Layout.AsideSummary>
        <Layout.AsideSummary>
          {/* todo: update when teams support reassigning case owner */}
          <Layout.SummaryLabel>Owned by {user.displayName}</Layout.SummaryLabel>
        </Layout.AsideSummary>
        <Layout.AsideSummary>
          <Layout.SummaryLabel>Created by {user.displayName}</Layout.SummaryLabel>
        </Layout.AsideSummary>
        <Layout.AsideSummary>
          <Layout.SummaryLabel>Last updated PLACEHOLDER by PLACEHOLDER2</Layout.SummaryLabel>
        </Layout.AsideSummary>
      </Layout.AsideSection>

      <Layout.AsideSection>
        <Layout.AsideSummary>
          <Layout.SummaryLabel><strong>Parties</strong></Layout.SummaryLabel>
        </Layout.AsideSummary>
      </Layout.AsideSection>

      <Layout.AsideSection>
        <Layout.AsideSummary>
          <Layout.SummaryLabel>Petitioner - {petitionerFirstName} {petitionerMiddleName} {petitionerLastName}</Layout.SummaryLabel>
        </Layout.AsideSummary>
        <Layout.AsideSummary>
          <Layout.SummaryLabel> {coPetitionerRespondentType} - {coPetitionerRespondentFirstName} {coPetitionerRespondentMiddleName} {coPetitionerRespondentLastName}</Layout.SummaryLabel>
        </Layout.AsideSummary>
        <Layout.AsideSummary>
          <Layout.SummaryLabel>Intervenor - {intervenor}</Layout.SummaryLabel>
        </Layout.AsideSummary>
      </Layout.AsideSection>

      <Layout.AsideSection>
        <Layout.AsideSummary>
          <Layout.SummaryLabel><strong>Court &amp; Case Information</strong></Layout.SummaryLabel>
        </Layout.AsideSummary>
      </Layout.AsideSection>

      <Layout.AsideSection>
        <Layout.AsideSummary>
          <Layout.SummaryLabel>Court Name - {courtName}</Layout.SummaryLabel>
        </Layout.AsideSummary>
        <Layout.AsideSummary>
          <Layout.SummaryLabel>Case Type - {caseType}</Layout.SummaryLabel>
        </Layout.AsideSummary>
        <Layout.AsideSummary>
          <Layout.SummaryLabel>Case Number - {caseNumber}</Layout.SummaryLabel>
        </Layout.AsideSummary>
        <Layout.AsideSummary>
          <Layout.SummaryLabel> {caseNumber && additionalCaseNumber ? `Additional Case Number - ${additionalCaseNumber}` : null}</Layout.SummaryLabel>
        </Layout.AsideSummary>
        <Layout.AsideSummary>
          <Layout.SummaryLabel>Division/Courtroom - {divisionCourtroom}</Layout.SummaryLabel>
        </Layout.AsideSummary>
      </Layout.AsideSection>

      <Layout.AsideSection>
        <Layout.AsideSummary>
          <Layout.SummaryLabel><strong>Filing Party Information</strong></Layout.SummaryLabel>
        </Layout.AsideSummary>
      </Layout.AsideSection>

      <Layout.AsideSection>
        <Layout.AsideSummary>
          <Layout.SummaryLabel>Party Role - {partyRole}</Layout.SummaryLabel>
        </Layout.AsideSummary>
        <Layout.AsideSummary>
          <Layout.SummaryLabel>Representation - {selectedRepresentationLabel}</Layout.SummaryLabel>
        </Layout.AsideSummary>
      </Layout.AsideSection>
    </div>
  )
}

export default CaseSummary
