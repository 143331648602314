import React from 'react'
import Header from '../../components/Header'
import Cases from '../../components/Cases'

const Dashboard = () => {
  return (
    <main>
      <Header version='loggedIn' />
      <Cases />
    </main>
  )
}
export default Dashboard
