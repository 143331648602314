import styled from 'styled-components'

// main layout
const Cols = styled.div`
  display: flex;
`
export const ColsMain = styled(Cols)`
  min-height: 100vh;
`
export const MainCol1 = styled.aside`
  flex: 1;
  padding: 40px;
  background: ${props => props.theme.colors.zircon};
`
export const MainCol2 = styled.div`
  flex: 3;
  padding: 40px;
`
// sidebar section
export const AsideSticky = styled.div`
  position: sticky;
  top: 130px;
`
export const AsideSection = styled.div`
  margin-bottom: 14px;
`
export const AsideSummary = styled.div`
  display: flex;
  justify-content: space-between;
`
export const AsideTitle = styled.p`
  margin-bottom: 5px;
`
export const SummaryLabel = styled.p`
  flex: 4;
`
export const SummaryValue = styled.p`
  flex: 1;
  text-align: right;
`
export const AsideActions = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  > button {
    flex-basis: 45%;
    margin-bottom: 30px;
  }
`
