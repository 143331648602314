import React from 'react'
import styled from 'styled-components'

const FormModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(to right, rgba(0,0,0,0.85), rgba(0,0,0,0.75), rgba(0,0,0,0.85));
    z-index: 999999;
`
FormModalWrapper.displayName = 'FormModalWrapper'

export default FormModalWrapper