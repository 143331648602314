import React from 'react'
import knowledgeBaseImage from '../../assets/images/at-the-library-divine.jpg'
const InterestCalculatorPage = {
  'content-block': {
    'title': 'Free Child Support Interest Calculator',
    'body': [
      ['Welcome! If you or your ex owes past-due child support according to a valid child support order issued by a Colorado Court, interest may apply to increase the debt. In Colorado, child support arrears due and owing as of July 1, 2021, or later accrues interest at a rate of 10%, that is two percent higher than the normal statutory interest rate, compounded annually. Interest on child support arrears due and owing prior to July 1, 2021, is calculated at a rate of 12%, ', <em>compounded monthly</em>, ' not annually. The longer a child support debt goes unpaid, the quicker that debt can grow. It requires some complicated math to add up the interest, which the Legal Thunder Interest Calculator can take care of for you.'],
      'To calculate your child support debt and to see how much interest may apply, use the calculator below. To learn more about child support interest, go to the Legal Thunder Knowledge Center.'],
    'cta-buttons': [
      /*{
        'text': 'Guide Me'
      },*/
      {
        'text': 'Start Calculation',
        'dark': true,
        'actionName': 'scrollToElement',
        'payload': {
          'id': 'interest-calculator'
        }
      }
    ]
  },
  'article-preview': {
    'category': 'Knowledge Base',
    'title': 'Learn About What Determines Child Support Interest Calculations In Colorado',
    'excerpt': '',
    'url': 'https://www.legalthunderapps.com/knowledge-base/',
    'image': <img src={knowledgeBaseImage} alt="Knowledge Base" />

  },
  intro: {
    'content-block': {
      'title': 'Child Support Interest Calculator',
      'body': ['To calculate your total child support debt, you need to enter the amount and due date of each payment according to the Support Order. Then enter actual amount and date of each payment that was made. Make sure you have your Child Support order(s) and payment records on hand, then click below to begin.'],
      'cta-buttons': [
        {
          'text': 'Enter Payments Due',
          'actionName': 'openAddPaymentsDueModal'
        },
        {
          'text': 'Enter Payments Made',
          'actionName': 'openAddPaymentsMadeModal'
        }
      ]
    }
  }
}

export default InterestCalculatorPage
