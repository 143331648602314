import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const ConfirmationPage = () => (
  <div>
      <h2>Congratulations, You've Successfully Registered and Subscribed!</h2>
      <p>Visit your <Link to={'/'}>Dashboard</Link> to begin creating cases and calculations or setup your <Link to={'/account/attorney-profiles/new'}>Attorney Profile</Link> to enter your personal information.</p>
  </div>
)

export default ConfirmationPage
