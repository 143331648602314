import React, { useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import Header from '../../components/Header'
import { DarkAppButton } from '../../components/buttons/AppButton'
import ContentBlock from '../../components/ContentBlock'
import Grid2Columns from '../../components/layouts/Grid2Columns'
import LinkButton from '../../components/buttons/LinkButton'
import firebase from '../../config/firebase'
import ModalWrapper from '../../components/modals'
import { useSession } from '../../App'
import ConfirmationPage from '../confirmation-page/confirmation-page'
import PaymentForm from '../payment-form/payment-form'
import Inputs from '../../components/inputs'

const Login = props => {
  const { setStep, setTab, login} = props
  const history = useHistory()
  const submitLogin = e => {
    e.preventDefault()
    const email = document.getElementById('loginEmail').value
    const password = document.getElementById('loginPassword').value
    login(
      email,
      password,
      ({user, session}) => {
        const { organization = {} } = session
        const { data = {} } = organization
        const { subscriptionStatus = '' } = data
        if ('active' === subscriptionStatus) {
          history.push('/dashboard')
        } else {
          setStep(2)
        }
      }
    )
      /*
    firebase.auth().signInWithEmailAndPassword(email, password)
    .catch(error => {
      const { code, message} = error
      if (code === 'auth/wrong-password') {
        alert('Wrong password')
      } else {
        alert(message)
      }
    })
    */
  }
  return (
    <div>
      <form onSubmit={submitLogin}>
        <h3>Log In</h3>
        <br />
        <Inputs.Wrapper>
          <Inputs.Text
            id='loginEmail'
            type='email'
            placeholder='Enter email...'
            name='email'
            required
          />
        </Inputs.Wrapper>
        <Inputs.Wrapper>
          <Inputs.Password
            id='loginPassword'
            type='password'
            placeholder='Enter password...'
            name='password'
            required
          />
        </Inputs.Wrapper>
        <div className="AppSubmit">
          <DarkAppButton type="submit">Log In</DarkAppButton>
        </div>
      </form>
      <br />
      <p>
        <LinkButton onClick={() => setTab('register')}>Register new account</LinkButton>   |   <LinkButton onClick={() => setTab('passwordReset')}>Forgot password?</LinkButton>
      </p>
    </div>
)}

const submitPasswordReset = e => {
  e.preventDefault()
  const email = document.getElementById('resetPasswordEmail').value
  firebase.auth().sendPasswordResetEmail(email)
    .catch(error => {
      const { message } = error
      alert(message)
    })
}

const PasswordReset = props => (
  <form onSubmit={submitPasswordReset}>
    <h3>Reset Password</h3>
    <br />
    <Inputs.Wrapper>
      <Inputs.Text
        id='resetPasswordEmail'
        type='email'
        placeholder='Enter email...'
        name='email'
        required
      />
    </Inputs.Wrapper>
    <div className="AppSubmit">
      <DarkAppButton type="submit">Reset Password</DarkAppButton>
    </div>
    <br />
    <p><LinkButton onClick={() => props.setTab('register')}>Register new account</LinkButton>   |   <LinkButton onClick={() => props.setTab('login')}>Sign in</LinkButton></p>
  </form>
)

const submitRegistration = (e, setStep, setTab, setConfirmedPayment, setRegistrationError, createUserInFirebaseAuth) => {
  e.preventDefault()

  const email = document.getElementById('registerEmail').value
  const password = document.getElementById('registerPassword').value
  const firstName = document.getElementById('registerFirstName').value
  const lastName = document.getElementById('registerLastName').value

  if (email && password && firstName && lastName) {
    if (password.length < 6) {
      setRegistrationError('Password must be at least six characters')
    } else {
      const callback = () => setStep(2)
      createUserInFirebaseAuth(email, password, firstName, lastName, callback, setRegistrationError)
      setRegistrationError('')
    }
  } else {
    setRegistrationError('Please fill out all fields correctly')
  }
}

const Register = props => (
  <form>
    <h3>Create Account</h3>
    <br />
    <Inputs.Wrapper>
    <Inputs.Text
        id='registerFirstName'
        type='text'
        placeholder='Enter first name...'
        name='firstName'
        required
      />
    </Inputs.Wrapper>
    <Inputs.Wrapper>
      <Inputs.Text
        id='registerLastName'
        type='text'
        placeholder='Enter last name...'
        name='lastName'
        required
      />
    </Inputs.Wrapper>
    <Inputs.Wrapper>
      <Inputs.Text
        id='registerEmail'
        type='email'
        placeholder='Enter email...'
        name='email'
        required
      />
    </Inputs.Wrapper>
    <Inputs.Wrapper>
      <Inputs.Password
        id='registerPassword'
        type='password'
        placeholder='Enter password...'
        name='password'
        required
      />
    </Inputs.Wrapper>
    <Inputs.Wrapper>
      <Inputs.Text
        id='registerOrganization'
        type='text'
        placeholder='Enter organization...'
        name='organization'
        required
      />
    </Inputs.Wrapper>
    <div className="AppSubmit">
      <DarkAppButton type="submit" onClick={(e) => submitRegistration(e, props.setStep, props.setTab, props.setConfirmedPayment, props.setRegistrationError, props.createUserInFirebaseAuth)}>Register</DarkAppButton>
    </div>
    <br />
    <p>Have an account? <LinkButton onClick={() => props.setTab('login')}>Sign in</LinkButton></p>
  </form>
)

const MembershipSelection = () => (
  <div>
    <h2>Legal Thunder Pro</h2>
    <br />
    <h3>$XX / month</h3>
    <p>Billed semi-annually</p>
    <h3>Features</h3>
    <ul>
      <li>15 active cases</li>
      <li>Child Support Calculator</li>
      <li>Child Support Interest Calculator</li>
      <li>Spousal Maintenance Interest Calculator</li>
    </ul>
    <h3>Coming Soon</h3>
    <ul>
      <li>Maintenance Buy-out Calculator</li>
      <li>Judgment Interest Calculator</li>
      <li>Calculations Export</li>
    </ul>
  </div>
)

const SignOutPrompt = () => {
  const { user, actions } = useSession()
  const { displayName = 'Guest' } = user || {}
  const { signOut } = actions
  return (
    <div>
      <p>Hello {displayName}, your account is setup.</p>
      <p><LinkButton
          onClick={signOut}>Not {displayName}? Sign out.</LinkButton></p>
      <br />
    </div>
  )
}

const Registration = () => {
  const { user, session, actions } = useSession()
  const { createUserInFirebaseAuth, login, signOut } = actions
  const loggedIn = !!user
  const [tab, setTab] = useState('register')
  const [step, setStep] = useState(1)
  const [paymentConfirmed, setConfirmedPayment] = useState(false)
  const [error, setRegistrationError] = useState(null)
  const [existingCustomer, setExistingCustomer] = useState(false)

  console.log(' Register step ', step)
  return (
    <main>
      {existingCustomer && <Redirect to='/dashboard' />}
      {tab !== 'dashboard' && <Header version='minimal'/>}
      <section>
        <ContentBlock>
          {!paymentConfirmed && (
            <Grid2Columns>
              {/*left col */}
              <MembershipSelection />

              {/*right col */}
              <div>
                <div>
                  <h2>Step 1: Setup Account</h2>
                  <br />
                </div>
                {loggedIn ? <SignOutPrompt /> : (
                  <div>
                    {'register' === tab && !paymentConfirmed && <Register {...{setTab, setStep, createUserInFirebaseAuth, setConfirmedPayment, setRegistrationError}}/>}
                    {'login' === tab && !paymentConfirmed && <Login {...{setStep, setTab, login}}/>}
                    {'passwordReset' === tab && !paymentConfirmed && <PasswordReset {...{setTab}}/>}
                  </div>
                )}
                <div>
                  <h2>Step 2: Enter Payment Details</h2>
                  <br />
                  <br />
                </div>
                {loggedIn && (
                  <div>
                    <PaymentForm {...{setTab, setRegistrationError, setConfirmedPayment}}/>
                  </div>
                )}
              </div>
            </Grid2Columns>
          )}
          {paymentConfirmed && tab !== 'dashboard' && <ConfirmationPage />}
        </ContentBlock>
      </section>
      {error && (
        <ModalWrapper close={() => setRegistrationError(null)}>
          <h2>{error}</h2>
        </ModalWrapper>
      )}
    </main>
  )
}

export default Registration
