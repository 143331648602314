import React from 'react'
import Dashboard from '../dashboard'
import Registration from '../registration'
import { useSession } from '../../App'

const Home = () => {
  const { user, session } = useSession()
  const { organization = {} } = session ? session : {}
  const { data: orgData = {} } = organization
  const { subscriptionStatus = null } = orgData
  const activeSubscriber = 'active' === subscriptionStatus
  return  (!user || !activeSubscriber) ? <Registration /> : <Dashboard />
}

export default Home
