import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  display: inline-block;
  color: ${props => props.dark ? props.theme.colors.solitude : props.theme.colors.hanPurple};
  text-align: left;
  text-decoration: underline;
  text-transform: none;
  letter-spacing: inherit;
  font-size: ${props => props.small ? '10px' : 'inherit'};
  font-weight: 300;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`

const LinkButton = props => (
  <Button {...props}>{props.children}</Button>
)

export default LinkButton
