import React from 'react'
import styled from 'styled-components'

const OptionButton = styled.button`
  display: block;
  width: 100%;
  font-size: 14px;
  text-align: left;
  color: ${props => props.theme.colors.gunmetal};
  padding: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.zumthor};
  margin-bottom: 9px;
  background-color: ${props => props.theme.colors.solitude};
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.colors.zumthor};
  }
`

export default OptionButton
