import { useEffect, useReducer } from 'react'
import { addCaseDocument } from '../containers/APICalls/APICalls'
import { fetchWithAuth } from './useAuth'
import { bindUpdateCaseProfile } from './useCases'
import simpleReducer from '../helpers/simpleReducer'

const initialState = {
  loading: false,
  status: 'initialize',
  profile: {},
  documents: [],
  error: null
}

function useCase(id) {
  const [state, dispatch] = useReducer(simpleReducer, initialState)
  const { loading, status } = state
  
  const deleteCaseDoc = () => console.log('implementation pending')
  useEffect(
    () => {
      if (!loading && 'initialize' === status) {
        dispatch({payload: {loading: true, status: 'gettingcase'}})
        fetchWithAuth(`/api/v1/my/cases/${id}`)
          .then(caseDetails => {
            const { profile: newProfile, documents: newDocuments } = caseDetails
            dispatch({
              payload: {
                status: 'loaded',
                loading: false,
                profile: newProfile,
                documents: newDocuments
              }
            })
          })
          .catch( error => {
            dispatch({
              payload: {
                status: 'error',
                error
              }
            })
          })
      }
    },
    [id, loading, status]
  )
  const actions = {
    addCaseDocument,
    deleteCaseDoc,
    updateCaseProfile: bindUpdateCaseProfile(dispatch)
  }
  return Object.assign({}, state , {
    addCaseDocument,
    deleteCaseDoc,
    actions
  })
}

export default useCase
