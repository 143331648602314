import { constructPDFObject, constructPDFData } from "./helpers/index";


export const downloadChildSupportWorksheet = (worksheetData) => {

  let pdfMake = constructPDFObject();

  let pdfData = constructPDFData(worksheetData);

  pdfMake.createPdf(pdfData).download()
}

export const printChildSupportWorksheet = (worksheetData) => {

  let pdfMake = constructPDFObject();

  let pdfData = constructPDFData(worksheetData);

  pdfMake.createPdf(pdfData).print()

}

export const previewChildSupportWorksheet = (worksheetData) => {

  let pdfMake = constructPDFObject();

  let pdfData = constructPDFData(worksheetData);

  pdfMake.createPdf(pdfData).open()
}
