import { CompoundingModels } from '../constants/interest-calculator-settings'
import moment from 'moment'
// Compound Interest Calculation formula
// A = P * ( (1 + r /n ) ^ nt ) - P
// A : interestDueAmount
// P : principal (currentBalance)
// r : interestRate
// n : numberOfTimesInterestIsCompoundedPerYear
// t : number of years amount is accruing interest
// nt : compounding periods
export const calculateCompoundInterest = (principal, daysOfInterest, interestRate, numberOfTimesInterestIsCompoundedPerYear) => {
  const daysInYear = 365.25
  const compoundingPeriods = daysOfInterest * numberOfTimesInterestIsCompoundedPerYear / daysInYear
  return principal * Math.pow(1 + interestRate / numberOfTimesInterestIsCompoundedPerYear, compoundingPeriods) - principal
}

export const dynamicChildSupportInterest = date => {
  const cutoffDate = moment('2021-07-01')
  const interestSettings = date.isBefore(cutoffDate) ? {
    interestRate: 0.12,
    compoundingModel: CompoundingModels.MONTHLY
  } : {
    interestRate: 0.1,
    compoundingModel: CompoundingModels.ANNUALLY
  }
  return interestSettings
}

