import firebase from '../config/firebase'
import { useState, useEffect, useReducer } from 'react'

const initialState = {
  status: 'initializing',
  user: null,
  session: null,
  callback: null
}

const reducer = (state, action) => {
  // console.log( ' - action - ', action)
  const { payload } = action
  const newState = Object.assign({}, state, payload)
  // console.log( ' - newState - ', newState)
  return newState
}

function useAuth() {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { status, user, session, callback } = state

  const login = (email, password, newCallback) => {
    dispatch({
      type: 'login',
      payload: {
        status: 'loggingIn',
        callback: newCallback
      }
    })
    firebase.auth().signInWithEmailAndPassword(email, password)
      .catch(error => {
        dispatch({
          type: 'error',
          payload: {
            status: 'error',
            callback: null
          }
        })
      })
  }
  const createUserInFirebaseAuth = (email, password, firstName, lastName, newCallback, setRegistrationError) => {
    dispatch({
      type: 'register',
      payload: {
        status: 'registering',
        profile: { firstName, lastName },
        callback: newCallback
      }
    })
    firebase.auth().createUserWithEmailAndPassword(email, password)
      .catch(error => {
        setRegistrationError(error.message)
        console.log(error)
      })
  }
  const onChange = onChangeUser => {
    // console.log(' - onChange user -', Date.now(), status, onChangeUser)
    if (onChangeUser) {
      if ('initializing' === status || 'loggedOut' === status || 'loggingIn' === status) {
        fetchWithAuth('/api/v1/my/session')
          .then(session => {
            dispatch({
              type: 'session',
              payload: {
                status: 'loggedIn',
                user: onChangeUser,
                session: session
              }
            })
            if (callback) callback({user: onChangeUser, session})
            //setState({ initializing: false, user, session })
          })
          .catch(error => {
            console.log(error)
            dispatch({
              type: 'error',
              payload: {
                status: 'error',
                user: onChangeUser,
                session: null
              }
            })
            //const { session } = state
            //setState({ initializing: false, user, session })
          })
      } else if ('registering' === status) {
          onChangeUser.updateProfile({
            displayName: state.profile.firstName
          })
            .then(() => {
              const options = {
                method: 'POST',
                body: JSON.stringify({
                  email: onChangeUser.email,
                  firstName: state.profile.firstName,
                  lastName: state.profile.lastName
                })
              }
              fetchWithAuth('/api/v1/users', options)
                .then(newSession => {
                  dispatch({
                    type: 'registered',
                    payload: {
                      status: 'loggedIn',
                      user: onChangeUser,
                      session: newSession
                    }
                  })
                  if (callback) callback()
                })
                .catch(error => {
                })
              })
            .catch(error => console.log(`error in useAuth while registering: ${error}`))
      }
    } else if ('registering' !== status) {
      dispatch({
        type: 'logout',
        payload: {
          status: 'loggedOut',
          user: null,
          session: null
        }
      })
    }
  }
  const signOut = () => firebase.auth().signOut()
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(onChange)
    return () => unsubscribe()
  }, [status])

  return {
    ...{state},
    actions: {
      createUserInFirebaseAuth,
      login,
      signOut
    }
  }
}

export const headersTemplate = token => (
  {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
)

export const fetchWithAuth = (endpoint, options = {}) => {
  return new Promise((resolve, reject) => {
    if (firebase.auth().currentUser) {
      firebase.auth().currentUser.getIdToken(true)
        .then(token => {
          const optionsWithAuth = Object.assign(
            {},
            options,
            { headers: headersTemplate(token) }
          )
          fetch(endpoint, optionsWithAuth)
            .then(res => resolve(res.json()))
            .catch(reject)
        })
      .catch(reject)
    } else {
      reject(new Error('Not authorized'))
    }
  })
}
export default useAuth
