import React, { Component } from 'react'
import './CTAButtonGroup.css'
import AppButton, { DarkAppButton } from '../buttons/AppButton'

const CTAButtonGroup = props => (
    <div className="CTAButtonGroup">
      {props["cta-buttons"].map((ctaButton, ind) => ctaButton.dark ? (
        <DarkAppButton
          key={ind}
          children={ctaButton.text}
          onClick={ctaButton.callback}
          payload={ctaButton.payload}/>
      ) : (
        <AppButton
          key={ind}
          children={ctaButton.text}
          onClick={ctaButton.callback}
          payload={ctaButton.payload}/>
      ))}
    </div>
)

export default CTAButtonGroup
