import React from 'react'
import './Grid4Columns.css'

function Grid4Columns (props) {
  return (
    <div className="Grid4Columns">{props.children}</div>
  )
}

export default Grid4Columns
