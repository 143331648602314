import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import styled from 'styled-components'
import firebase from '../../config/firebase'
import Header from '../../components/Header'
import ContentBlock from '../../components/ContentBlock'
import { useSession } from '../../App'
import Grid2Columns from '../../components/layouts/Grid2Columns'
import AppButton, { DarkAppButton } from '../../components/buttons/AppButton'
import PaymentForm from '../payment-form/payment-form'

const SignOutButton = styled(AppButton)`
  position: absolute;
  right: 0;
  top: 0;
`

const Account = () => {
  const demoMode = false
  const history = useHistory()
  const {
    actions,
    session = {}
  } = useSession()
  const { organization = {} } = session
  const { data: organizationData } = organization
  const { subscriptionStatus = 'N/A' } = organizationData
  const { signOut } = actions

  const onSignOutClick = () => {
    if (demoMode) {
      alert('Demo only')
      history.push('/')
    } else {
      signOut().then(() => history.push('/'))
    }
  }

  return (
    <main>
      <Header />
      <ContentBlock>
        <h2>Account</h2>
        <p><Link to='/account/attorney-profiles'>Attorney Profile(s)</Link></p>
        <h3>Billing</h3>
        <p>Your subscription status is <strong>{subscriptionStatus}</strong>.</p>
        <p><Link to='/account/billing-history'>Payment history</Link></p>
        <p><Link to='/account/update-payment-method'>Update payment method</Link></p>
        <SignOutButton onClick={onSignOutClick}>Sign Out</SignOutButton>
      </ContentBlock>
    </main>
  )
}
export default Account
