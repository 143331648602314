import React from 'react'
import styled from 'styled-components'

const SubmitWrapper = styled.div`
    width: 100%;
    display: inline-block;
    padding: 15px;
`
SubmitWrapper.displayName = 'SubmitWrapper'

export default SubmitWrapper