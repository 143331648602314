import React, { useState } from 'react'
import styled from 'styled-components'
import ModalWrapper from '../modals/ModalWrapper'
import LinkButton from '../buttons/LinkButton'

const Dev = () => {
  const [showEnv, setShowEnv] = useState(false)
  return (
    <Wrapper>
      <LinkButton dark small onClick={() => setShowEnv(true)}>Developer Info</LinkButton>
      {showEnv && (
        <ModalWrapper close={() => setShowEnv(false)}>
          <div><Pre>{JSON.stringify(process.env).split(',').join('\n')}</Pre></div>
        </ModalWrapper>
      )}
    </Wrapper>
  )
}
const Wrapper = styled.div`
  margin: 35px 20px;
  padding: 10px 20px;
`
const Pre = styled.pre`
  color: ${props => props.theme.colors.gunmetal};
`
export default Dev
