import React, { useState } from 'react'
import styled from 'styled-components'
import { useSession } from '../../App'
import ConfirmationButton from '../confirmation-button/confirmation-button'
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { CARD_NUMBER_ELEMENT_OPTIONS, CARD_CVC_ELEMENT_OPTIONS, CARD_EXPIRY_ELEMENT_OPTIONS } from '../../theme/stripeStyles'
import { createSubscription, validatePromoCode } from '../APICalls/APICalls'
import Inputs from '../../components/inputs'
import { PromoCodeAppButton } from '../../components/buttons/AppButton'

const PromoCodeErrorMessage = styled.h3`
  color: red;
  font-size: 15px;
  font-weight: bold;
`

const SubscriptionPriceWrapper = styled.h3`
  font-size: 15px;
  font-weight: bold;
`

const PaymentForm = ({ setTab = null, setRegistrationError = null, setAccountError = null, setConfirmedPayment = null, setIsUpdatingPayment = null }) => {
  const { session } = useSession()
  const { organization = {} } = session ? session : {}
  const { data: orgData = {} } = organization
  const { stripeCustomerId = null } = orgData

  const [price, setPrice] = useState('30.00')
  const [promoCodeError, setPromoCodeError] = useState('')
  const [validPromoCodeUsed, setValidPromoCodeUsed] = useState(false)

  const stripe = useStripe()
  const elements = useElements()
  
  if (!stripe || !elements) {
    return
  }
  
  const priceId = 'price_1HKRlIJPqfsrLoZaOjgmWjuL'
  
  const applyOrRemoveDiscount = (e) => {
    e.preventDefault()
    if (!document.getElementById('paymentDiscountCode') || !document.getElementById('paymentDiscountCode').value) {
      return null
    } else {
      const stripePromoCode = document.getElementById('paymentDiscountCode').value.toLowerCase()
      validatePromoCode(stripePromoCode, priceId)
        .then(res => {
          if (res.updatedTotal) {
            setPromoCodeError('')
            setPrice(res.updatedTotal)
            setValidPromoCodeUsed(true)
          } else {
            setPromoCodeError(res.error)
          }
        })
    }
  }
  
  const submitPaymentDetails = async (e) => {
    e.preventDefault()
    const cardNumber = elements.getElement(CardNumberElement)
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumber
    })
    
    
		if (error && setAccountError) {
      setAccountError(error.message)
		} else if (error && setRegistrationError) {
      setRegistrationError(error.message)
		} else {
      const paymentMethodId = paymentMethod.id
      const stripePromoCode = document.getElementById('paymentDiscountCode').value

      // Eventually need to add function to take a user's input and dynamically
			// pair it with the priceId for the subscription they want instead of hard-coding
			
      
			if (setConfirmedPayment) {
        console.log(session)
        if (!session.organization.stripeCustomerId) {
          createSubscription(session.organization.data.stripeCustomerId, paymentMethodId, priceId, stripePromoCode)
            .then(createSubscriptionResponse => {
              setRegistrationError('')
              // if (createSubscriptionResponse.status === 401) {
                  //   setTimeout(() => {
                  //     setRegistrationError('Your session has expired, you must log back in')
                  //   }, 5000)
                  //   setConfirmedPayment(false)
                  //   setTab('login')
                  // }
            })
            .catch(error => {
              setRegistrationError(`There was an error: ${error}`)
            })
				  setConfirmedPayment(true)
        } else {
          createSubscription(session.organization.stripeCustomerId, paymentMethodId, priceId, stripePromoCode)
            .then(createSubscriptionResponse => {
              setRegistrationError('')
              // if (createSubscriptionResponse.status === 401) {
                  //   setTimeout(() => {
                  //     setRegistrationError('Your session has expired, you must log back in')
                  //   }, 5000)
                  //   setConfirmedPayment(false)
                  //   setTab('login')
                  // }
            })
            .catch(error => {
              setRegistrationError(`There was an error: ${error}`)
            })
				  setConfirmedPayment(true)

        }
			}
			
			if (setIsUpdatingPayment) {	
        console.log(' - unsupported - ')
        /*
				if (document.getElementById('zipCode').value) {
          updatePaymentMethod(stripeCustomerId, paymentMethodId)
            .then(res => {
              setAccountError('')
            })
            .catch(error => {
              console.log(error)
              setRegistrationError(`There was an error: ${error}`)
            })
					setIsUpdatingPayment(false)
				}
        */
			}
		}	
	}
	
	// Stripe suggests getting a user's zip code for further security but currently
	// the #zipCode input below is not hooked up to Stripe for any type of verification
  return (
		<form onSubmit={submitPaymentDetails} className='submitPaymentInfoForm'>
			<h3>Payment Details</h3>
      <SubscriptionPriceWrapper>Total: ${ price }</SubscriptionPriceWrapper>
      <PromoCodeErrorMessage>{ promoCodeError }</PromoCodeErrorMessage>
			<br />
			<Inputs.Stripe>
				<CardNumberElement options={CARD_NUMBER_ELEMENT_OPTIONS}/>
			</Inputs.Stripe>
			<Inputs.Stripe>
				<CardExpiryElement options={CARD_EXPIRY_ELEMENT_OPTIONS}/>
			</Inputs.Stripe>
			<Inputs.Stripe>
				<CardCvcElement options={CARD_CVC_ELEMENT_OPTIONS}/>
			</Inputs.Stripe>
			<Inputs.Wrapper>
				<Inputs.Text
					id='zipCode'
					type='text'
					placeholder='Enter Zip Code...'
					name='zipCode'
					required
					/>
			</Inputs.Wrapper>
      <Inputs.PromoCodeWrapper>
        <Inputs.Text
          id='paymentDiscountCode'
          type='text'
          placeholder='Enter Discount Code...'
          name='paymentDiscountCode'
        />
        <PromoCodeAppButton onClick={(e) => applyOrRemoveDiscount(e)}>
          {validPromoCodeUsed ? 'Remove Promo Code' : 'Apply Promo Code'}
        </PromoCodeAppButton>
      </Inputs.PromoCodeWrapper>
      <ConfirmationButton stripe={stripe} />
		</form>
  )
}

export default PaymentForm
