import React, { useReducer } from 'react'
import simpleReducer from '../../helpers/simpleReducer'
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js'

import Loader from '../Loader'
import Header from '../Header'
import ContentBlock from '../ContentBlock'
import Inputs from '../inputs'
import { DarkAppButton } from  '../buttons/AppButton'
import PaymentForm from '../../containers/payment-form/payment-form'
import { fetchWithAuth } from '../../hooks/useAuth'
import { CARD_NUMBER_ELEMENT_OPTIONS, CARD_CVC_ELEMENT_OPTIONS, CARD_EXPIRY_ELEMENT_OPTIONS } from '../../theme/stripeStyles'


const initialState = {
  loading: false,
  errorMessage: null,
  success: null
}
const UpdatePaymentMethod = () => {
  const [state, dispatch] = useReducer(simpleReducer, initialState)
  const {
    loading,
    errorMessage,
    success
  } = state
  const stripe = useStripe()
  const elements = useElements()
  const submitPaymentDetails = ev => {
    ev.preventDefault()
    dispatch({payload: { loading: true }})
    const cardNumber = elements.getElement(CardNumberElement)
    stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber
    }).then(({error, paymentMethod }) => {
      if (error) {
        const { message } = error
        dispatch({ payload: {
          loading: false,
          errorMessage: message
        } })
      } else {
        console.log(' - createPaymentMethod then -', error, paymentMethod)
        const { id: stripePaymentMethodId } = paymentMethod
        const options = {
          method: 'PATCH',
          body: JSON.stringify({ stripePaymentMethodId })
        }
        fetchWithAuth('/api/v1/my/payment-method', options)
          .then(res => {
            const { success } = res
            dispatch({ payload: {
              loading: false,
              success
            }})
          })
          .catch(err => {
            dispatch({ payload: {
              loading: false,
              errorMessage: err.message
            } })
          })
      }
    })

    // fetchWithAuth
  }
  if (success) return (
    <main>
      <Header />
      <ContentBlock>
        <h2>Update payment method</h2>
        <p>{success}</p>
      </ContentBlock>
    </main>
  )
  return (
    <main>
      {loading && <Loader />}
      <Header />
      <ContentBlock>
        <h2>Update payment method</h2>
        <form onSubmit={submitPaymentDetails}>
          <Inputs.Stripe>
            <CardNumberElement options={CARD_NUMBER_ELEMENT_OPTIONS}/>
          </Inputs.Stripe>
          <Inputs.Stripe>
            <CardExpiryElement options={CARD_EXPIRY_ELEMENT_OPTIONS}/>
          </Inputs.Stripe>
          <Inputs.Stripe>
            <CardCvcElement options={CARD_CVC_ELEMENT_OPTIONS}/>
          </Inputs.Stripe>
          <Inputs.Wrapper>
            <DarkAppButton type='submit'>Submit</DarkAppButton>
          </Inputs.Wrapper>
        </form>
      </ContentBlock>
    </main>
  )
}

export default UpdatePaymentMethod
