import { css, keyframes } from 'styled-components'

// color names from https://www.color-blindness.com/color-name-hue/
const colors = {
  // monochrome / greys
  white: '#FFFFFF',
  solitude: '#F6F7F8',
  zircon: '#EEF0F1',
  zumthor: '#D6D9DD',
  casper: '#ADB4B9',
  lightSlateGrey: '#838F98',
  blueBayoux: '#5A6A76',
  tuna: '#484E59',
  gunmetal: '#30393D',

  // oranges
  sun: '#EE7A30',

  // pinks
  darkPink: '#EA368A',

  // purples
  hanPurple: '#7A2CF5'
}

const radioLabel = css`
  display: block;
  position: relative;
  user-select: none;
  cursor: pointer;
  padding: 5px 10px 5px 35px;
  margin: 0;
`
const gradients = {
  'sun-darkPink-hanPurple gradient': 'linear-gradient(to right, #EE7A30, #EA368A, #7A2CF5)',
  'sun-darkPink gradient': 'linear-gradient(to right, #EE7A30, #EA368A)',
  'darkPink-hanPurple gradient': 'linear-gradient(to right, #EA368A, #7A2CF5)',
  'grey-dark grey gradient': 'linear-gradient(to right, #5A6A76, #30393D)',
}

// snippets

const inputs = css`
  display: block;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  border: 1px solid ${colors.zircon};
  margin: 0 0 18px;
  padding: 15px;
`

const stripeInputs = css`
  border: 1px solid ${colors.zircon};
  margin-bottom: 15px;
  padding: 16px;
`

// speeds
const fast = '150ms'
const med = '550ms'
const slow = '850ms'

// keyframes

const fadeInSlideUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(8px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`

const theme = {
  colors,
  gradients,
  snippets: {
    inputs,
    stripeInputs
  },
  speeds: {
    fast,
    med,
    slow
  },
  keyframes: {
    fadeInSlideUp
  }
}

export default theme

