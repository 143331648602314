import React, { Component } from 'react'
import './ArticlePreview.css'
import PlaceholderImage from '../placeholders/image'

class ArticlePreview extends Component {
  render () {
    const {
      url = false,
      image = <PlaceholderImage />
    } = this.props
    return (
      <aside className="ArticlePreview">
        <div className="ArticlePreview-image">
          {!url && image}
          {url && <a href={url}>{image}</a>}
        </div>
        <div className="ArticlePreview-content">
          <p className="ArticlePreview-category">{this.props.category}</p>
          {!url && <h3 className="ArticlePreview-title">{this.props.title}</h3>}
          {url && <h3 className="ArticlePreview-title"><a href={url}>{this.props.title}</a></h3>}
          <p>{this.props.excerpt}</p>
        </div>
      </aside>
    )
  }
}
export default ArticlePreview

