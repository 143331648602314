import React, { useState } from 'react'
import Grid3Columns from '../layouts/Grid3Columns'
import * as AppUI from '../app'
import * as UI from './styled-components'

const weekdayLabels = [
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
  'Sun'
]

const OvernightsCalculator = props => {
  const [consistency, setConsistency] = useState('YEAR_ROUND')
  return (
    <UI.BodySection>

      <UI.BodySectionTitle>Overnights Calculator</UI.BodySectionTitle>

      <AppUI.Input>
        <label>Consistency of Overnights Schedule</label>
        <AppUI.RadioLabel>Overnights schedule is consistent year-round
          <input
            name="consistency"
            value="YEAR_ROUND"
            type="radio"
            checked={'YEAR_ROUND' === consistency}
            onChange={e => {
              setConsistency(e.target.value)
            }}/>
          <AppUI.RadioCheckmark className="app-checkmark" />
        </AppUI.RadioLabel>
        <AppUI.RadioLabel>Summer and school year overnights schedules are different
          <input
            name="consistency"
            value="SUMMER"
            type="radio"
            checked={'SUMMER' === consistency}
            onChange={e => {
              setConsistency(e.target.value)
            }}/>
          <AppUI.RadioCheckmark className="app-checkmark" />
        </AppUI.RadioLabel>
      </AppUI.Input>

      <UI.BodyInstructions>Add overnights for each type of schedule that applies. All days selected will be added together and displayed in the TOTAL below.</UI.BodyInstructions>

      <UI.WeekdaySelector>
        <UI.WeekdaySelectorHeader>
          <p>Add monthly overnights</p>
          <p>For example, every third Monday and every Friday &amp; Saturday</p>
        </UI.WeekdaySelectorHeader>
        <UI.WeekdaySelectorBody>
          {weekdayLabels.map((label, ind) => (
            <UI.WeekdaySelectorOption key={ind}>{label.slice(0,1)}</UI.WeekdaySelectorOption>
           ))}
          {weekdayLabels.map((label, ind) => (
            <UI.WeekdaySelectorOption key={ind}>{label.slice(0,1)}</UI.WeekdaySelectorOption>
           ))}
          {weekdayLabels.map((label, ind) => (
            <UI.WeekdaySelectorOption key={ind}>{label.slice(0,1)}</UI.WeekdaySelectorOption>
           ))}
          {weekdayLabels.map((label, ind) => (
            <UI.WeekdaySelectorOption key={ind}>{label.slice(0,1)}</UI.WeekdaySelectorOption>
           ))}
         </UI.WeekdaySelectorBody>
      </UI.WeekdaySelector>

      <br />

      <Grid3Columns>
        <UI.CalculatorTotal>
          <UI.CalculatorLabel>&gt; Total Overnights</UI.CalculatorLabel>
          <UI.CalculatorValue>156</UI.CalculatorValue>
        </UI.CalculatorTotal>
      </Grid3Columns>

    </UI.BodySection>
  )
}

export default OvernightsCalculator
