import React, { useState, useReducer } from 'react'
import * as Layout from '../layouts/ContentWithSidebarLeft'
import { presentDollarAmount, presentDecimalAsPercentage } from '../../helpers/presenters'
import AppButton from '../buttons/AppButton'

const PARTY_1 = 'PARTY_1'
const PARTY_2 = 'PARTY_2'
const {
    MainCol1,
    AsideActions,
    AsideTitle,
    AsideSticky,
    AsideSection,
    AsideSummary,
    SummaryLabel,
    SummaryValue
} = Layout

const SummaryPanel = props => {

    const {
        numberOfChildren,
        party1,
        party2,
        party1Label,
        overnightsParty1,
        party2Label,
        overnightsParty2,
        spousalMaintenanceEntryMethod,
        calculatedSpousalSupport,
        directSpousalSupport,
        party1MonthlyAdjustedGross,
        party2MonthlyAdjustedGross,
        party1ShareOfIncome,
        party2ShareOfIncome,
        payor,
        recommendedChildSupportOrder,
        previewChildSupportWorksheet,
        pdfExportData,
        state,
        caseProfile,
        attorneyProfiles,
        updateCaseDoc
    } = props
    return (
        <MainCol1>
            <AsideSticky>
                <AsideSection>
                    <AsideSummary>
                        <SummaryLabel><strong>Children</strong></SummaryLabel>
                        <SummaryValue>{numberOfChildren}</SummaryValue>
                    </AsideSummary>
                </AsideSection>

                <AsideSection>
                    <AsideTitle><strong>Overnights</strong></AsideTitle>
                    <AsideSummary>
                        <SummaryLabel>{party1Label}</SummaryLabel>
                        <SummaryValue>{overnightsParty1}</SummaryValue>
                    </AsideSummary>
                    <AsideSummary>
                        <SummaryLabel>{party2Label}</SummaryLabel>
                        <SummaryValue>{overnightsParty2}</SummaryValue>
                    </AsideSummary>
                </AsideSection>

                <AsideSection>
                    <AsideTitle><strong>Monthly Gross Income</strong></AsideTitle>
                    <AsideSummary>
                        <SummaryLabel>{party1Label}</SummaryLabel>
                        <SummaryValue>
                        {party1.monthlyGross && presentDollarAmount(party1.monthlyGross)}
                        </SummaryValue>
                    </AsideSummary>
                    <AsideSummary>
                        <SummaryLabel>{party2Label}</SummaryLabel>
                        <SummaryValue>
                        {party2.monthlyGross && presentDollarAmount(party2.monthlyGross)}
                        </SummaryValue>
                    </AsideSummary>
                </AsideSection>

                {'CALCULATOR' === spousalMaintenanceEntryMethod && !!calculatedSpousalSupport.payor && (
                    <AsideSection>
                        <AsideTitle><strong>Monthly Maintenance</strong></AsideTitle>
                        {calculatedSpousalSupport.payor === PARTY_1 && (
                        <AsideSummary>
                            <SummaryLabel><em>{party1Label}</em> pays <em>{party2Label}</em></SummaryLabel>
                            <SummaryValue>{presentDollarAmount(calculatedSpousalSupport.maintenance)}</SummaryValue>
                        </AsideSummary>
                        )}
                        {calculatedSpousalSupport.payor === PARTY_2 && (
                        <AsideSummary>
                            <SummaryLabel><em>{party2Label}</em> pays <em>{party1Label}</em></SummaryLabel>
                            <SummaryValue>{presentDollarAmount(calculatedSpousalSupport.maintenance)}</SummaryValue>
                        </AsideSummary>
                        )}
                    </AsideSection>
                    )}

                {'DIRECT' === spousalMaintenanceEntryMethod &&
                    !!directSpousalSupport.maintenance &&
                    directSpousalSupport.maintenance > 0 && (
                    <AsideSection>
                        <AsideTitle><strong>Monthly Maintenance</strong></AsideTitle>
                        {directSpousalSupport.payor === PARTY_1 && (
                        <AsideSummary>
                            <SummaryLabel><em>{party1Label}</em> pays <em>{party2Label}</em></SummaryLabel>
                            <SummaryValue>{presentDollarAmount(directSpousalSupport.maintenance)}</SummaryValue>
                        </AsideSummary>
                        )}
                        {directSpousalSupport.payor === PARTY_2 && (
                        <AsideSummary>
                            <SummaryLabel><em>{party2Label}</em> pays <em>{party1Label}</em></SummaryLabel>
                            <SummaryValue>{presentDollarAmount(directSpousalSupport.maintenance)}</SummaryValue>
                        </AsideSummary>
                        )}
                    </AsideSection>
                )}

                <AsideSection>
                    <AsideTitle><strong>Monthly Adjusted Gross Income</strong></AsideTitle>
                    <AsideSummary>
                        <SummaryLabel>{party1Label}</SummaryLabel>
                        <SummaryValue>
                        {party1MonthlyAdjustedGross && presentDollarAmount(party1MonthlyAdjustedGross)}
                        </SummaryValue>
                    </AsideSummary>
                    <AsideSummary>
                        <SummaryLabel>{party2Label}</SummaryLabel>
                        <SummaryValue>
                        {party2MonthlyAdjustedGross && presentDollarAmount(party2MonthlyAdjustedGross)}
                        </SummaryValue>
                    </AsideSummary>
                </AsideSection>

                <AsideSection>
                    <AsideTitle><strong>Share of Income</strong></AsideTitle>
                    <AsideSummary>
                        <SummaryLabel>{party1Label}</SummaryLabel>
                        <SummaryValue>
                        {presentDecimalAsPercentage(party1ShareOfIncome, 1)}
                        </SummaryValue>
                    </AsideSummary>
                    <AsideSummary>
                        <SummaryLabel>{party2Label}</SummaryLabel>
                        <SummaryValue>
                        {presentDecimalAsPercentage(party2ShareOfIncome, 1)}
                        </SummaryValue>
                    </AsideSummary>
                    </AsideSection>

                    <AsideSection>
                    <AsideTitle>
                        <strong>Recommended Support Order</strong>
                    </AsideTitle>
                        {payor === PARTY_1 &&
                        <AsideSummary>
                            <SummaryLabel><em>{party1Label}</em> pays <em>{party2Label}</em></SummaryLabel>
                            <SummaryValue>{false !== recommendedChildSupportOrder[PARTY_1] && presentDollarAmount(recommendedChildSupportOrder[PARTY_1])}</SummaryValue>
                        </AsideSummary>
                        }
                        {payor === PARTY_2 &&
                        <AsideSummary>
                            <SummaryLabel><em>{party2Label}</em> pays <em>{party1Label}</em></SummaryLabel>
                            <SummaryValue>{false !== recommendedChildSupportOrder[PARTY_2] && presentDollarAmount(recommendedChildSupportOrder[PARTY_2])}</SummaryValue>
                        </AsideSummary>
                        }
                    </AsideSection>

                    <AsideActions>
                    <AppButton onClick={() => {
                        try {
                        previewChildSupportWorksheet(pdfExportData(state, party1Label, party2Label, caseProfile, attorneyProfiles))
                        } catch (e) { alert(e) }
                    }}>Export</AppButton>
                    <AppButton onClick={e => {
                        e.preventDefault()
                        updateCaseDoc(state)
                    }}>Save</AppButton>
                    </AsideActions>

            </AsideSticky>
        </MainCol1>
    )
}

export default SummaryPanel