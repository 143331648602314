import React from 'react'
import styled from 'styled-components'

const RadioButtonCheckmark = props => (
  <StyledRadioCheckmarkSpan {...props} type="text" />
)
const StyledRadioCheckmarkSpan = styled.span`
${props => props.theme.snippets.radioCheckmark}
`

RadioButtonCheckmark.displayName = 'RadioButtonCheckmark'

export default RadioButtonCheckmark