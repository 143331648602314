import React from 'react'
import styled, { keyframes } from 'styled-components'
import { grey100, orange, pink/*, purple*/ } from '../../constants/colors'

//const colors = [grey100, orange, pink, purple]
const circleWidth = 105
const timing = 375
//const randomColor = () => colors[Math.floor(colors.length * Math.random())]
const spreadOut = keyframes`
  0% {
    opacity: 0;
    top: 50vh;
    left: 50vw;
    width: 0;
    height: 0;
  }
  90% {
    opacity: 1;
    top: calc(50vh - ${circleWidth/2}px);
    left: calc(50vw - ${circleWidth/2}px);
    width: ${circleWidth}px;
    height: ${circleWidth}px;
  }
  100% {
    opacity: 0;
    top: 50vh;
    left: 50vw;
    width: 0;
    height: 0;
    top: calc(50vh - ${circleWidth/2}px);
    left: calc(50vw - ${circleWidth/2}px);
    width: ${circleWidth}px;
    height: ${circleWidth}px;
  }
`
const StyledLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 999999;
`
const CircleSpreading = styled.div`
  animation: ${spreadOut} ${timing*4}ms ease-in infinite;
  position: fixed;
  top: -100vh;
  width: 200px;
  height: 200px;
  border-width: 2px;
  border-style: solid;
  border-color: ${pink};
  border-radius: 100%;
`
//border-image: linear-gradient(to bottom right, ${orange}, ${pink}, ${purple});
//border-image-slice: 1;
const CircleSpreadingVariation = styled(CircleSpreading)`
  border-color: ${grey100};
  animation-delay: ${timing}ms;
`
const CircleSpreadingVariation2 = styled(CircleSpreading)`
  border-color: ${orange};
  animation-delay: ${timing*2}ms;
`
const CircleSpreadingVariation3 = styled(CircleSpreading)`
  border-color: ${grey100};
  animation-delay: ${timing*3}ms;
`
const LoadingText = styled.span`
  position: fixed;
  display: block;
  width: 100vw;
  height: 50vh;
  top: 50vh;
  font-size: 30px;
  text-align: center;
  line-height: 50vh;
`
const Loader = () => (
  <StyledLoader >
    <LoadingText>Loading...</LoadingText>
    <CircleSpreading />
    <CircleSpreadingVariation />
    <CircleSpreadingVariation2 />
    <CircleSpreadingVariation3 />
  </StyledLoader>
)

export default Loader
