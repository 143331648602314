import React from 'react'
import styled from 'styled-components'
import firebase from '../../config/firebase'
import LinkButton from '../buttons/LinkButton'
import { grey100, purple } from '../../constants/colors'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { useSession } from '../../App'

const Wrapper = styled.nav`
  position: absolute;
  top: 0;
  right: 0;
`
const AccountLink = styled(Link)`
  display: inline-block;
  font-size: 25px;
  line-height: 67px;
  text-decoration: none;
  color: ${grey100}
  transition: 600ms color;
  &:hover,
  &:active,
  &:focus {
    color: ${purple};
  }
`
const AccountIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`

const AccountMenu = () => {
  const { actions } = useSession()
  const { signOut } = actions
  return (
    <Wrapper>
      <AccountLink to="/account">
        <AccountIcon icon={faUserCircle} /> Account
      </AccountLink>
      {false &&
      <ul>
        <li><a href="/account"><FontAwesomeIcon icon={faUserCircle} />My Account</a></li>
        <li><LinkButton onClick={signOut}>Sign Out</LinkButton></li>
      </ul>
      }
    </Wrapper>
  )
}

export default AccountMenu
