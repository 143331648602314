export const PDFChildrenTable = (data) => {
  let childrenTable = {
    layout: 'myTableLayout',
    table: {
      headerRows: 1,
      widths: ['*', '*', '*', '*', '*', '*'],
      body: [
        [{
          text: 'Children',
          colSpan: 2,
          style: {
            bold: true
          }
        }, {}, {
          text: 'Date Of Birth',
          style: {
            bold: true
          }
        }, {
          text: 'Children',
          colSpan: 2,
          style: {
            bold: true
          }
        }, {}, {
          text: 'Date Of Birth',
          style: {
            bold: true
          }
        }]
      ]
    }
  };
  
  // Store the last value for odd numbered children
  let startOfRow;

  // iterate through the children to construct a table
  data.children.forEach((element, i) => {
    if (i % 2) {
      childrenTable.table.body.push(  [{
        text: startOfRow.name,
        colSpan: 2
      }, {}, {
        text: startOfRow.dob
      }, {
        text: element.name,
        colSpan: 2
      }, {}, {
        text: element.dob
      }]);
    } else {
      if (data.children.length === (i + 1)) {
        childrenTable.table.body.push(  [{
          text: element.name,
          colSpan: 2
        }, {}, {
          text: element.dob
        }, {
          text: ' ',
          colSpan: 2
        }, {}, {
          text: ' '
        }]);
      } else {
        startOfRow = element;
      }
    }
  });

  return childrenTable;
};