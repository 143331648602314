import React, { Component } from 'react'
import { DarkAppButton } from '../buttons/AppButton'
import { validateEmail } from '../../helpers/validators'
import { errorMessagesArrayToObject } from '../../helpers/reducers'

const initialState = () => ({
  email: '',
  errors: [],
  showErrors: false,
  showSuccess: false
})

const validate = email => {
  const errors = []
  validateEmail(email).forEach(error => {
    errors.push(['email', `Email ${error}`])
  })
  return errors
}

class NewsletterSignup extends Component {

  constructor (props) {
    super(props)
    this.state = initialState()
  }

  validateForm () {
    const { email } = this.state
    const errors = validate(email)
    this.setState({ errors })
    return errors
  }

  handleInputChange = e => {
    const { showErrors } = this.state
    const { target } = e
    const { value } = target
    const newState = { email: value }
    if (showErrors) newState['errors'] = validate(value)
    this.setState(newState)
  }

  handleFormSubmit = e => {
    e.preventDefault()
    const errors = this.validateForm()
    const { email } = this.state
    if (errors.length) this.setState({showErrors: true})
    else {
      const data = { email }
      fetch(
        '/api/news/signup',
        {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data)
        }
      )
        .then(() => {
          this.setState({showSuccess: true})
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  render () {
    const { errors, showErrors, email, showSuccess }= this.state
    const errorMessages = errorMessagesArrayToObject(errors)
    if (showSuccess) {
      return (
        <div>
          <p>You are now subscribed in our newsletter!</p>
        </div>
      )
    } else {
      return (
        <form onSubmit={this.handleFormSubmit}>
          <p>Subscribe to our email newsletter:</p>
          <div className={`AppInput${ showErrors && errorMessages['email'] ? ' has-error' : ''}`}>
            <input
              id="newsletterEmail"
              type="email"
              value={email}
              placeholder="Enter email..."
              name="email"
              onChange={this.handleInputChange}
              required
            />
            {showErrors && errorMessages['email'] &&
              <p className="AppInputError">{errorMessages['email']}</p>
            }
          </div>
          <div className="AppSubmit">
            <DarkAppButton type="submit">Subscribe</DarkAppButton>
          </div>
        </form>
      )
    }
  }
}
export default NewsletterSignup
