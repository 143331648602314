import { useState, useEffect } from 'react'
const buffer = 450

function useBuffer() {
  const [buffering, setBuffering] = useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setBuffering(false)
    }, buffer)
    return () => clearTimeout(timer)
  }, [])
  return buffering
}

export default useBuffer
