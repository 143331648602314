import { useReducer, useEffect } from 'react'
import { fetchWithAuth } from './useAuth'
import { simpleReducer } from '../helpers/simpleReducer'

const initialState = {
  loading: false,
  status: 'initialize',
  profileFields: [],
  attorneyProfiles: [],
  error: null
}
const basePath = '/api/v1/my/attorney_profiles'

const dispatchError = dispatch => err => dispatch({ payload: {
  loading: false,
  status: 'error',
  error: err
}})
const useAttorneyProfiles = ({history, pathHelper}) => {
  const [state, dispatch] = useReducer(simpleReducer, initialState)
  const { status, attorneyProfiles } = state

  const fetch = () =>{
    dispatch({ payload: {
      loading: true,
      status: 'loading'
    }})
    fetchWithAuth(basePath)
      .then(res => {
        dispatch({ payload: {
          loading: false,
          status: 'loaded',
          profileFields: res.profile_fields,
          attorneyProfiles: res.attorney_profiles
        }})
      })
      .catch(dispatchError(dispatch))
  }

  const create = newAttorneyProfile => {
    dispatch({ payload: {
      loading: true,
      status: 'loading'
    }})
    fetchWithAuth(basePath, {
      method: 'POST',
      body: JSON.stringify({ attorney_profile: newAttorneyProfile })
    })
      .then(res =>  {
        dispatch({ payload: {
          loading: false,
          status: 'loading'
        }})
        history.push(pathHelper(res.attorney_profile.id).show)
        fetch()
        alert('Attorney profile was successfully created.')
      })
      .catch(dispatchError(dispatch))
  }

  const update = (id, newAttorneyProfile) => {
    dispatch({ payload: {
      loading: true,
      status: 'loading'
    }})
    fetchWithAuth(`${basePath}/${id}`, {
      method: 'PATCH',
      body: JSON.stringify({ attorney_profile: newAttorneyProfile })
    })
      .then(res =>  {
        dispatch({ payload: {
          loading: false,
          status: 'loading'
        }})
        history.push(pathHelper(res.attorney_profile.id).show)
        fetch()
        alert('Attorney profile was successfully updated.')
      })
      .catch(dispatchError(dispatch))
  }

  useEffect(() => {
    if ('initialize' === status) fetch()
  }, [status])

  const representationOptions = [
    {
      label: 'Select Representation...',
      value: null
    },
    {
      label: 'Pro Se',
      value: 'Pro Se'
    }
  ].concat(attorneyProfiles.map(profile => ({
    label: profile.name,
    value: profile.id
  })))

  const helpers = { representationOptions }
  const actions = { fetch, create, update }
  return Object.assign({}, state, { actions, helpers })
}

export default useAttorneyProfiles
