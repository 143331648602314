import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { grey5, grey10, grey25 } from '../../constants/colors'
import * as Timings from '../../constants/timings'

// main section
export const ToggleSectionButton = styled.button`
  display: inline-block;
  width: 100%;
  text-align: left;
  text-transform: none;
  letter-spacing: inherit;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 25px;
  padding: 15px 20px;
  margin: 0;
  border: 0;
  background-color: ${props => props.isActive ? grey10 : 'transparent'};
  cursor: pointer;
  transition: ${Timings.med}ms color, ${Timings.med}ms background-color;
  &:hover,
  &:active,
  &:focus {
    background-color: ${grey25};
  }
`
export const ToggleSectionIcon = styled(FontAwesomeIcon)`
  margin-right: 20px;
`
export const BodySection = styled.div`
  margin-bottom: 60px;
`
export const BodySectionInner = styled.section`
  padding: 15px 20px;
  background: ${grey5};
`
export const BodySectionTitle = styled.h4`
  font-size: 30px;
  padding-bottom: 22px;
  border-bottom: 1px solid #EEF0F1;
  margin-bottom: 22px;
`
export const BodySubSectionTitle = styled.h5`
  font-size: 26px;
  margin-bottom: 16px;
`
export const BodyInstructions = styled.p`
  font-size: 14px;
  margin-bottom: 16px;
`
export const BodyValue = styled.p`
  text-align: center;
  font-size: 22px;
  background-color: #EEF0F1;
  padding: 15px;
`
export const BodyValueNA = styled(BodyValue)`
  font-size: 14px;
`

// overnights calculator
export const WeekdaySelector = styled.div`
  border: 1px solid #EEF0F1;
`
export const WeekdaySelectorHeader = styled.div`
  padding: 8px;
`
export const WeekdaySelectorBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
  padding: 8px;
  background-color: #EEF0F1;
`
export const WeekdaySelectorOption = styled.button`
  font-size: 8px;
  text-transform: none;
  text-align: center;
  letter-spacing: normal;
  background-color: white;
  width: 25px;
  height: 25px;
  padding: 0px;
  border: none;
  border-radius: 25px;
`
export const CalculatorTotal = styled.div`
  display: flex;
`
export const CalculatorLabel = styled.p`
  flex: 4;
  font-size: 22px;
`
export const CalculatorValue = styled.p`
  flex: 1;
  font-size: 22px;
`
