import React from 'react'
import styled from 'styled-components'

const Text = props => (
  <StyledText {...props} type="text" />
)
const StyledText = styled.input`
  ${props => props.theme.snippets.inputs}
`

export const SmallerText = styled(Text)`
  margin: 0;
  display: block;
`

Text.displayName = 'Text'

export default Text
