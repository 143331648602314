
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "../fonts/vfs_fonts";
pdfMake.vfs = pdfFonts;

export const constructPDFObject = () => {

  pdfMake.tableLayouts = {
    myTableLayout: {
      hLineWidth: function (i, node) {
        if (i === 0 || i === node.table.body.length) {
          return 0;
        }
        return 1;
      },
      defaultBorder: true,
      vLineWidth: function (i) {
        return 0;
      },
      hLineColor: function (i) {
        return i === 1 ? '#101010' : '#aaa';
      },
      paddingLeft: function (i) {
        return i === 0 ? 0 : 8;
      },
      paddingRight: function (i, node) {
        return (i === node.table.widths.length - 1) ? 0 : 8;
      }
    }
  };

  pdfMake.fonts = {
    arial: {
      normal: 'arial.ttf',
      bold: 'arial-bold.ttf',
    }
  };

  return pdfMake;
};
