import React from 'react'
import Loader from '../Loader'
import Header from '../Header'
import ContentBlock from '../ContentBlock'
import Form from './form'

const New = ({loading, create}) => {
  if (loading) return <Loader />
  return (
    <main>
      <Header />
      <ContentBlock>
        <h2>New Attorney Profile</h2>
        <Form {...{create}} />
      </ContentBlock>
    </main>
  )
}

export default New
