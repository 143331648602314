export const PDFFormSections = (section, sectionNumber, data) => {
  let sectionContent = [];
  //console.log(' section:', section, ' sectionNumber: ', sectionNumber)
  switch (section) {
    case 'monthly-gross-income':
      sectionContent = [
        [{
          ol: [{
            text: 'Monthly Gross Income, C.R.S. §14-10-115(5)',
          }],
          start: sectionNumber ? sectionNumber : 1,
          colSpan: 3,
          style: {
            bold: true
          }
        }, {}, {}, {
          text: data.worksheet.party1.monthlygrossincome
        }, {
          text: data.worksheet.party2.monthlygrossincome
        }, {
          text: ' ',
          rowSpan: 1
        }],
        [{
          ol: [{
            text: 'Plus maintenance received (adjusted per\n§ 115(5)(a)(I.5) if not taxable): ' + data.worksheet.description.adjustedmaintenancereceivedexplained,
          }],
          type: 'lower-alpha',
          start: 1,
          colSpan: 3
        }, {}, {}, {
          text: data.worksheet.party1.adjustedmaintenancereceived
        }, {
          text: data.worksheet.party2.adjustedmaintenancereceived
        }, {}],
        [{
          ol: [{
            text: 'Minus maintenance paid (adjusted per\n§ 115(5)(a)(I.5) if not taxable): ' + data.worksheet.description.adjustedmaintenancerepaidexplained,
          }],
          start: 2,
          type: 'lower-alpha',
          colSpan: 3
        }, {}, {}, {
          text: data.worksheet.party1.adjustedmaintenancepaid
        }, {
          text: data.worksheet.party2.adjustedmaintenancepaid
        }, {}],
        [{
          ol: [{
            text: 'Minus ordered child support payments for non-joint children, § 115(6)(a)(I)',
          }],
          start: 3,
          type: 'lower-alpha',
          colSpan: 3
        }, {}, {}, {
          text: data.worksheet.party1.supportPaidChildrenNOTR
        }, {
          text: data.worksheet.party2.supportPaidChildrenNOTR
        }, {}],
        [{
          ol: [{
            text: 'Minus legal responsibility for non-joint children, § 115(6)(a)(II)',
          }],
          start: 4,
          type: 'lower-alpha',
          colSpan: 3
        }, {}, {}, {
          text: data.worksheet.party1.otherRelationshipResponsibility
        }, {
          text: data.worksheet.party2.otherRelationshipResponsibility
        }, {}],
        [{
          ol: [{
            text: 'Minus documented payments for non-joint children, § 115(6)(a)(III)',
          }],
          start: 5,
          type: 'lower-alpha',
          colSpan: 3
        }, {}, {}, {
          text: data.worksheet.party1.cappedDocumentedPaymentsChildrenOtherRelationship
        }, {
          text: data.worksheet.party2.cappedDocumentedPaymentsChildrenOtherRelationship
        }, {}]
      ];
      break;
    case 'monthly-adjusted-gross-income':
      sectionContent = [
        [{
          ol: [{
            text: 'Monthly Adjusted Gross Income, § 115(3)(a)',
          }],
          start: sectionNumber ? sectionNumber : 1,
          colSpan: 3,
          style: {
            bold: true
          }
        }, {}, {}, {
          text: data.worksheet.party1.monthlyAdjustedGrossIncomes
        }, {
          text: data.worksheet.party2.monthlyAdjustedGrossIncomes
        }, {
          text: data.worksheet.combined.combinedAdjustedGrossIncome
        }]
      ];
      break;
    case 'percentage-share-of-income':
      sectionContent = [
        [{
          ol: [{
            text: 'Percentage Share of Income',
          }],
          start: sectionNumber ? sectionNumber : 1,
          colSpan: 3,
          style: {
            bold: true
          }
        }, {}, {}, {
          text: data.worksheet.party1.shareofincome
        }, {
          text: data.worksheet.party2.shareofincome
        }, {
          text: ' '
        }]
      ];
      break;
    case 'basic-support-obligation':
      sectionContent = [
        [{
          ol: [{
            text: 'Basic Support Obligation',
          }],
          start: sectionNumber ? sectionNumber : 1,
          colSpan: 6,
          style: {
            bold: true
          }
        }, {}, {}, {}, {}, {}],
        [{
          ol: [{
            text: 'Basic combined obligation, § 115(7)(b)',
          }],
          start: 1,
          type: 'lower-alpha',
          colSpan: 3,
        }, {}, {}, {
          text: ' ',
          colSpan: 2
        }, {}, {
          text: data.worksheet.combined.basicsupportobligation
        }],
        [{
          ol: [{
            text: 'Each parent\'s share of basic obligation,\n§ 115(7)(a)(I)',
          }],
          start: 2,
          type: 'lower-alpha',
          colSpan: 3
        }, {}, {}, {
          text: data.worksheet.party1.shareofbasicobligation
        }, {
          text: data.worksheet.party2.shareofbasicobligation
        }, {
          text: ' ',
          rowSpan: 1
        }]
      ];
      break;
    case 'each-parents-share-of-basic-obligation':
      sectionContent = [
        [{
          ol: [{
            text: 'Each Parent’s Share of Basic Obligation',
          }],
          start: sectionNumber ? sectionNumber : 1,
          colSpan: 3,
          style: {
            bold: true
          }
        }, {}, {}, {
          text: data.worksheet.party1.shareofbasicobligation
        }, {
          text: data.worksheet.party2.shareofbasicobligation
        }, {
          text: ' ',
          rowSpan: 1
        }],
      ];
      break;
    case 'overnights-with-each-parent':
      sectionContent = [
        [{
          ol: [{
            text: 'Overnights with Each Parent',
          }],
          start: sectionNumber ? sectionNumber : 1,
          colSpan: 3,
          style: {
            bold: true
          }
        }, {}, {}, {
          text: data.worksheet.party1.overnightstays
        }, {
          text: data.worksheet.party2.overnightstays
        }, {
          text: ' ',
          rowSpan: 1
        }],
      ];
      break;
    case 'percentage-time-with-each-parent':
      sectionContent = [
        [{
          ol: [{
            text: 'Percentage Time with Each Parent',
          }],
          start: sectionNumber ? sectionNumber : 1,
          colSpan: 3,
          style: {
            bold: true
          }
        }, {}, {}, {
          text: data.worksheet.party1.percentagetime
        }, {
          text: data.worksheet.party2.percentagetime
        }, {
          text: ' ',
          rowSpan: 1
        }],
      ];
      break;
    case 'support-obligation-for-time-with-other-parents':
      sectionContent = [
        [{
          ol: [{
            text: 'Support Obligation for Time with Other Parent, § 115(8)(b)',
          }],
          start: sectionNumber ? sectionNumber : 1,
          colSpan: 3,
          style: {
            bold: true
          }
        }, {}, {}, {
          text: data.worksheet.party1.supportfortime
        }, {
          text: data.worksheet.party2.supportfortime
        }, {
          text: ' ',
          rowSpan: 1
        }],
      ];
      break;
    case 'basic-support-obligation-short':
      sectionContent = [
        [{
          ol: [{
            text: 'Basic Combined Obligation, § 115(7)(b)',
          }],
          start: sectionNumber ? sectionNumber : 1,
          colSpan: 3,
          style: {
            bold: true
          }
        }, {}, {}, {
          text: ' ',
          colSpan: 2
        }, {}, {
          text: data.worksheet.combined.basicsupportobligation
        }]
      ];
      break;
    case 'shared-physical-care-support-obligation':
      sectionContent = [
        [{
          ol: [{
            text: 'Shared Physical Care Support Obligation, § 115(8)(b)',
          }],
          start: sectionNumber ? sectionNumber : 1,
          colSpan: 3,
          style: {
            bold: true
          }
        }, {}, {}, {
          text: ' ',
          colSpan: 2
        }, {}, {
          text: data.worksheet.combined.sharedphysicalcaresupportobligation
        }]
      ];
      break;
    case 'low-income-adjustment':
      sectionContent = [
        [{
          ol: [{
            text: 'Low-Income Adjustment, § 115(7)(a)(II)',
          }],
          start: sectionNumber ? sectionNumber : 1,
          colSpan: 3,
          style: {
            bold: true
          }
        }, {}, {}, {
          text: data.worksheet.party1.lowincomeadjustment
        }, {
          text: data.worksheet.party2.lowincomeadjustment
        }, {}]
      ];
      break;
    case 'adjustments':
      sectionContent = [
        [{
          ol: [{
            text: 'Adjustments',
          }],
          start: sectionNumber ? sectionNumber : 1,
          colSpan: 3,
          style: {
            bold: true
          }
        }, {}, {}, {
          text: ' ',
          colSpan: 2
        }, {}, {}],
        [{
          ol: [{
            text: 'Plus work-related child care costs net of federal Tax Credit for Child Care, § 115(9)',
          }],
          start: 1,
          type: 'lower-alpha',
          colSpan: 3
        }, {}, {}, {
          text: data.worksheet.party1.workchildcarecosts
        }, {
          text: data.worksheet.party2.workchildcarecosts
        }, {}],
        [{
          ol: [{
            text: 'Plus education-related child care costs, § 115(9)',
          }],
          start: 2,
          type: 'lower-alpha',
          colSpan: 3
        }, {}, {}, {
          text: data.worksheet.party1.educhildcarecosts
        }, {
          text: data.worksheet.party2.educhildcarecosts
        }, {}],
        [{
          ol: [{
            text: 'Plus health insurance premium costs, § 115(10)',
          }],
          start: 3,
          type: 'lower-alpha',
          colSpan: 3
        }, {}, {}, {
          text: data.worksheet.party1.healthinscosts
        }, {
          text: data.worksheet.party2.healthinscosts
        }, {}],
        [{
          ol: [{
            text: 'Plus extraordinary medical expenses, § 115(10)(h)',
          }],
          start: 4,
          type: 'lower-alpha',
          colSpan: 3
        }, {}, {}, {
          text: data.worksheet.party1.medexpenses
        }, {
          text: data.worksheet.party2.medexpenses
        }, {}],
        [{
          ol: [{
            text: 'Plus extraordinary expenses, § 115(11)(a)',
          }],
          start: 5,
          type: 'lower-alpha',
          colSpan: 3
        }, {}, {}, {
          text: data.worksheet.party1.extraexpenses
        }, {
          text: data.worksheet.party2.extraexpenses
        }, {}],
        [{
          ol: [{
            text: 'Minus extraordinary adjustments, § 115(11)(b)',
          }],
          start: 6,
          type: 'lower-alpha',
          colSpan: 3
        }, {}, {}, {
          text: data.worksheet.party1.extraadjustments
        }, {
          text: data.worksheet.party2.extraadjustments
        }, {}]
      ];
      break;
    case 'total-adjustments':
      sectionContent = [
        [{
          ol: [{
            text: 'Total Adjustments',
          }],
          start: sectionNumber ? sectionNumber : 1,
          colSpan: 3,
          style: {
            bold: true
          }
        }, {}, {}, {
          text: data.worksheet.party1.totaladjustments
        }, {
          text: data.worksheet.party2.totaladjustments
        }, {
          text: data.worksheet.combined.Combinedtotaladjustments
        }]
      ];
      break;
    case 'each-parents-share-of-adjustments':
      sectionContent = [
        [{
          ol: [{
            text: 'Each Parent’s Share of Adjustments',
          }],
          start: sectionNumber ? sectionNumber : 1,
          colSpan: 3,
          style: {
            bold: true
          }
        }, {}, {}, {
          text: data.worksheet.party1.shareadjustments
        }, {
          text: data.worksheet.party2.shareadjustments
        }, {
          text: ' ',
          rowSpan: 1
        }]
      ];
      break;
    case 'each-parents-share-of-total-support':
      sectionContent = [
        [{
          ol: [{
            text: 'Each Parent’s Share of Total Support',
          }],
          start: sectionNumber ? sectionNumber : 1,
          colSpan: 3,
          style: {
            bold: true
          }
        }, {}, {}, {
          text: data.worksheet.party1.Payingparentsharetotal
        }, {
          text: data.worksheet.party2.Payingparentsharetotal
        }, {}]
      ];
      break;
    case 'paying-parents-adjustment':
      sectionContent = [
        [{
          ol: [{
            text: 'Paying Parent’s Adjustment',
          }],
          start: 10,
          colSpan: 3,
          style: {
            bold: true
          }
        }, {}, {}, {
          text: data.worksheet.party1.Payingparentadjustment
        }, {
          text: data.worksheet.party2.Payingparentadjustment
        }, {}]
      ];
      break;
    case 'recomended-child-support-order':
      sectionContent = [
        [{
          ol: [{
            text: 'Recommended Child Support Order',
          }],
          start: 15,
          colSpan: 3,
          style: {
            bold: true
          }
        }, {}, {}, {
          text: data.worksheet.party1.Payingparentsupporttotal
        }, {
          text: data.worksheet.party2.Payingparentsupporttotal
        }, {}]
      ];
      break;
    case 'paid-in-excess-of-fair-share':
      sectionContent = [
        [{
          ol: [{
            text: 'Adjustments Paid in Excess of Fair Share',
          }],
          start: 13,
          colSpan: 3,
          style: {
            bold: true
          }
        }, {}, {}, {
          text: data.worksheet.party1.excessadjustmentspaid
        }, {
          text: data.worksheet.party2.excessadjustmentspaid
        }, {}]
      ];
      break;
    case 'each-parents-adjusted-support-obligation':
      sectionContent = [
        [{
          ol: [{
            text: 'Each Parent\'s Adjusted Support Obligation',
          }],
          start: 14,
          colSpan: 3,
          style: {
            bold: true
          }
        }, {}, {}, {
          text: data.worksheet.party1.adjustedobligation
        }, {
          text: data.worksheet.party2.adjustedobligation
        }, {}]
      ];
      break;
    case 'comments':
      sectionContent = [
        [{
          text: 'Comments:\n\n' + data.comments,
          colSpan: 6
        }, {}, {}, {}, {}, {}]
      ];
      break;
    case 'signature':
      sectionContent = [

        [{
          text: 'Prepared by:',
          colSpan: 6,
          border: [false, false, false, false],
        }, {}, {}, {}, {}, {}],
        [{
          border: [false, false, false, false],
          text: '\n \n \n \_\_\_\_\_\_\_\_\_\_\_\_\_\_\_\_\_\_\_\_\_\_\_\_                    \_\_\_\_\_\_\_\_\_\_\_\_\_\_\_\_\_\_\_\_\_\_\_\_                   \_\_\_\_\_\_\_\_\_\_\_\_\_\_\_\n Signature                                                     Printed Name                                             Date',
          colSpan: 6
        }, {}, {}, {}, {}, {}]
      ];
      break;
    default:
      break;
  }
  return sectionContent;
};
