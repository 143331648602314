import React from 'react'
import ContentBlock from '../ContentBlock'
import ContentWithSidebar from '../layouts/ContentWithSidebar'
import FooterMessages from '../../store/ui/footer'
import './Footer.css'
import NewsletterSignup from '../NewsletterSignup'
import Dev from '../dev'

const Footer = (props) => (
  <footer className="Footer">
    {!props.skipSignup && (
      <ContentWithSidebar>
        <ContentBlock>
          <p className="Footer-disclaimer">{FooterMessages['DISCLAIMER']}</p>
        </ContentBlock>
        <ContentBlock>
          <NewsletterSignup />
        </ContentBlock>
      </ContentWithSidebar>
    )}
    {props.skipSignup && (
      <ContentBlock>
        <p className="Footer-disclaimer">{FooterMessages['DISCLAIMER']}</p>
      </ContentBlock>
    )}
    <nav>
      <ul className="Footer-nav">
        <li className="Footer-nav-item"><a href="https://www.legalthunderapps.com" target="_blank" rel="noopener noreferrer">&copy; Legal Thunder, LLC 2019</a></li>
        <li className="Footer-nav-item"><a href="https://www.legalthunderapps.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
        <li className="Footer-nav-item"><a href="https://www.legalthunderapps.com/terms-of-use/" target="_blank" rel="noopener noreferrer">Terms of Use</a></li>
      </ul>
    </nav>
    <Dev />
  </footer>
)

export default Footer
