import React from 'react'
import * as CaseDocTypes from '../../constants/CaseDocTypes'
import ChildSupport from '../../components/child-support'
import { caseDocTypeFromPath } from '../../helpers/paths'

const CaseDoc = props => {
  const { match } = props
  const { params } = match
  const { id, caseDocType: caseDocTypePath, caseDocId } = params
  const caseDocType = caseDocTypeFromPath(caseDocTypePath)
  return (
    <main>
      {CaseDocTypes.CHILD_SUPPORT_CALCULATION === caseDocType &&
        <ChildSupport {...{id, caseDocId}} />
      }
    </main>
  )
}

export default CaseDoc
