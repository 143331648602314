import React, { useState, useEffect } from 'react'
import {Prompt} from 'react-router-dom'

const useUnsavedChangesWarning = (message = 'Your case profile changes are unsaved, reload?') => {
  const [isUnsaved, setUnsaved] = useState(false)

  useEffect(() => {
    //detecting browser closing
    window.onbeforeunload = isUnsaved && (() => message)

    return () => {
      window.onbeforeunload = null
    }
  }, [isUnsaved, message])
  const routerPrompt = <Prompt when={isUnsaved} message={message} />
  return [routerPrompt, () => setUnsaved(true), () => setUnsaved(false)]
}

export default useUnsavedChangesWarning
