import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder, faFolderPlus, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
import { withRouter } from 'react-router-dom'
import ContentBlock from '../ContentBlock'
import * as AppUI from '../app'
import { DarkAppButton } from '../buttons/AppButton'
//import CTAButton from '../buttons/CTAButton'
//import LinkButton from '../buttons/LinkButton'
//import TextButton from '../buttons/TextButton'
import Fade from '../transitions/Fade'
import ModalWrapper from '../modals/ModalWrapper'
import Grid4Columns from '../layouts/Grid4Columns'
import useCases from '../../hooks/useCases'
import useBuffer from '../../hooks/useBuffer'
import Loader from '../Loader'
import { useSession } from '../../App'
//import { purple, grey5, darkGreyHighContrast } from '../../constants/colors'
import { CasesTitle, NewButton, CaseTitleButton, CasePreviewAction, GridItem, FlexGridItemInner } from '../../containers/case'
import Inputs from '../inputs'
import Forms from '../forms'
import { cleaner } from '../../helpers/cleaner'


const casePath = id => `/cases/${id}/case-profile`
const Cases = withRouter(( { history })  => {
  const { session } = useSession()
  const [showAddCase, setShowAddCase] = useState(false)
  const [isCaseDeleted, setIsCaseDeleted] = useState(false)
  const [deleteCaseId, setDeleteCaseId] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [nowOrLater, setNowOrLater] = useState('now')
  const [renamingCase, setRenamingCase] = useState(false)
  const {
    loading,
    cases,
    messages,
    error,
    actions
  } = useCases()
  const {
    createCase,
    deleteCase,
    dismissModal
  } = actions
  const showError = !!error
  const [redirectPath, setRedirectPath] = useState(false)
  const buffering = useBuffer()

  const onNowOrLaterChange = e => {
    setNowOrLater(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const title = document.getElementById('newCaseName').value
    const data = { title }
    createCase(title)
      .then(docRef => {
        if ('now' === nowOrLater) {
          setRedirectPath(`/cases/${docRef.id}/case-profile`)
        } else {
          setRedirectPath(`/cases/${docRef.id}`)
        }
      })
      .catch(error => console.log(error))
  }

  return (
    <ContentBlock>
      {redirectPath && <Redirect push={true} to={{ pathname: redirectPath }} />}
      <CasesTitle>My Cases</CasesTitle>
      <NewButton title="Click to add a new case" onClick={() => setShowAddCase(true)}>
        <FontAwesomeIcon icon={faFolderPlus} /> New
      </NewButton>
      <br />
      <div className="Cases">
        {(loading || buffering) && <Loader />}
        {showError && <p>Error: {error.message}</p>}
        {0 === cases.length &&
          <div>
            <p>No cases found.</p>
          </div>
        }
        {0 < cases.length &&
          <Grid4Columns>
            {cases.map((doc, ind) => {
              const { id = null, admin = {} } = doc || {}
              const { caseName = 'N/A' } = admin
              return (
              <GridItem key={ind}>
                <FlexGridItemInner>
                  <CaseTitleButton
                    title="Click to view case files"
                    onClick={() => setRedirectPath(`/cases/${id}`)}
                  >
                    <FontAwesomeIcon icon={faFolder} /> {caseName}
                  </CaseTitleButton>
                  <CasePreviewAction
                    title="Click to edit case profile"
                    onClick={() => history.push(casePath(id))}
                  ><FontAwesomeIcon icon={faEdit} /> Edit Case Profile</CasePreviewAction>
                  <CasePreviewAction
                    title="Click to delete case and all case files"
                    onClick={() => setDeleteCaseId(id)}
                  ><FontAwesomeIcon icon={faTrash} /> Delete</CasePreviewAction>
                </FlexGridItemInner>
              </GridItem>
            )})}
          </Grid4Columns>
        }
      </div>
      <Fade in={showAddCase}>
        <ModalWrapper close={() => setShowAddCase(false)}>
          <Forms.FormGrid2>
            <form onSubmit={handleSubmit}>
              <h3 className="AppFormTitle">Create New Case</h3>
              <Inputs.Wrapper>
                <Inputs.Label htmlFor="newCaseName">Case Name</Inputs.Label>
                <Inputs.Text id="newCaseName" name="newCaseName" placeholder="Enter case name..." required={true} />
              </Inputs.Wrapper>
              <div>
                <Inputs.Wrapper>
                <AppUI.RadioLabel>Edit case profile now
                  <input
                    name="chooseNowOrLater"
                    value="now"
                    type="radio"
                    checked={'now' === nowOrLater}
                    onChange={onNowOrLaterChange}
                 />
                <AppUI.RadioCheckmark className="app-checkmark" />
                </AppUI.RadioLabel>
                <AppUI.RadioLabel>Edit case profile later
                  <input
                    name="chooseNowOrLater"
                    value="later"
                    type="radio"
                    checked={'later' === nowOrLater}
                    onChange={onNowOrLaterChange}
                 />
                  <AppUI.RadioCheckmark className="app-checkmark" />
                </AppUI.RadioLabel>
                </Inputs.Wrapper>
              </div>
              <div className="AppSubmit">
                <DarkAppButton className="dark" type="submit">Submit</DarkAppButton>
              </div>
            </form>
          </Forms.FormGrid2>
        </ModalWrapper>
      </Fade>
      <Fade in={false !== deleteCaseId}>
        <ModalWrapper close={() => setDeleteCaseId(false)}>
          <Forms.FormGrid2>
            <form onSubmit={e => {
              e.preventDefault()
              setDeleteCaseId(false)
              deleteCase(deleteCaseId)
            }}>
              <h3 className="AppFormTitle">Confirm Delete Case?</h3>
              <div className="AppSubmit">
                <DarkAppButton type="submit">Delete Now</DarkAppButton>
              </div>
            </form>
          </Forms.FormGrid2>
        </ModalWrapper>
      </Fade>
      <Fade in={null !== messages}>
        <ModalWrapper close={dismissModal}>
          {messages && messages.map((message, ind) => <p key={ind}>{message}</p>)}
        </ModalWrapper>
      </Fade>
    </ContentBlock>
  )
})

export default Cases
