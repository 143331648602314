export const ColoradoCourts = [
  {
    "County": "Adams County",
    "Judicial District": "17th",
    "Name": "Adams County Justice Center",
    "Court Address": "1100 Judicial Center Drive, Brighton, CO 80601"
  },
  {
    "County": "Alamosa County",
    "Judicial District": "12th",
    "Name": "Alamosa Combined Court, 8955 Independence Way, Alamosa, CO 81101",
    "Court Address": ""
  },
  {
    "County": "Arapahoe County",
    "Judicial District": "18th",
    "Name": "Arapahoe County Justice Center",
    "Court Address": "7325 S. Potomac Street, Centennial, CO 80112"
  },
  {
    "County": "Arapahoe County",
    "Judicial District": "18th",
    "Name": "Arapahoe County Courthouse - Littleton",
    "Court Address": "1790 West Littleton Blvd., Littleton, CO 80120"
  },
  {
    "County": "Archuleta County",
    "Judicial District": "6th",
    "Name": "Archuleta County Courthouse",
    "Court Address": "46 Eaton Drive, Suite 1, P.O. Box 148, Pagosa Springs, CO 81147"
  },
  {
    "County": "Baca County",
    "Judicial District": "15th",
    "Name": "Baca County Court",
    "Court Address": "741 Main Street, Suite 5, Springfield, CO 81073"
  },
  {
    "County": "Bent County",
    "Judicial District": "16th",
    "Name": "Bent Combined Court",
    "Court Address": "725 Bent Avenue, Las Animas, CO 81054"
  },
  {
    "County": "Boulder County",
    "Judicial District": "20th",
    "Name": "Boulder County Combined County Court",
    "Court Address": "1777 6th Street, Boulder, CO 80302"
  },
  {
    "County": "Boulder County",
    "Judicial District": "20th",
    "Name": "Boulder County Combined Court - Longmont",
    "Court Address": "1035 Kimbark Street, Longmont, CO 80501"
  },
  {
    "County": "Chaffee County",
    "Judicial District": "11th",
    "Name": "Chaffee Combined Court",
    "Court Address": "142 Crestone Avenue, P.O. Box 279, Salida, CO 81201"
  },
  {
    "County": "Cheyenne County",
    "Judicial District": "15th",
    "Name": "Cheyenne County Courthouse",
    "Court Address": "51 S. 1st Street, P.O. Box 696, Cheyenne Wells, CO 80810"
  },
  {
    "County": "City and County of Broomfield",
    "Judicial District": "17th",
    "Name": "Broomfield Combined Courts",
    "Court Address": "17 Descombes Drive, Broomfield, CO 80020"
  },
  {
    "County": "City and County of Denver",
    "Judicial District": "2nd",
    "Name": "Denver City & County Bldg",
    "Court Address": "1437 Bannock Street, Room 256, Denver, CO 80202"
  },
  {
    "County": "City and County of Denver",
    "Judicial District": "2nd",
    "Name": "Lindsey-Flanigan Courthouse",
    "Court Address": "520 W. Colfax Avenue, Room 135, Denver, CO 80204"
  },
  {
    "County": "Clear Creek County",
    "Judicial District": "5th",
    "Name": "Clear Creek County Courthouse",
    "Court Address": "405 Argentine, P.O. Box 367, Georgetown, CO 80444"
  },
  {
    "County": "Conejos County",
    "Judicial District": "12th",
    "Name": "Conejos Combined Court",
    "Court Address": "6683 County Road 13, P.O. Box 128, Conejos, CO 81129"
  },
  {
    "County": "Costilla County",
    "Judicial District": "12th",
    "Name": "Costilla Combined Court",
    "Court Address": "304 Main Street, P.O. Box 301, San Luis, CO 81152"
  },
  {
    "County": "Crowley County",
    "Judicial District": "16th",
    "Name": "Crowley Combined Court",
    "Court Address": "110 East 6th, Room 303, Ordway, CO 81063"
  },
  {
    "County": "Custer County",
    "Judicial District": "11th",
    "Name": "Custer Combined Court",
    "Court Address": "205 S. 6th Street, P.O. Box 60, Westcliffe, CO 81252"
  },
  {
    "County": "Delta County",
    "Judicial District": "7th",
    "Name": "Delta County Combined Court",
    "Court Address": "501 Palmer Street, #338, Delta, CO 81416"
  },
  {
    "County": "Dolores County",
    "Judicial District": "22nd",
    "Name": "Dolores Combined Court",
    "Court Address": "409 N. Main, P.O. Box 511, Dove Creek, CO 81324"
  },
  {
    "County": "Douglas County",
    "Judicial District": "18th",
    "Name": "Douglas County Courthouse",
    "Court Address": "4000 Justice Way, Suite 2009, Castle Rock, CO 80109"
  },
  {
    "County": "Eagle County",
    "Judicial District": "5th",
    "Name": "Eagle County Justice Center",
    "Court Address": "885 Chambers Ave., P.O. Box 597, Eagle, CO 81631"
  },
  {
    "County": "Eagle County",
    "Judicial District": "5th",
    "Name": "Eagle County Court at El Jebel",
    "Court Address": "0020 Eagle County Drive, Suite B, Carbondale, CO 81623"
  },
  {
    "County": "El Paso County",
    "Judicial District": "4th",
    "Name": "El Paso County Judicial Building",
    "Court Address": "270 S. Tejon Street, Colorado Springs, CO 80903"
  },
  {
    "County": "Elbert County",
    "Judicial District": "18th",
    "Name": "Elbert County Courthouse",
    "Court Address": "751 Ute Avenue, P.O. Box 232, Kiowa, CO 80117"
  },
  {
    "County": "Fremont County",
    "Judicial District": "11th",
    "Name": "Fremont Combined Court",
    "Court Address": "136 Justice Center Road, Canon City, CO 81212"
  },
  {
    "County": "Garfield County",
    "Judicial District": "9th",
    "Name": "Garfield County Courthouse",
    "Court Address": "109 8th Street, Suite 104, Glenwood Springs, CO 81601"
  },
  {
    "County": "Garfield County",
    "Judicial District": "9th",
    "Name": "Garfield County Associate Court - Rifle",
    "Court Address": "100 E. 18th Street, Suite 103, Rifle, CO 81650"
  },
  {
    "County": "Gilpin County",
    "Judicial District": "1st",
    "Name": "2960 Dory Hill Road, Ste. 200, Black Hawk, CO 80422",
    "Court Address": "2960 Dory Hill Road, Ste. 200, Black Hawk, CO 80422"
  },
  {
    "County": "Grand County",
    "Judicial District": "14th",
    "Name": "Grand County Judicial Center",
    "Court Address": "307 Moffat Avenue, P.O. Box 192, Hot Sulfer Springs, CO 80451"
  },
  {
    "County": "Gunnison County",
    "Judicial District": "7th",
    "Name": "Gunnison County Combined Court Offices",
    "Court Address": "200 E. Virginia Avenue, Gunnison, CO 81230"
  },
  {
    "County": "Hinsdale County",
    "Judicial District": "7th",
    "Name": "Hinsdale County Courthouse",
    "Court Address": "317 Henson, Lake City, CO 81235"
  },
  {
    "County": "Huerfano County",
    "Judicial District": "3rd",
    "Name": "Huerfano County Courthouse",
    "Court Address": "401 Main St., Suite 304, Walsenburg, CO 81089"
  },
  {
    "County": "Jackson County",
    "Judicial District": "8th",
    "Name": "Jackson County Combined Court",
    "Court Address": "396 Lafever Street, P.O. Box 308, Walden, CO 80480"
  },
  {
    "County": "Jefferson County",
    "Judicial District": "1st",
    "Name": "100 Jefferson County Parkway, Golden, CO 80401",
    "Court Address": "100 Jefferson County Parkway, Golden, CO 80401"
  },
  {
    "County": "Kiowa County",
    "Judicial District": "15th",
    "Name": "Kiowa County Combined Court",
    "Court Address": "1305 Goff Street, P.O. Box 353, Eads, CO 81036"
  },
  {
    "County": "Kit Carson County",
    "Judicial District": "13th",
    "Name": "Kit Carson County Combined Court",
    "Court Address": "251 16th Street, Suite 301, Burlington, CO 80807"
  },
  {
    "County": "La Plata County",
    "Judicial District": "6th",
    "Name": "La Plata County Courthouse",
    "Court Address": "1060 E. 2nd Ave., Room 106, Durango, CO 81301"
  },
  {
    "County": "Lake County",
    "Judicial District": "5th",
    "Name": "Lake County Justice Center",
    "Court Address": "505 Harrison Ave., P.O. Box 55, Leadville, CO 80461"
  },
  {
    "County": "Larimer County",
    "Judicial District": "8th",
    "Name": "Larimer County Justice Center",
    "Court Address": "201 La Porte Avenue, Suite 100, Fort Collins, CO 80521"
  },
  {
    "County": "Larimer County",
    "Judicial District": "8th",
    "Name": "Loveland County Court",
    "Court Address": "810 E. 10th Street, Suite 110, Loveland, CO 80537"
  },
  {
    "County": "Las Animas County",
    "Judicial District": "3rd",
    "Name": "Las Animas County Courthouse",
    "Court Address": "200 E. 1st St., Room 304, Trinidad, CO 81082"
  },
  {
    "County": "Lincoln County",
    "Judicial District": "18th",
    "Name": "Lincoln County Courthouse",
    "Court Address": "103 Third Avenue, P.O. Box 128, Hugo, CO 80821"
  },
  {
    "County": "Logan County",
    "Judicial District": "13th",
    "Name": "Logan County Combined Courts",
    "Court Address": "110 N. Riverview Road, Sterling, CO 80751"
  },
  {
    "County": "Mesa County",
    "Judicial District": "21st",
    "Name": "Mesa County Justice Center",
    "Court Address": "125 N. Spruce Street, P.O. Box 20,000-5030, Grand Junction, CO 81501"
  },
  {
    "County": "Mineral County",
    "Judicial District": "12th",
    "Name": "Mineral Combined Court",
    "Court Address": "P.O. Box 337, 1201 North Main, Creede, CO 81130"
  },
  {
    "County": "Moffat County",
    "Judicial District": "14th",
    "Name": "Moffat Combined Court",
    "Court Address": "221 W. Victory Way, Suite 300, Craig, CO 81625"
  },
  {
    "County": "Montezuma County",
    "Judicial District": "22nd",
    "Name": "Montezuma Combined Courts",
    "Court Address": "865 North Park Street, Suite 100, Cortez, CO 81321"
  },
  {
    "County": "Montrose County",
    "Judicial District": "7th",
    "Name": "Montrose County Justice Center",
    "Court Address": "1200 North Grand Avenue, Montrose, CO 81401"
  },
  {
    "County": "Montrose County",
    "Judicial District": "7th",
    "Name": "Nucla Montrose Associate Court",
    "Court Address": "Montrose County Courthouse Annex - Nucla, 300 Main Street, Nucla, CO 81424"
  },
  {
    "County": "Morgan County",
    "Judicial District": "13th",
    "Name": "Morgan District Court",
    "Court Address": "400 Warner Street, Fort Morgan, CO 80701"
  },
  {
    "County": "Otero County",
    "Judicial District": "16th",
    "Name": "Otero Combined Court",
    "Court Address": "13 West Third Street, Room 207, La Junta, CO 81050"
  },
  {
    "County": "Ouray County",
    "Judicial District": "7th",
    "Name": "Ouray County Courthouse",
    "Court Address": "630 Main Street, P.O. Box 643, Ouray, CO 81427"
  },
  {
    "County": "Park County",
    "Judicial District": "11th",
    "Name": "Park Combined Court",
    "Court Address": "300 Fourth Street, P.O. Box 190, Fairplay, CO 80440"
  },
  {
    "County": "Phillips County",
    "Judicial District": "13th",
    "Name": "Phillips Combined Court",
    "Court Address": "221 S. Interocean, Holyoke, CO 80734"
  },
  {
    "County": "Pitkin County",
    "Judicial District": "9th",
    "Name": "Pitkin County Courthouse",
    "Court Address": "506 E. Main, Suite 300, Aspen, CO 81611"
  },
  {
    "County": "Prowers County",
    "Judicial District": "15th",
    "Name": "Prowers County Combined Court",
    "Court Address": "301 S. Main, Suite 300, Lamar, CO 81052"
  },
  {
    "County": "Pueblo County",
    "Judicial District": "10th",
    "Name": "Pueblo Combined Court",
    "Court Address": "501 N. Elizabeth Street, Pueblo, CO 81003"
  },
  {
    "County": "Rio Blanco County",
    "Judicial District": "9th",
    "Name": "Rio Blanco Combined Court",
    "Court Address": "P.O. Box 1150, 455 Main Street, Meeker, CO 81641"
  },
  {
    "County": "Rio Blanco County",
    "Judicial District": "9th",
    "Name": "Rio Blanco County Associate Court",
    "Court Address": "209 E. Main Street, Rangely, CO 81648"
  },
  {
    "County": "Rio Grande County",
    "Judicial District": "12th",
    "Name": "Rio Grande Combined Court",
    "Court Address": "925 6th Street, Room 204, Del Norte, CO 81132"
  },
  {
    "County": "Routt County",
    "Judicial District": "14th",
    "Name": "Routt Combined Court",
    "Court Address": "1955 Shield Drive, Unit 200, Steamboat Springs, CO 80487"
  },
  {
    "County": "Saguache County",
    "Judicial District": "12th",
    "Name": "Saguache Combined Court",
    "Court Address": "501 4th Street, P.O. Box 197, Saguache, CO 81149"
  },
  {
    "County": "San Juan County",
    "Judicial District": "6th",
    "Name": "San Juan County Courthouse",
    "Court Address": "1557 Greene Street, P.O. Box 900, Silverton, CO 81433"
  },
  {
    "County": "San Miguel County",
    "Judicial District": "7th",
    "Name": "San Miguel County Courthouse",
    "Court Address": "305 W. Colorado Ave., P.O. Box 919, Telluride, CO 81435"
  },
  {
    "County": "Sedgwick County",
    "Judicial District": "13th",
    "Name": "Sedgwick Combined Court",
    "Court Address": "315 Cedar Street, Julesburg, CO 80737"
  },
  {
    "County": "Summit County",
    "Judicial District": "5th",
    "Name": "Summit County Justice Center",
    "Court Address": "510 N. Park Avenue, P.O. Box 269, Breckenridge, CO 80424"
  },
  {
    "County": "Teller County",
    "Judicial District": "4th",
    "Name": "Teller County Courthouse",
    "Court Address": "101 W. Bennett Avenue, P.O. Box 997, Cripple Creek, CO 80813"
  },
  {
    "County": "Washington County",
    "Judicial District": "13th",
    "Name": "Washington Combined Court",
    "Court Address": "26861 Hwy 34, P.O. Box 455, Akron CO 80720"
  },
  {
    "County": "Weld County",
    "Judicial District": "19th",
    "Name": "Weld County Court House",
    "Court Address": "901 9th Avenue, P.O. Box 2038 (80632), Greeley, CO 80631"
  },
  {
    "County": "Weld County",
    "Judicial District": "19th",
    "Name": "Weld County Centennial Center",
    "Court Address": "915 10th Street, P.O. Box 2038 (80632), Greeley, CO 80631"
  },
  {
    "County": "Weld County",
    "Judicial District": "19th",
    "Name": "Plaza West",
    "Court Address": "910 10th Avenue, P.O. Box 2038 (80632), Greeley, CO 80631"
  },
  {
    "County": "Yuma County",
    "Judicial District": "13th",
    "Name": "Yuma County Courthouse",
    "Court Address": "310 Ash Street, Suite L, Wray, CO 80758"
  }
]
