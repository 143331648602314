import React from 'react'
import styled from 'styled-components'

const CheckmarkDecoration = styled.span`
  content: " ";
  position: absolute;
  top: 5px;
  left: 0px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.zircon};
  transition: background-color 300ms;
`
const StyledRadioButton = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  &:checked {
    ~ ${CheckmarkDecoration} {
      background-color: ${props => props.theme.colors.tuna};
    }
  }
`
const StyledRadioLabel = styled.label`
  display: block;
  position: relative;
  user-select: none;
  cursor: pointer;
  padding: 5px 10px 5px 35px;
  margin: 0;
`

const RadioButton = props => {
  const { labelText = 'Test Radio Button' } = props
  return (
    <StyledRadioLabel>
        {labelText}
        <StyledRadioButton
        type="radio"
        />
        <CheckmarkDecoration />
    </StyledRadioLabel>
  )
}

export default RadioButton
