import React from 'react'
import Header from '../Header'
import ContentBlock from '../ContentBlock'
import Loader from '../Loader'
//import Error from '../pages/Error'
import Form from './form'
import { useParams /*, Link */ } from 'react-router-dom'

const Edit = ({loading, attorneyProfiles, profileFields, pathHelper, update}) => {
  const { id } = useParams()
  if (loading) return <Loader />
  const attorneyProfile = attorneyProfiles.find(profile => id === profile.id)
  return (
    <main>
      <Header />
      <ContentBlock>
        <h2>Editing Attorney Profile</h2>
        <Form {...{id, attorneyProfile, update}} />
      </ContentBlock>
    </main>
  )
}

export default Edit
