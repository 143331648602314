import {
    applyDocumentedPaymentsCap,
    legalResponsibilityForChildrenOtherRelationship
} from '../child-support/calculations/worksheet-calculations'

export const calculateMonthlyAdjustedGrossIncome = (monthlyGross, adjustments) => {
    //debugger
    if (false === monthlyGross) {
        return {
          adjustedMonthlyGross: monthlyGross
        }
    }
    const notTaxableFactor = 1.25
    const {
        // NOTR means not of this releationship
        maintenanceReceivedNOTR,
        maintenanceReceivedNOTRIsTaxDedductible,
        maintenancePaidNOTR,
        maintenancePaidNOTRIsTaxDeductible,
        supportPaidChildrenNOTR,
        numberOfChildrenOtherRelationshipPrimaryLiving,
        documentedPaymentsChildrenOtherRelationship,
        numberOfChildrenOtherRelationshipNotPrimaryLiving
     } = adjustments
    const adjustedMaintenanceReceivedNOTR = (
        maintenanceReceivedNOTR && maintenanceReceivedNOTRIsTaxDedductible
    ) ? maintenanceReceivedNOTR : (notTaxableFactor * maintenanceReceivedNOTR)
    const adjustedMaintenancePaidNOTR = (
        maintenancePaidNOTR && maintenancePaidNOTRIsTaxDeductible
    ) ? maintenancePaidNOTR : (notTaxableFactor * maintenancePaidNOTR)
    const otherRelationshipResponsibility = (numberOfChildrenOtherRelationshipPrimaryLiving ?
        legalResponsibilityForChildrenOtherRelationship(
          monthlyGross,
          adjustedMaintenanceReceivedNOTR,
          adjustedMaintenancePaidNOTR,
          numberOfChildrenOtherRelationshipPrimaryLiving
        ) : 0)
    const cappedDocumentedPaymentsChildrenOtherRelationship = (
        documentedPaymentsChildrenOtherRelationship ?
        applyDocumentedPaymentsCap(
            monthlyGross,
            numberOfChildrenOtherRelationshipNotPrimaryLiving,
            documentedPaymentsChildrenOtherRelationship
        ) : 0)
    return {
        adjustedMonthlyGross: monthlyGross 
            + (adjustedMaintenanceReceivedNOTR ? adjustedMaintenanceReceivedNOTR : 0) // 1a
            - (adjustedMaintenancePaidNOTR ? adjustedMaintenancePaidNOTR : 0) // 1b
            - (false !== supportPaidChildrenNOTR ? supportPaidChildrenNOTR : 0) // 1c
            - (false !== otherRelationshipResponsibility ? otherRelationshipResponsibility : 0) //1d
            - cappedDocumentedPaymentsChildrenOtherRelationship
    }
}

export const calculateSpousalSupport = options => {
    const {
        supportIsTaxDeductible,
        adjustedGrossMonthlyIncome1,
        adjustedGrossMonthlyIncome2
    } = options
    if ('number' != typeof adjustedGrossMonthlyIncome1 || 'number' != typeof adjustedGrossMonthlyIncome2) {
        return {
            payor: false,
            maintenance: false,
            error: 'Adjusted gross montly incomes are required.'
        }
    }
    const combinedAdjustedGrossMonthlyIncome = adjustedGrossMonthlyIncome1 + adjustedGrossMonthlyIncome2
    const isExtrapolation = combinedAdjustedGrossMonthlyIncome > 20000
    const _40percentOfCombinedAdjustedGrossMonthlyIncome = .4 * combinedAdjustedGrossMonthlyIncome
    var maintenance
    if (adjustedGrossMonthlyIncome1 === adjustedGrossMonthlyIncome2) {
        return {
            payor: false,
            maintenance: 0,
            _40percentOfCombinedAdjustedGrossMonthlyIncome
        }
    } else {
        const payor = adjustedGrossMonthlyIncome1 > adjustedGrossMonthlyIncome2 ? 'PARTY_1' :
         (adjustedGrossMonthlyIncome1 < adjustedGrossMonthlyIncome2 ? 'PARTY_2' : false)
        maintenance = payor === 'PARTY_1' ?
            _40percentOfCombinedAdjustedGrossMonthlyIncome - adjustedGrossMonthlyIncome2 :
            _40percentOfCombinedAdjustedGrossMonthlyIncome - adjustedGrossMonthlyIncome1
        if (maintenance < 0) return { maintenance: 0, _40percentOfCombinedAdjustedGrossMonthlyIncome }
        if (!supportIsTaxDeductible) {
            if (combinedAdjustedGrossMonthlyIncome < 10000) {
                maintenance *= .8
            } else {
                maintenance *= .75
            }
        }
        return {
            payor,
            maintenance,
            _40percentOfCombinedAdjustedGrossMonthlyIncome,
            isExtrapolation
        }
    }
}

export const spousalSupportTemplate = {
    payor: false,
    supportIsTaxDeductible: null,
    maintenance: false,
    _40PercentOfCombinedAdjustedGrossMonthlyIncome: false,
    isExtrapolation: null
}