import React, { createContext, useContext } from 'react'
import Footer from './components/Footer'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
// routes
import IndexRoutes from './containers/routers/Index'
//import Tracker from './containers/routers/Tracker'

import AccountRoutes from './routes/account'
import ModalSelector from './containers/modals/ModalSelector'
import Loader from './components/Loader'
import './App.css'
import useAuth from './hooks/useAuth'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import GlobalStyles from '../src/theme/globalStyles'

const userContext = createContext({user: null, session: null, status: null, actions: null})
export const useSession = () => useContext(userContext)

const testing = 'development' === process.env.NODE_ENV
const tracking = !testing

const App = () => {
  const { state, actions } = useAuth()
  const { user, session, status } = state
  if (status === 'initializing') return <Loader />

  return (
    <userContext.Provider value={{ user, session, status, actions }}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <div className="App">
          <Router>
            <Switch>
              <Route path='/account' component={AccountRoutes} />
              <IndexRoutes />
            </Switch>
            {/*tracking && <Tracker />*/}
          </Router>
          <Footer />
          <ModalSelector />
        </div>
      </ThemeProvider>
    </userContext.Provider>
  )
}

export default App
