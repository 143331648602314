import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  color: ${props => props.error ? props.theme.colors.white : 'auto'};
  margin-bottom: 20px;
  background: ${props => props.error ? props.theme.colors.sun : 'auto'};
`
Wrapper.displayName = 'Wrapper'

export default Wrapper
