import React from 'react'
import Header from '../Header'
import ContentBlock from '../ContentBlock'
import Loader from '../Loader'
import Error from '../pages/Error'
import { useParams, Link } from 'react-router-dom'
import { presentCamelCaseAsStartCase } from '../../helpers/presenters'

const Show = ({loading, attorneyProfiles, profileFields, pathHelper}) => {
  const { id } = useParams()
  const attorneyProfile = attorneyProfiles.find(profile => id === profile.id)
  if (loading) return <Loader />
  if (!attorneyProfile) return <Error />
  return (
    <main>
      <Header />
      <ContentBlock>
        <h2>Attorney Profile</h2>
        {profileFields.map(field => (
          <p><strong>{presentCamelCaseAsStartCase(field)}:</strong><br />{attorneyProfile[field]}</p>
        ))}
              <p><Link to={pathHelper(id).edit}>Edit Profile</Link> | <Link to={pathHelper().index}>Attorney Profiles</Link></p>
      </ContentBlock>
    </main>
  )
}

export default Show
