export function pdfLayout() {
  return {
    pageOrientation: 'portrait',
    pageSize: 'LETTER',
    pageMargins: [72, 110, 72, 72],
    content: [],
    footer: (currentPage, pageCount) => {
      let footer = {
        columns: [
          'Created using software by Legal Thunder, LLC.',
          {
            text: "Page " + currentPage.toString() + ' of ' + pageCount,
            alignment: 'right'
          }
        ],
        style: "footer",
        margin: [72, 0, 72, 0]
      };
      return footer;
    },
    styles: {
      footer: {
        fontSize: 9
      },
      numbered: {
        fontSize: 11
      },
      subordinated: {
        fontSize: 10
      }
    },
    defaultStyle: {
      fontSize: 10,
      font: 'arial'
    }
  };
}

