import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import Account from '../containers/account'
import BillingHistory from '../containers/billing-history'
import AttorneyProfilesRoutes from '../components/attorney-profiles'
import UpdatePaymentMethod from '../components/update-payment-method'

const AccountRoutes = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/billing-history`} component={BillingHistory} />
      <Route path={`${path}/update-payment-method`} component={UpdatePaymentMethod} />
      <Route path={`${path}/attorney-profiles`} component={AttorneyProfilesRoutes} />
      <Route path={path} component={Account} />
    </Switch>
  )
}

export default AccountRoutes
